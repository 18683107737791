import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { getLLInfo } from '../js/utils'
import LLContent from './LLContent'

export default class LessonLearntInfo extends Component {

    render () {
        // TODO: Change this llContent to llName. Check where this module is being used and make the necessary changes. Merging this module with LLContent module might be a good idea.
        const { db, llContent } = this.props

        const llName = llContent.title
        let llReq = getLLInfo(llName, db.ll)
        return llReq.found ? <LLContent db={db} lesson={llReq.llInfo}/> : this.LLNotFound()

    }

    //
    LLNotFound () {
        return (
            <Box mb={1}> <Typography variant="h5">!!! Information not found !!! </Typography> </Box>
        )
    }

}