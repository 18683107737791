import React from 'react'

import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { Box, Divider, Fade } from '@material-ui/core'

import LLReplicability from '../charts/LLReplicability'
import LLctm_new from '../charts/LLctm_new'
import Grid from '@material-ui/core/Grid'
import LLCounter from '../charts/LLCounter'
import LLAchievementsList from './Analyses/LLAchievementsList'
import LLInfluences from './Analyses/LLInfluences'

function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <div
            style={{ width: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            <Fade in={value === index} timeout={{ enter: 700, exit: 0, }}>
                <Box p={1}>
                    {children}
                </Box>
            </Fade>

        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    // indicator: {
    //     backgroundColor: '#1890ff',
    // },
})(Tabs)

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&$selected': {
            color: 'primary',
            fontWeight: theme.typography.fontWeightMedium,
            outline: 'none'
        },
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&:focus': {
            color: 'primary',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />)

function Title (props) {
    return (
        <Typography component="div" variant={'subtitle2'}>
            <Box mb={2}>
                {props.children.toUpperCase()}
            </Box>
        </Typography>
    )
}

function SubTitle (props) {
    return (
        <Typography component="div" variant={'body2'}>
            {/*<Box my={2} fontFamily="Monospace" fontWeight="fontWeightMedium">*/}
            {props.children}
            {/*</Box>*/}
        </Typography>
    )
}

function StatisticsBoard (props) {
    const { llList } = props

    return (
        <React.Fragment>
            <Title>Statistics</Title>
            <SubTitle>
                Take a look on some statistics on this list of Lessons Learnt. It might give you an idea about which
                fields
                are covered and its distribution.
            </SubTitle>

            <Grid container>

                <Grid item xs={12}>
                    <Box mx="auto" width='30%' my={3}><Divider/></Box>
                </Grid>

                <Grid item xs={12}>
                    {/*LLCounter*/}
                    <LLCounter llList={llList}/>
                </Grid>

                <Grid item xs={12}>
                    <Box mx="auto" width='30%' my={3}><Divider/></Box>
                </Grid>

                <Grid item xs={12}>
                    {/*LLReplicability*/}
                    <LLReplicability llList={llList}/>
                    <Box align="center">
                        <SubTitle>
                            It shows the Replicability distribution over all Lessons Learnt.
                        </SubTitle>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box mx="auto" width='30%' my={3}><Divider/></Box>
                </Grid>

                <Grid item xs={12}>

                    {/*<Box border={1} width={600}>*/}
                    {/*LLctm*/}
                    <LLctm_new llList={llList}/>
                    {/*</Box>*/}

                    <Box align="center">
                        <SubTitle>
                            Indicates which types of capital were present in the initial phase, which were developed and
                            which were eventually obtained.
                        </SubTitle>
                    </Box>
                </Grid>

            </Grid>


        </React.Fragment>
    )
}

function AchievementsBoard (props) {
    const { db, llList } = props
    return (
        <React.Fragment>

            <Title>Explore all achievements</Title>
            <SubTitle>
                From here you can explore all achievements and check if they are mentioned from multiple Lessons Learnt.
                This way, you can have multiple views on how Lessons Learnt are addressing these achievements.
            </SubTitle>

            {/*LLAchievementsList*/}
            <LLAchievementsList db={db} llList={llList}/>
        </React.Fragment>
    )
}

function RmaBoard (props) {
    const { db, llList } = props
    return (
        <React.Fragment>

            <Title>Explore all Role Model Actions</Title>
            <SubTitle>
                Those Role Model Actions (RMA) are sorted on how often they are mentioned in this list of Lessons
                Learnt. It might be worth for you to explore their details or take a look on the full Role Model
                where it was referred to.
            </SubTitle>

            {/*LLctm*/}
            <LLInfluences db={db} llList={llList}/>

        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        // height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: 200
    },
}))

export default function LLAnalyzes (props) {

    const { db, llList } = props

    const classes = useStyles()

    const [value, setValue] = React.useState(0)
    const handleChange = (event, newValue) => { setValue(newValue) }

    return (
        <Box>

            <Grid container>

                <Grid item xs={12}>
                    <Box my={3}>
                        <Typography variant={'body2'}>
                            From the list of Lessons Learnt, we have made analysis that might help you to get better insights and find better
                            answers. Explore the data contained in the following tabs.
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.root}>

                        <AntTabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                            textColor="secondary"
                        >
                            <AntTab label="STATS"/>
                            <AntTab label="ACHIEVEMENTS"/>
                            <AntTab label="ROLE MODEL ACTIONS"/>

                        </AntTabs>

                        <TabPanel value={value} index={0}>
                            <StatisticsBoard llList={llList}/>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <AchievementsBoard db={db} llList={llList}/>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <RmaBoard db={db} llList={llList}/>
                        </TabPanel>

                    </div>
                </Grid>

            </Grid>

        </Box>
    )
}