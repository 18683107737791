import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'


import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Typography from '@material-ui/core/Typography'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Chart from 'react-apexcharts'

import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import ZoomInIcon from '@material-ui/icons/ZoomIn';

import LessonLearntInfo from '../LessonLearntInfo'
import DialogInfo from '../DialogInfo'
import IconButton from '@material-ui/core/IconButton'
import { theme } from '../../theme/theme'


const useStyles = makeStyles({
    speedDial: {
        position: 'absolute',
        bottom: '5px', //{theme.spacing(2)},
        right: '5px', //theme.spacing(2),
    },
})

function a11yProps (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

function CTMTable (props) {
    let { list, handleClickOpen } = props
    return (
        <Table size="small" aria-label="Built table">
            <TableHead>
                <TableRow>
                    <TableCell><Box color="primary.main">LESSON LEARNT</Box></TableCell>
                    <TableCell><Box color="primary.main">INITIAL</Box></TableCell>
                    <TableCell><Box color="primary.main">DEVELOPED</Box></TableCell>
                    <TableCell><Box color="primary.main">OBTAINED</Box></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map(row => (
                    <TableRow key={row.llName} hover>
                        <TableCell component="th" scope="row">
                            <Button variant="outlined" color="primary" size="small" width={''}
                                    onClick={row.llName === '--' ? () => null : () => handleClickOpen(row.llName)}>
                                {row.llName}
                            </Button>

                        </TableCell>
                        <TableCell align="left">{row.initial}</TableCell>
                        <TableCell align="left">{row.developed}</TableCell>
                        <TableCell align="left">{row.obtained}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

function SimpleDialog (props) {
    // const classes = useStyles()
    const { listsCTM, onClose, selectedValue, open, db } = props

    const handleClose = () => {
        onClose(selectedValue)
    }

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [dialogContent, setDialogContent] = React.useState({ title: 'LL01'})

    function handleClickOpen (name) {
        setDialogOpen(true)
        setDialogContent({title: name})
    }

    function handleCloseLLDialog () {
        setDialogOpen(false)
    }

    let rowsCTM = listsCTM//getLLCTM(ll, db)

    return (
        <Dialog onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth
                maxWidth={'lg'}

        >
            <DialogTitle id="simple-dialog-title">LESSONS LEARNT - Capital Transference Mechanism</DialogTitle>
            <DialogContent>

            <DialogContentText>
                 Make Analyses on Capital Transference Mechanisms per category.
            </DialogContentText>

                <AppBar position="static">
                    <Tabs value={value}
                          variant="scrollable"
                          scrollButtons="auto"
                          onChange={handleChange}
                          aria-label="Capital Transference Mechanism Tabs"
                    >
                        <Tab label="BUILT" {...a11yProps(0)} />
                        <Tab label="CULTURAL" {...a11yProps(1)} />
                        <Tab label="FINANCIAL" {...a11yProps(2)} />
                        <Tab label="HUMAN" {...a11yProps(3)} />
                        <Tab label="NATURAL" {...a11yProps(4)} />
                        <Tab label="SOCIAL" {...a11yProps(5)} />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                    <CTMTable list={rowsCTM.BUILT} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CTMTable list={rowsCTM.CULTURAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CTMTable list={rowsCTM.FINANCIAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <CTMTable list={rowsCTM.HUMAN} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <CTMTable list={rowsCTM.NATURAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <CTMTable list={rowsCTM.SOCIAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>


                <DialogInfo
                    dialogOpen={dialogOpen}
                    title={'Lesson learnt ' + dialogContent.title}
                    handleClose={() => handleCloseLLDialog()}
                    // backgroundColor={theme.palette.DSS_Pallet.mainColours.YELLOW}
                >
                    <LessonLearntInfo llContent={dialogContent} db={db}/>
                </DialogInfo>

            </DialogContent>

        </Dialog>
    )
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // selectedValue: PropTypes.string.isRequired,
}



function getCTMData (llList) {

    // get list of displayed LL
    // let llList = this.getListOfDisplayedLL()

    // Create counters for each category
    let capitalInit = {
        CULTURAL: 0,
        NATURAL: 0,
        HUMAN: 0,
        SOCIAL: 0,
        FINANCIAL: 0,
        BUILT: 0
    }
    let capitalDev = {
        CULTURAL: 0,
        NATURAL: 0,
        HUMAN: 0,
        SOCIAL: 0,
        FINANCIAL: 0,
        BUILT: 0
    }
    let capitalObt = {
        CULTURAL: 0,
        NATURAL: 0,
        HUMAN: 0,
        SOCIAL: 0,
        FINANCIAL: 0,
        BUILT: 0
    }

    // Create list of CTM to be passed to dialog tables
    let listsCTM = {
        CULTURAL: [],
        NATURAL: [],
        HUMAN: [],
        SOCIAL: [],
        FINANCIAL: [],
        BUILT: []
    }

    // Loop through all displayed LL and increase the counter for any developed CTM category
    for (let i = 0; i < llList.length; i++) {
        // let llName = llList[i]
        //
        // let llAux = getLLInfo(llName, this.db.ll)
        // let ll = llAux.found ? llAux.llInfo : {}
        let ll = llList[i]
        if (Object.keys(ll).length > 0) {
            if (typeof ll['CAPITAL TRANSFERENCE MECHANISM']['List'] !== 'undefined') {
                let ctmList = ll['CAPITAL TRANSFERENCE MECHANISM']['List']

                // Go through ctm list of this LL
                for (let k = 0; k < ctmList.length; k++) {

                    let ctmElem = ctmList[k] // get CTM element data

                    if (typeof ctmElem['RELATED CAPITAL'] !== 'undefined') {
                        let capital = ctmElem['RELATED CAPITAL'].trim().toUpperCase() // get Capital Category

                        let initText = ctmElem['INITIAL'].trim()
                        let devText = ctmElem['DEVELOPED'].trim()
                        let obtText = ctmElem['OBTAINED'].trim()

                        // Count capitals
                        if (initText) {
                            capitalInit[capital] += 1
                        }

                        if (devText) {
                            capitalDev[capital] += 1
                        }

                        if (obtText) {
                            capitalObt[capital] += 1
                        }

                        // Collect info for dialog tabels
                        listsCTM[capital].push(
                            {
                                llName: llList["LL CODE"],
                                initial: initText,
                                developed: devText,
                                obtained: obtText
                            }
                        )

                    }

                }
            }

        }
    }

    // Transform the counter in arrays
    let labels = Object.keys(capitalDev).sort()

    let dataCapitalsInit = []
    let dataCapitalsDev = []
    let dataCapitalsObt = []
    for (let i = 0; i < labels.length; i++) {
        dataCapitalsInit.push(capitalInit[labels[i]])
        dataCapitalsDev.push(capitalDev[labels[i]])
        dataCapitalsObt.push(capitalObt[labels[i]])
    }

    let data = [
        {
            name: 'Initial',
            data: dataCapitalsInit
        },
        {
            name: 'Developed',
            data: dataCapitalsDev
        },
        {
            name: 'Obtained',
            data: dataCapitalsObt
        },
    ]

    let ctm = {
        labels: labels,
        data: data,
        listsCTM: listsCTM,
        clickEvent: () => () => console.log('ClickEvent Passed CTM')
    }

    // console.log(ctm)

    return ctm
}


export default function LLctm_new (props) {

    let { llList, options, data, listsCTM, ll, db } = props

    // const classes = useStyles()
    //
    // const [open, setOpen] = React.useState(false)
    //
    // const handleClickOpen = () => {
    //     setOpen(true)
    // }
    // const handleClose = (value) => {
    //     setOpen(false)
    // }

    let results = getCTMData(llList)

    let optCTM = {
        labels: results.labels, // array
        title: {
            text: "CAPITAL TRANSFERENCE MECHANISM",
            align: "center"
        },
        colors: [
            theme.palette.DSS_Pallet.mainColours.BROWN,
            theme.palette.DSS_Pallet.mainColours.YELLOW,
            theme.palette.DSS_Pallet.mainColours.DARK_GREEN,
            theme.palette.DSS_Pallet.mainColours.GRAY,
            theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN,
            theme.palette.DSS_Pallet.supplementaryColours.DARK_GRAY,
            theme.palette.DSS_Pallet.mainColours.GREEN,
            theme.palette.DSS_Pallet.mainColours.DARK_BROWN
        ],
        chart: {
            toolbar: {
                show: false
            }
        },
        legend: {
            show: true,
            position: 'right', //'bottom',
            horizontalAlign: 'left',
            floating: false,//true,
            width: 150,
            height: 140, // should be the height of the graph
            offsetY: -10, // For some reason it hiddes to last element of the legend
            // width: 200,
            formatter: function(seriesName, opts) {
                return [seriesName.toUpperCase()]
            }
        }
    }

    return (
        <React.Fragment>

            <Box border={0} height={300} minWidth={500} >
                <Chart options={optCTM}
                       series={results.data}
                       type="radar"
                       height="100%" // ("100%"| "auto") // !! If set to 100% then set parent to have a fixed height !!
                />
            </Box>

            {/*<Box className={classes.speedDial} borderRadius="50%" boxShadow={theme.boxShadow.GREEN}>*/}
            {/*    <IconButton  color="primary" size="small" onClick={handleClickOpen}>*/}
            {/*        <ZoomInIcon/>*/}
            {/*    </IconButton>*/}
            {/*</Box>*/}

            {/*<SimpleDialog listsCTM={listsCTM} ll={ll} db={db} open={open} onClose={handleClose}/>*/}

        </React.Fragment>
    )
}
