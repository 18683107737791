import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { Accordion, Divider, Container } from '@material-ui/core'

import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { theme } from '../../../theme/theme'
import RMList from '../RMList'
import LLList from '../LLList'
// import RMctm from '../../charts/RMctm'
// import RMctmOurSelection from './OurSelectionPanel/RMctmOurSelection'
import CtmSelectionTables from './OurSelectionPanel/ctmSelectionTables'

import { compareField, RM_SIZES } from '../../../js/utils'
import RmDrivers from '../../charts/RmDrivers'
import Chart from 'react-apexcharts'

//---------------------------------------------------------------------------------------
//------------------------ Role Models Scores Calculations -----------------------------

// It calculates drivers score for a single RM given the user selection
function calcDriversScore (rm, driversState) {

    let drivers_score = 0 // score to be returned

    let drivers_Length = Object.keys(driversState).length   // Get how many options exis
    let driver_Value = 1.0 / drivers_Length                 // score value for each active option

    // Get list of active drivers
    let drivers_activeList = []
    Object.keys(driversState).forEach(d => driversState[d] === true && drivers_activeList.push(d))

    // Check how many rm drivers are equal to the ones that the user choose
    drivers_activeList.forEach(activeDriver => {
        // if rm includes the active driver then increment the score
        rm['Practices']['DRIVERS'].includes(activeDriver) && (drivers_score += driver_Value)
    })

    return drivers_score
}

// It calculates challenge score for a single RM given the user selection
function calcChallengesScore (rm, challengesState) {

    let challenges_score = 0 // score to be returned

    let challenges_length = Object.keys(challengesState).length // Get how many options exist
    let challenges_value = 1.0 / challenges_length              // score value for each active option

    // Get list of active challenges
    let challenges_activeList = []
    Object.keys(challengesState).forEach(c => challengesState[c] === true && challenges_activeList.push(c))

    // Check how many rm challenges are equal to the ones that the user choose
    challenges_activeList.forEach(activeChallenge => {
        // Filter all challenges that contain the activeChallenge. If it exists in this rm then increase the score
        (rm['Practices']['CHALLENGES'].filter(c => c['CHALLENGE'] === activeChallenge).length > 0) && (challenges_score += challenges_value)
    })

    return challenges_score
}

function calcSizeScore (rm, sizeState) {

    let score = 0 // score to be returned

    // Default values for unknown variables
    const DEFAULT_SCORE_SIZESTATE_UNKNOWN = 0
    const DEFAULT_SCORE_RMSIZE_UNKNOWN = 0.4

    // Is sizeState is not defined in RM_SIZES list
    if (!RM_SIZES.includes(sizeState)) {
        console.log('ERROR!: size state is not defined in RM_SIZES!')
        return DEFAULT_SCORE_SIZESTATE_UNKNOWN // return 0 to make this parcel not effective
    }

    // Logic:
    // if RM size match then returns 1
    // if RM size is 1 position distance from sizeState then return 1 - (1/#sizes)
    // if RM size is 2 position distance from sizeState then return 1 - 2 * (1/#sizes)
    // if RM size is undefined then return 0.4

    let rmSize = rm['Context']['Size'] // get RM size

    // if rmSize does not exist
    if (typeof rmSize === 'undefined' || rmSize === '') {
        return DEFAULT_SCORE_RMSIZE_UNKNOWN
    }

    let rmPos = RM_SIZES.indexOf(rmSize) // get position of RM in the organized crescent list ('SMALL', ..., 'VERY BIG')
    let statePos = RM_SIZES.indexOf(sizeState) // get the choosen position

    let distance = Math.abs(statePos - rmPos) // calculate absolute distance between positions

    // calculate RM Size score
    score = 1 - distance * (1 / RM_SIZES.length)

    return score
}

function calcRMReplicabilityScore (rm) {

    let score = 0

    // Get RM replicability
    let rmRep = rm['Practices']['REPLICABILITY'][0]

    // Define scores
    let DEFAULT_SCORE = 0.5
    let replicabilityScores = {
        'HIGH': 1,
        'MEDIUM-HIGH': 0.8,
        'MEDIUM': 0.6,
        'MEDIUM-LOW': 0.4,
        'LOW': 0.2,
    }

    // if rm has a know replicability then pass it. Otherwise pass a default score
    score = (replicabilityScores[rmRep]) ? score = replicabilityScores[rmRep] : DEFAULT_SCORE

    return score
}

function calcSiasScore (rm, siasState) {

    let sias_score = 0

    let sias_length = Object.keys(siasState).length   // Get how many options exis
    let sias_value = 1.0 / sias_length                 // score value for each active option

    // Get list of active sias
    let sias_activeList = []
    Object.keys(siasState).forEach(sia => siasState[sia] === true && sias_activeList.push(sia))

    // Check how many rm sias are equal to the ones that the user choose
    sias_activeList.forEach(activeSia => {
        // if rm includes the active sia then increment the score
        (rm['SIA NAME'] === activeSia) && (sias_score += sias_value)
    })

    return sias_score
}

// Calculates the RM scores and returns a sorted array with the highest scored ones on top
function getTopRM (db, wizardSelections, top = 5) {

    let topRM = []

    let rmScores = [
        // { RM1: {}, score:0.7 }
    ]

    // Weight for the final score
    let driver_weight = 0.3,
        challenges_weight = 0.3,
        size_weight = 0.15,
        replicability_weight = 0.1,
        sias_weight = 0.15

    db.rm.forEach(rm => {

        let rmScore = 0 // final role models score

        // Calculate partial scores
        let drivers_score = calcDriversScore(rm, wizardSelections.drivers)
        let challenges_score = calcChallengesScore(rm, wizardSelections.challenges)
        let size_score = calcSizeScore(rm, wizardSelections.size)
        let replicability_score = calcRMReplicabilityScore(rm)
        let sias_score = calcSiasScore(rm, wizardSelections.sias)

        // Calculate final RM score
        rmScore =
            driver_weight * drivers_score +                 // Drivers
            challenges_weight * challenges_score +          // Challenges
            size_weight * size_score +                      // Size
            replicability_weight * replicability_score +   // Replicability
            sias_weight * sias_score                        // SIA's

        // console.log(
        //     rm['RM'] + 'scores:\n' +
        //     '\t drivers: ' + drivers_score + '\n' +
        //     '\t challenges: ' + challenges_score + '\n' +
        //     '\t size: ' + size_score + '\n' +
        //     '\t replicability: ' + replicability_score + '\n' +
        //     '\t sias: ' + sias_score + '\n' +
        //     '\t rmScore: ' + rmScore
        // )

        // push RM score to all rmScores
        rmScores.push({ rm: rm, score: rmScore })

    })

    // Sort RMs by score
    rmScores.sort(compareField('score'))
    // console.log('rmScores', rmScores)

    if (rmScores.length > 0) {
        for (let i = 0; i < top; i++) {
            topRM.push(rmScores[i].rm)
        }
    }

    // Return the top scored ones
    return topRM
}

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------
//------------------------ Lessons Learnt Scores Calculations -----------------------------

function calcCrosscuttingScore (ll, crosscuttingState) {

    let score = 0 // score to be returned

    // Get list of active challenges
    let cc_activeList = []
    Object.keys(crosscuttingState).forEach(cc => crosscuttingState[cc] === true && cc_activeList.push(cc))

    // If none has been selected then return zero to cancel any of cc calculation
    if (cc_activeList.length === 0) {
        return score
    }

    // Weights for the overall calculation to benefit the cc that appear in the main section
    let mainCC_weight = 1.0,
        otherCC_weight = 0.8

    // value of each cc
    let cc_value = 1.0 / cc_activeList.length

    // start counters
    let main_cc_counter = 0,
        other_cc_counter = 0

    // count how many active cc are in main and other cc field
    cc_activeList.forEach(activeCC => {
        ll['MAIN RELATED CROSSCUTTING'].includes(activeCC) && main_cc_counter++
        ll['OTHER RELATED CROSSCUTTING'].includes(activeCC) && other_cc_counter++
    })

    // final score
    score = mainCC_weight * (cc_value * main_cc_counter) + otherCC_weight * (cc_value * other_cc_counter)

    return score
}

function calcLLReplicabilityScore (ll) {

    let score = 0

    // Get RM replicability
    let llRep = ll['REPLICABILITY']

    // Define scores
    let DEFAULT_SCORE = 0.5
    let replicabilityScores = {
        'HIGH': 1,
        'MEDIUM-HIGH': 0.8,
        'MEDIUM': 0.6,
        'MEDIUM-LOW': 0.4,
        'LOW': 0.2,
    }

    // if rm has a know replicability then pass it. Otherwise pass a default score
    score = (replicabilityScores[llRep]) ? score = replicabilityScores[llRep] : DEFAULT_SCORE

    return score

}

function getTopLL (db, wizardSelections, top = 5) {

    // top =

    let topLL = []

    let llScores = [
        // { LL1: {}, score:0.7 }
    ]

    // Weight for the final score
    let cc_weight = 0.8,
        replicability_weight = 0.2

    db.ll.forEach(ll => {

        let llScore = 0 // final Lesson Learnt score

        // Calculate partial scores
        let mainCC_score = calcCrosscuttingScore(ll, wizardSelections.crosscuttings)
        let replicability_score = calcLLReplicabilityScore(ll)

        //TODO: improve the llScore with more features

        // Calculate final RM score
        llScore =
            cc_weight * mainCC_score +                      // Crosscutting
            replicability_weight * replicability_score      // Replicability
                                                            // CTM
        // console.log(
        //     ll['LL CODE'] + 'scores:\n' +
        //     '\t crosscuting:   \t' + mainCC_score + '\n' +
        //     '\t replicability: \t' + replicability_score + '\n' +
        //     '\t llScore: ' + llScore
        // )

        // push LL score to all llScores
        llScores.push({ ll: ll, score: llScore })

    })

    // Sort LL by score
    llScores.sort(compareField('score'))
    // console.log('llScores', llScores)

    if (llScores.length > 0) {
        for (let i = 0; i < top; i++) {
            topLL.push(llScores[i].ll)
        }
    }

    //Score :   Main CC, Other CC, Replicability
    //         Maybe some RMA info
    return topLL
}

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------
//------------------------ RMs Scored by Initial Conditions -----------------------------

function getRmWithSimilarInitialConditions (db, wizardSelections) {

    let rmInitCondSorted = []

    let rmScores = [
        // { RM1: {}, score:0.7 }
    ]

    // Weight for the final score
    let
        // driver_weight = 0.3,
        challenges_weight = 0.5,
        size_weight = 0.4,
        replicability_weight = 0.1
    // sias_weight = 0.15

    db.rm.forEach(rm => {

        let rmScore = 0 // final role models score

        // Calculate partial scores
        // let drivers_score = calcDriversScore(rm, wizardSelections.drivers)
        let challenges_score = calcChallengesScore(rm, wizardSelections.challenges)
        let size_score = calcSizeScore(rm, wizardSelections.size)
        let replicability_score = calcRMReplicabilityScore(rm)
        // let sias_score = calcSiasScore(rm, wizardSelections.sias)

        // Calculate final RM score
        rmScore =
            // driver_weight * drivers_score +                 // Drivers
            challenges_weight * challenges_score +          // Challenges
            size_weight * size_score +                      // Size
            replicability_weight * replicability_score   // Replicability
        // sias_weight * sias_score                        // SIA's

        // console.log(
        //     rm['RM'] + 'scores:\n' +
        //     // '\t drivers: ' + drivers_score + '\n' +
        //     '\t challenges: ' + challenges_score + '\n' +
        //     '\t size: ' + size_score + '\n' +
        //     '\t replicability: ' + replicability_score + '\n' +
        //     // '\t sias: ' + sias_score + '\n' +
        //     '\t rmScore: ' + rmScore
        // )

        // push RM score to all rmScores
        rmScores.push({ rm: rm, score: rmScore })

    })

    // Sort RMs by score
    rmScores.sort(compareField('score'))
    // console.log('rmScores', rmScores)

    if (rmScores.length > 0) {
        for (let i = 0; i < rmScores.length; i++) {
            rmInitCondSorted.push(rmScores[i].rm)
        }
    }

    return rmInitCondSorted
}

function getTopRmDrivers (rmList, top) {

    let topRmDrivers = []

    let driversCounter = {}

    rmList.forEach(rm => {
        let driversArr = rm['Practices']['DRIVERS']

        driversArr.forEach(driver => {
            if (driver in driversCounter) {
                driversCounter[driver]++
            } else {
                driversCounter[driver] = 1
            }
        })
    })

    // Create a list of objects containing the driver name and its counter
    let driversList = []
    Object.keys(driversCounter).forEach(driver => driversList.push({ driver: driver, counter: driversCounter[driver] }))

    // sort list
    driversList.sort(compareField('counter'))
    // console.log('driversList Sorted: ', driversList)

    // if top not defined then send the full list
    if (typeof top === 'undefined') {
        top = driversList.length
    }

    // push to list the top ones
    for (let i = 0; (i < top) && (i < driversList.length); i++) {
        topRmDrivers.push(driversList[i].driver)
    }

    return topRmDrivers
}

//Returns something like this:
//  {
//     'NATURAL': [
//         { rm: {RMyy}, score: #, ctm: { INITIAL: '...', DEVELOPED: '...', OBTAINED: '...', "CAPITALS": "", "RELEVANCE": "" }},
//         { rm: {RMyy}, score: #, ctm:{} }, ...
//     ],
//     'HUMAN': [
//         { rm: {RMyy}, score: #, ctm:{} }, ...
//     ]
// }
function getTopCTM (rmList, topRmCapitals, topRmDrivers) {

    let ctmSelected = {} // object to return

    // Create capital keys on object
    topRmCapitals.forEach(capital => ctmSelected[capital] = [])

    // Go through role models to calculate a score
    rmList.forEach(rm => {
        // get rm ctm
        let rmCtm = rm['Practices']['CAPITAL TRANSFER MECHANISM']

        topRmCapitals.forEach(capital => {

            // look for ctm that have the DEVELOPED field and belongs to the same capital
            let selection = rmCtm.find(ctm => ctm['DEVELOPED'] && ctm['CAPITALS'] === capital)

            // if ctm found then give a score
            if (selection) {

                // set score for ctm being HIGH relevance
                let ctmRelevanceScore = (selection['RELEVANCE'] === 'HIGH') ? 1.0 : 0.0

                // set score for rm having as many drivers as possible
                let rmDriversScore = 0.0
                topRmDrivers.forEach(driver => {
                    rm['Practices']['DRIVERS'].includes(driver) && (rmDriversScore += 1 / topRmDrivers.length)
                })

                // console.log(
                //     rm['RM'] + ' --> \n' +
                //     '\t ctm score: ' + ctmRelevanceScore + '\n' +
                //     '\t Dri score: ' + rmDriversScore + '\n'
                // )

                // calculate final score
                let ctmRelevance_weight = 0.5, rmDrivers_weight = 0.5 // weights for each part of the score formula
                let totalScore =
                    ctmRelevance_weight * ctmRelevanceScore +
                    rmDrivers_weight * rmDriversScore

                // push info to array
                selection && ctmSelected[capital].push({ rm: rm, ctm: selection, score: totalScore })
            }

        })

    })

    // sort all ctm arrays
    Object.keys(ctmSelected).forEach(capital => {
        ctmSelected[capital].sort(compareField('score'))
    })

    // console.log('ctmSelected:', ctmSelected)

    return ctmSelected
}

function getDriversData (rmList) {

    // object to be returned
    let driversData = {
        labels: [],
        data: []
    }

    let driversCounters = {}

    rmList.forEach(rm => {
        let driversArr = rm['Practices']['DRIVERS']

        driversArr.forEach(driver => {
            if (driver in driversCounters) {
                driversCounters[driver]++
            } else {
                driversCounters[driver] = 1
            }
        })
    })

    // Create a list of objects containing the driver name and its counter
    let driversList = []
    Object.keys(driversCounters).forEach(driver => driversList.push({
        driver: driver,
        counter: driversCounters[driver]
    }))

    // sort list
    driversList.sort(compareField('counter'))
    // console.log('driversList Sorted: ', driversList)

    // Create drivers data for graph
    driversList.forEach(driverObj => {
        driversData.labels.push(driverObj.driver)
        driversData.data.push(driverObj.counter)
    })

    // console.log('driversData:', driversData)
    return driversData
}

function getCTMData (rmList) {

    // get list of displayed LL
    // let llList = this.getListOfDisplayedLL()

    // Create counters for each category
    let capitalInit = {
        CULTURAL: 0,
        NATURAL: 0,
        HUMAN: 0,
        SOCIAL: 0,
        FINANCIAL: 0,
        // BUILT: 0,
        BUILD: 0
    }
    let capitalDev = {
        CULTURAL: 0,
        NATURAL: 0,
        HUMAN: 0,
        SOCIAL: 0,
        FINANCIAL: 0,
        // BUILT: 0,
        BUILD: 0
    }
    let capitalObt = {
        CULTURAL: 0,
        NATURAL: 0,
        HUMAN: 0,
        SOCIAL: 0,
        FINANCIAL: 0,
        // BUILT: 0,
        BUILD: 0
    }

    // // Create list of CTM to be passed to dialog tables
    // let listsCTM = {
    //     CULTURAL: [],
    //     NATURAL: [],
    //     HUMAN: [],
    //     SOCIAL: [],
    //     FINANCIAL: [],
    //     BUILT: []
    // }

    // Loop through all RM and increase the counter for any developed CTM category
    for (let i = 0; i < rmList.length; i++) {
        // let llName = llList[i]
        //
        // let llAux = getLLInfo(llName, this.db.ll)
        // let ll = llAux.found ? llAux.llInfo : {}
        let rm = rmList[i]
        if (Object.keys(rm).length > 0) {
            if (typeof rm['Practices']['CAPITAL TRANSFER MECHANISM'] !== 'undefined') {
                let ctmList = rm['Practices']['CAPITAL TRANSFER MECHANISM']

                // Go through ctm list of this LL
                for (let k = 0; k < ctmList.length; k++) {

                    let ctmElem = ctmList[k] // get CTM element data

                    if (typeof ctmElem['CAPITALS'] !== 'undefined') {
                        let capital = ctmElem['CAPITALS'].trim().toUpperCase() // get Capital Category

                        let initText = ctmElem['INITIAL'] && ctmElem['INITIAL'].trim()
                        let devText = ctmElem['DEVELOPED'] && ctmElem['DEVELOPED'].trim()
                        let obtText = ctmElem['OBTAINED'] && ctmElem['OBTAINED'].trim()

                        // Count capitals
                        initText && (capitalInit[capital] += 1)
                        devText && (capitalDev[capital] += 1)
                        obtText && (capitalObt[capital] += 1)

                        // // Collect info for dialog tabels
                        // listsCTM[capital].push(
                        //     {
                        //         llName: llList["LL CODE"],
                        //         initial: initText,
                        //         developed: devText,
                        //         obtained: obtText
                        //     }
                        // )

                    }

                }
            }

        }
    }

    // Transform the counter in arrays
    let labels = Object.keys(capitalDev).sort()
    // console.log("labels: ", labels)
    let dataCapitalsInit = []
    let dataCapitalsDev = []
    let dataCapitalsObt = []
    for (let i = 0; i < labels.length; i++) {
        dataCapitalsInit.push(capitalInit[labels[i]])
        dataCapitalsDev.push(capitalDev[labels[i]])
        dataCapitalsObt.push(capitalObt[labels[i]])
    }

    let data = [
        {
            name: 'Initial',
            data: dataCapitalsInit
        },
        {
            name: 'Developed',
            data: dataCapitalsDev
        },
        {
            name: 'Obtained',
            data: dataCapitalsObt
        },
    ]

    let ctm = {
        labels: labels,
        data: data,
        // listsCTM: listsCTM,
        // clickEvent: () => () => console.log('ClickEvent Passed CTM')
    }

    // console.log(ctm)

    return ctm
}

// TODO: Split this component in smaller ones
export default function OurSelectionPanel (props) {

    const { db, wizardSelections } = props

    // ----------------
    // --- 1st part ---
    // ----------------

    // Get the overall top Lists of RM and LL
    let topListNum = 5
    let topRMList = getTopRM(db, wizardSelections, topListNum)
    let topLLList = getTopLL(db, wizardSelections)
    let rmLenght = db.rm.length
    let llLenght = db.ll.length
    // ----------------
    // --- 2nd part ---
    // ----------------

    // Get a list of the first 10 scored RMs based on similar Initial Conditions
    let rmWithSimilarInitialConditions = getRmWithSimilarInitialConditions(db, wizardSelections).filter((rm, idx) => idx < 10)
    // console.log('rmWithSimilarInitialConditions:', rmWithSimilarInitialConditions)

    //--------- Drivers related ----------

    // get drivers data
    let driversData = getDriversData(rmWithSimilarInitialConditions)

    let optDrivers = {
        labels: driversData.labels,
        plotOptions: {
            pie: {
                customScale: 0.85,
            }
        },
        title: {
            text: 'DRIVERS',
            align: 'center'
        },
        legend: {
            width: 200,
            formatter: function (seriesName, opts) {
                return [seriesName.toUpperCase()]
            }
        },
        colors: [
            theme.palette.DSS_Pallet.mainColours.BROWN,
            theme.palette.DSS_Pallet.mainColours.YELLOW,
            theme.palette.DSS_Pallet.mainColours.DARK_GREEN,
            theme.palette.DSS_Pallet.mainColours.GRAY,
            theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN,
            theme.palette.DSS_Pallet.supplementaryColours.DARK_GRAY,
            theme.palette.DSS_Pallet.mainColours.GREEN,
            theme.palette.DSS_Pallet.mainColours.DARK_BROWN
        ]
    }

    // get the most common RM drivers
    let topDriverNum = 2
    let topRmDrivers = getTopRmDrivers(rmWithSimilarInitialConditions, topDriverNum)
    // console.log('rmDrivers: ', topRmDrivers)

    //--------- CTM related ----------
    // get the data to display in the CTM graph
    let ctmData = getCTMData(rmWithSimilarInitialConditions)

    // Find the data related to 'Developed' ctm
    let ctmDevDataArray = ctmData.data.find(ctmClass => ctmClass.name === 'Developed').data
    // console.log('ctmDevDataArray:', ctmDevDataArray)

    // Create an array with objects that contain all capitals and its scores
    let capitalsCounterArr = []
    ctmDevDataArray.forEach((val, idx) => capitalsCounterArr.push({ capital: ctmData.labels[idx], score: val }))
    // console.log("capitalsCounterArr:", capitalsCounterArr)

    capitalsCounterArr.sort(compareField('score')) // sort array by the scores
    // console.log("capitalsCounterArr:", capitalsCounterArr)

    // Create an array just with the capital names. This array is already sorted.
    let allCapitalsOrdered = capitalsCounterArr.map(obj => obj.capital)

    // Get the top 2 capital names used to field up the sentence
    let topRmCapitals = allCapitalsOrdered.slice(0, 2)

    // options for the ctm graph
    let optCTM = {
        labels: ctmData.labels, // insert ctm labels
        title: {
            text: 'CAPITAL TRANSFERENCE MECHANISM',
            align: 'center'
        },
        colors: [
            theme.palette.DSS_Pallet.mainColours.BROWN,
            theme.palette.DSS_Pallet.mainColours.YELLOW,
            theme.palette.DSS_Pallet.mainColours.DARK_GREEN,
            theme.palette.DSS_Pallet.mainColours.GRAY,
            theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN,
            theme.palette.DSS_Pallet.supplementaryColours.DARK_GRAY,
            theme.palette.DSS_Pallet.mainColours.GREEN,
            theme.palette.DSS_Pallet.mainColours.DARK_BROWN
        ],
        chart: {
            toolbar: {
                show: false
            }
        },
        legend: {
            show: true,
            position: 'right', //'bottom',
            horizontalAlign: 'left',
            floating: false,//true,
            // width: 150,
            // height: 140, // should be the height of the graph
            // offsetY: -10, // For some reason it hiddes to last element of the legend
            // width: 200,
            formatter: function (seriesName, opts) {
                return [seriesName.toUpperCase()]
            }
        }
    }

    //--------- End of CTM related ----------

    // ----------------
    // --- 3rd part ---
    // ----------------
    // Get selected ctm based on top Capitals and Drivers (used for the tabs with tables)
    let ctmSelected = getTopCTM(topRMList, allCapitalsOrdered, topRmDrivers)

    return (
        <React.Fragment>
            <Container fixed>
                <Box py={1}>

                    <Box mt={2}>
                        <Typography variant={'body2'} component={'div'}>
                            <Box mb={2}>
                                Based on all your answers, we have selected and sorted the top {topListNum} Role Models
                                (out of {rmLenght}) and the top {topListNum} Lessons Learnt (out of {llLenght}) that we
                                think that might be worth for you to explore. We think that they are the ones with more
                                similarities with your project. You can explore it in the following lists.
                            </Box>
                        </Typography>

                        {/*top lists of RM and LL*/}
                        <Box my={6}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Box maxWidth={800}>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                <Typography>ROLE MODELS</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box width='100%'>
                                                    <RMList db={db} rmList={topRMList} filter={() => true}/>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                <Typography>LESSONS LEARNT</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box>
                                                    <LLList db={db} llList={topLLList}/>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>

                    <Box my={4}>

                        <Typography component={'div'} variant={'body2'}>
                            <Box mb={2}>
                                From our analyses, Role Models that have similar Initial Conditions and Challenges as
                                your project, seems that they frequently develop strategies around the capitals
                                of <b>{topRmCapitals[0]}</b> and <b>{topRmCapitals[1]}</b>.
                                Also, the main drivers are descrived
                                as <b>{topRmDrivers[0]}</b> and <b>{topRmDrivers[1]}</b>.
                                From these Role Models, who have similar Initial Conditions, you can
                                visualize and have an idea of how those categories are distributed in the following
                                graphs.
                            </Box>
                        </Typography>

                        {/*Graphs*/}
                        <Box my={6}>
                            <Grid container>

                                <Grid item xs={12} md={6}>
                                    {/*CTM Graph*/}
                                    <Box component={'div'} border={0} height={300} p={0} m={0}>
                                        <Chart options={optCTM}
                                               series={ctmData.data}
                                               type="radar"
                                               height="100%" // ("100%"| "auto") // !! If set to 100% then set parent to have a fixed height !!
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box height={300} p={0} m={0}>
                                        {/*RMDrivers Graph*/}
                                        <RmDrivers data={driversData.data} options={optDrivers}/>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                        <Typography component={'div'} variant={'body2'}>
                            <Box mt={1}>
                                Next, you have our selection of some developed practices watching these Capitals and
                                Drivers where you might take some ideas. We have sorted those lists according to what we
                                think that might be more relevant based on the descriptions that you have provided.
                                You can also explore these developed practices by looking to the full Role Model
                                where it was performed.
                            </Box>
                        </Typography>

                        <Box my={4}>
                            <CtmSelectionTables db={db} ctmSelected={ctmSelected}/>
                        </Box>
                        {/*<Divider/>*/}

                        {/*<Box mt={4}>*/}
                        {/*    Having your selection of Crosscutting in mind and ??? our Lessons Learnt tell us that the*/}
                        {/*    following*/}
                        {/*    Capitals tend to be more dominant:<br/>*/}
                        {/*    - Aafdf<br/>*/}
                        {/*    - asdsad<br/>*/}
                        {/*    <br/>*/}

                        {/*    Having the following Initial Conditions and Key Elements<br/>*/}
                        {/*    (Tables)<br/>*/}

                        {/*    helped to reach some of the following Achievements:<br/>*/}
                        {/*    (table)<br/>*/}
                        {/*    <br/>*/}

                        {/*    The following RMAs were frequently referenced from the Lessons Learnt that contain those*/}
                        {/*    Crosscuttings.You*/}
                        {/*    might get some extra ideas by exploring them.<br/>*/}
                        {/*    (Table)<br/>*/}
                        {/*    <br/>*/}
                        {/*    <Divider/>*/}

                        {/*</Box>*/}


                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}