import React from 'react'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

// import Ads from './Ads'
import ReportInfo from './ReportInfo'

export default function Report (props) {

    const { db, wizardSelections, handleGoToWizard } = props

    return (

        <Container fixed>
            {/*Space for the Report*/}
            <Grid item xs={12}>
                <Paper component={Box} style={{ minHeight: 300 }} p={3}>
                    <ReportInfo
                        db={db}
                        wizardSelections={wizardSelections}
                        handleGoToWizard={handleGoToWizard}/>
                </Paper>
            </Grid>

            {/*Right Side Space for ADS*/}
            {/*<Grid item xs={2}>*/}
            {/*    <Box m={1} p={1} border={1} boxShadow={0}>*/}
            {/*        <Ads/>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}

        </Container>

    )
}