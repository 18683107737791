import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

import TableActions from './TableActions'
import TableCapitalTransferenceMechanism from './TableCapitalTransferenceMechanism'
import TableChallenges from './TableChallenges'
import TableList from './TableList'
import ProcessPanel from './ProcessPanel'
import BasePaper from './BasePaper'

import { theme } from '../theme/theme'
import TableActionsEnhancements from './TableActionsEnhancements'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    expansionPanel: {
        height: '100%'
    }
}))

function Title (props) {

    let { bgColor } = props
    if (typeof bgColor === 'undefined') {
        bgColor = 'secondary.main'
    }
    return (
        <Typography variant="h6">
            <Box color={bgColor} letterSpacing={2} fontSize="htmlFontSize"
                 fontWeight="fontWeightMedium">
                {props.children}
            </Box>
        </Typography>
    )
}

function CostumExpandMoreIcon (props) {
    return (<Box color={props.color}><ExpandMoreIcon/></Box>)
}

export default function RmPanel (props) {
    const { db, rm } = props
    const classes = useStyles()

    //console.log(rm)
    // const rmColor = theme.palette.DSS_Sia_Colours[rm['SIA NAME']]
    const rmColor = theme.palette.DSS_Sia_Colours[rm['SIA NAME']]
    // const rmColor = theme.palette.primary.dark

    return (

        <div className={classes.root}>

            {
                (
                    rm
                    && Object.keys(rm).length === 0
                    && Object.getPrototypeOf(rm) === Object.prototype
                ) ?
                    <p></p>
                    :
                    <Grid container spacing={2}>

                        {/*SIA info*/}
                        <Grid item xs={12}>
                            <BasePaper>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Avatar src={require('../img/SIA_pictograms-Icons/' + rm['SIA NAME'] + '.png')}
                                            alt={rm['SIA NAME']}/>
                                    <Typography component="div">
                                        <Box color={rmColor}>
                                            {rm['SIA NAME']}
                                        </Box>
                                    </Typography>
                                </Box>
                                <Box pt={1}>
                                    <Typography>
                                        <strong>Role Model Name: </strong> {rm['Name']}
                                    </Typography>
                                </Box>
                                <Box pt={1}>
                                    <Typography>
                                        <strong>ID: </strong> {rm['RM']}
                                    </Typography>
                                </Box>
                                <Box pt={1}>
                                    <Typography>
                                        <strong>Replicability: </strong> {rm['Practices']['REPLICABILITY']}
                                    </Typography>
                                </Box>
                                <Box pt={1}>
                                    <Typography>
                                        <strong>Size: </strong>{rm['Context']['Size']}
                                    </Typography>
                                </Box>
                                <Box pt={1}>
                                    <Typography>
                                        <strong>Main Economic Sector: </strong>{rm['Context']['MainEconomicSector']}
                                    </Typography>
                                </Box>

                                <Box pt={1}>
                                    <Typography>
                                        <strong>Geography: </strong>{rm['Context']['Geography']}
                                    </Typography>
                                </Box>

                            </BasePaper>
                        </Grid>

                        {/*Process*/}
                        <Grid item xs={12}>
                            <Accordion xs={12} defaultExpanded>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>PROCESS</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <ProcessPanel rm={rm}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*ROLE MODEL ACTIONS*/}
                        <Grid item xs={12}>
                            <Accordion xs={12} defaultExpanded>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>ROLE MODEL ACTIONS</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TableActions db={db} modelActions={rm.ModelActions} mainColor={rmColor}/>
                                        </Grid>
                                        {
                                            rm.rma_enhancements &&
                                            <Grid item xs={12}>
                                                <TableActionsEnhancements db={db} rmaEnhancements={rm.rma_enhancements} mainColor={rmColor}/>
                                            </Grid>
                                        }

                                    </Grid>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*Drivers*/}
                        <Grid item xs={12} sm={6}>
                            <Accordion xs={12}>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>DRIVERS</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={rm['Practices']['DRIVERS']}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*CHALLENGES*/}
                        <Grid item xs={12} sm={6}>
                            <Accordion xs={12}>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>CHALLENGES</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableChallenges challenges={rm.Practices['CHALLENGES']} mainColor={rmColor}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*BARRIERS*/}
                        <Grid item xs={12} sm={6}>
                            <Accordion xs={12}>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>BARRIERS</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={rm.Practices.BARRIERS}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*KEY RESOURCES*/}
                        <Grid item xs={12} sm={6}>
                            <Accordion xs={12}>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>RELEVANT HERITAGE</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={rm['Practices']['RELEVANT HERITAGE']}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*CAPITAL TRANSFER MECHANISM*/}
                        <Grid item xs={12}>
                            <Accordion xs={12} defaultExpanded>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>CAPITAL TRANSFER MECHANISM</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableCapitalTransferenceMechanism ctm={rm.Practices['CAPITAL TRANSFER MECHANISM']}
                                                                       mainColor={rmColor}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*KNOWLEDGE BUILDING*/}
                        <Grid item xs={12} sm={6}>
                            <Accordion xs={12}>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>KNOWLEDGE BUILDING</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={rm['Practices']['KNOWLEDGE BUILDING']}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*CO-BENEFITS*/}
                        <Grid item xs={12} sm={6}>
                            <Accordion xs={12}>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>CO-BENEFITS</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={rm['Practices']['CO-BENEFITS']}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*KEYWORDS*/}
                        <Grid item xs={12}>
                            <Accordion xs={12}>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon color={rmColor}/>}>
                                    <Title bgColor={rmColor}>KEYWORDS</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={rm['Practices']['KEYWORDS']}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                    </Grid>
            }

        </div>

    )
}