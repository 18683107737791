import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Box, FormLabel, RadioGroup } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'

import { RM_SIZES } from '../../../js/utils'
import WizardQuestion from './wizardStepsUtilities/WizardQuestion'


export default function SizeStep (props) {

    const { wizardSelections, handleOnChanges } = props

    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <WizardQuestion>
                    How would you describe your project in terms of size?
                </WizardQuestion>
            </Grid>

            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="size" name="size1" value={wizardSelections.size}
                                onChange={handleOnChanges.size}>
                        {
                            RM_SIZES.map(size =>
                                <FormControlLabel
                                    key={size}
                                    value={size}
                                    control={<Radio/>}
                                    label={<Typography variant={'overline'}>{size}</Typography>}
                                />
                            )
                        }
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )

}