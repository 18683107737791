import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Box } from '@material-ui/core'

import RMAInfo from './RMAInfo'

const RMA_NOT_FOUND = "RMA_NOT_FOUND"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(0),
        overflowX: 'auto'
    },
    button: {
        color: props => props.mainColor,
        borderColor: props => props.mainColor,
        justifyContent: "flex-start",
        textAlign:'left'
    }
}))

function MyButton(props) {
    const { mainColor, ...other } = props;
    const classes = useStyles(props);
    return <Button variant="outlined" size="small" className={classes.button} {...other} />;
}

export default function TableActions (props) {
    //console.log(props)
    const classes = useStyles()
    const { db, modelActions, mainColor } = props

    // Define the primary color for all page
    let primaryColor = mainColor
    if( typeof mainColor === 'undefined'){
        primaryColor = "secondary"
    }

    // If modelActions is undefined then pass an empty array
    let actions = modelActions
    if (typeof modelActions === 'undefined') {
        actions = []
        console.log('WARNING!! modelActions undefined!')
    }

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogID, setDialogId] = React.useState(false);

    function handleOpen(rmaDescription){
        setDialogId(findRmaIdByDescription (rmaDescription))
        setDialogOpen(true)
    }
    function handleClose(){
        setDialogOpen(false)
    }

    function findRmaIdByDescription (rmaDescription) {

        // Find rma by description
        let rma = db.rma.find(a => a["NAME OF THE RM ACTION"] === rmaDescription)

        // If RMA wasn't found then pass an non existing RMA name
        let id = rma ? rma["CODE RM ACTION"] : RMA_NOT_FOUND

        return id
    }

    return (
        <Box className={classes.root}>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><Box color={primaryColor}>RELEVANCE</Box></TableCell>
                        <TableCell align="left"><Box color={primaryColor}>DESCRIPTION</Box></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        actions.map(row => (
                            <TableRow key={row.description} hover>
                                <TableCell>{row.relevance}</TableCell>
                                <TableCell align="left" >
                                    <MyButton
                                        mainColor={primaryColor}
                                        // disabled={findRmaIdByDescription(row.description) === RMA_NOT_FOUND}
                                        onClick={()=>handleOpen(row.description)}>
                                        {row.description}
                                    </MyButton>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogContent>
                    <RMAInfo db={db} actionId={dialogID}/>
                </DialogContent>
            </Dialog>
        </Box>
    )

}