import React from 'react'
import Box from '@material-ui/core/Box'
import { theme } from '../theme/theme'
import IconButton from '@material-ui/core/IconButton'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

export default function DSSInfo (props) {

    const [openDssInfo, setOpenDssInfo] = React.useState(false)

    function handleOpenDssInfo () {
        setOpenDssInfo(!openDssInfo)
    }

    return (
        <Box m={1} bgcolor="background.paper" boxShadow={3}>
            <Box
                p={1}
                bgcolor={theme.palette.primary.main}
                // bgcolor={theme.palette.secondary.light}
                color={theme.palette.background.paper}
                display="flex"
                alignItems="center"
            >
                {/*Bar Title*/}
                <Box flexGrow={1}>
                    <Box onClick={() => handleOpenDssInfo()}
                         fontSize={16} fontWeight="fontWeightMedium" letterSpacing={2}>
                        HOW TO GET THE RIGHT INFORMATION?
                    </Box>
                </Box>
                {/*Bar Icon*/}
                <Box flexShrink={1}>
                    <IconButton size="small" onClick={() => handleOpenDssInfo()}>
                        {
                            (openDssInfo) ?
                                <ExpandLessIcon style={{ color: theme.palette.background.paper }}
                                                fontSize="small"/>
                                :
                                <ExpandMoreIcon style={{ color: theme.palette.background.paper }}
                                                fontSize="small"/>
                        }
                    </IconButton>
                </Box>
            </Box>

            <Collapse in={openDssInfo}>
                <Box display="flex" flexDirection="column" width="100%" mt={1}>

                    <Box m={1}>

                        <Typography variant="body2" component="div">
                            <Box my={2}>
                                The DSS has 3 mainly different sections:

                                <Grid container>
                                    <Grid item xs={12} sm={4}>
                                        <Box m={1} p={1} border={1}
                                             borderColor={theme.palette.secondary.main}
                                             height="100%"
                                        >
                                            <Box mb={1} fontSize={14} textAlign="center"
                                                 fontWeight="fontWeightMedium" letterSpacing={2}
                                                 color={theme.palette.secondary.main}>
                                                ROLE MODELS & REPLICATORS & LESSONS LEARNT
                                            </Box>
                                            Contain lists of valuable collections of
                                            previous practices and information that you can explore and
                                            learn from. Open each element that might be more relevant for you.
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box m={1} p={1} border={1}
                                             borderColor={theme.palette.DSS_Pallet.mainColours.DARK_BROWN}
                                            // boxShadow={theme.boxShadow.DARK_BROWN}
                                             height="100%"
                                        >
                                            <Box mb={1} fontSize={14} textAlign="center"
                                                 fontWeight="fontWeightMedium" letterSpacing={2}
                                                 color={theme.palette.DSS_Pallet.mainColours.DARK_BROWN}>
                                                FILTERS
                                            </Box>

                                            Allows you to best refine ROLE MODELS, REPLICATORS and LESSONS LEARNT
                                            lists. By activating or deactivating the available filters, it
                                            reflects your choices immediately by showing or hiding the related
                                            elements on
                                            each collection.
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box m={1} p={1} border={1}
                                             borderColor={theme.palette.DSS_Pallet.mainColours.YELLOW}
                                            // boxShadow={theme.boxShadow.YELLOW}
                                             height="100%"
                                        >
                                            <Box mb={1} fontSize={14} textAlign="center"
                                                 fontWeight="fontWeightMedium" letterSpacing={2}
                                                 color={theme.palette.DSS_Pallet.mainColours.YELLOW}>
                                                SELECTION SUMMARY
                                            </Box>

                                            Helps you to understand your data selection
                                            produced by your filtration. Here you have a graphical
                                            perspective related to
                                            the insights of your selection. In some cases you might
                                            interact
                                            with
                                            these graphs bringing another way of exploring that
                                            selection of
                                            data.
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Typography>
                    </Box>

                </Box>
            </Collapse>

        </Box>
    )
}