import React, { Component } from 'react'
import Chart from 'react-apexcharts'

import { theme } from '../../theme/theme'

export default class LLReplicability extends Component {

    constructor (props) {
        super(props)

        this.initialConfiguration = {

            series: [0, 0, 0],
            options: {
                labels: ['A', 'B', 'C'],
                title: {
                    text: 'REPLICABILITY',
                    align: 'center'
                },
                legend:{
                    width: 200,
                    formatter: function(seriesName, opts) {
                        return [seriesName.toUpperCase()]
                    }
                },
                colors: [
                    theme.palette.DSS_Pallet.mainColours.BROWN,
                    theme.palette.DSS_Pallet.mainColours.YELLOW,
                    theme.palette.DSS_Pallet.mainColours.DARK_GREEN,
                    theme.palette.DSS_Pallet.mainColours.GRAY,
                    theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN,
                    theme.palette.DSS_Pallet.supplementaryColours.DARK_GRAY,
                    theme.palette.DSS_Pallet.mainColours.GREEN,
                    theme.palette.DSS_Pallet.mainColours.DARK_BROWN
                ]
            },

        }

    }

    updateReplicabilityData = (llList) => {

        let series = []
        let labels = []

        let data = {}

        // Find lables and count them
        llList.forEach(lesson => {
            let label = lesson['REPLICABILITY']
            if (label in data) {
                data[label]++
            } else {
                data[label] = 1
            }
        })

        // Create labels list
        labels = Object.keys(data)

        // Create series list
        labels.forEach(label => {
            series.push(data[label])
        })

        // Update the options labels
        let options = { ...this.initialConfiguration.options }
        options.labels = labels

        let obj = { series, options }
        // console.log("object: ", obj)
        return obj

    }

    render () {

        const { llList } = this.props

        let data = this.updateReplicabilityData(llList)
        
        return (
            <div style={{ height: 150 }}>

                <Chart options={data.options}//{this.state.options}
                       series={data.series}//{this.state.series}
                       type="pie" // donut
                       height="100%" // ("100%"| "auto") // !! If set to 100% then set parent to have a fixed height !!
                />
            </div>
        )
    }

}