import React from 'react'
import { Box } from '@material-ui/core'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import DialogInfo from '../DialogInfo'
import RmPanel from '../RmPanel'
import { RURITAGE_SIAS_LOGOS } from '../../js/utils'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ListItemIcon from '@material-ui/core/ListItemIcon'

export default function RMList (props) {

    const { db, rmList } = props

    // const { rmList, sias } = props

    const [selectedRM, setSelectedRM] = React.useState('undefined')
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [rmDialog, setRmDialog] = React.useState({})

    function handleDialogClose () {setDialogOpen(false)}

    function handleListItemClick (event, elem) {
        setSelectedRM(elem.Name)    // keeps the highligh of the selected RM
        setRmDialog(elem)           // gives the data be displayed in dialog window
        setDialogOpen(true)   // Opens the dialog
    }

    function checkRmBellongsToActiveSias (rm, sias) {
        return sias[rm['RELATED SIA']]
    }

    function getLogoInfo (elem) {
        return RURITAGE_SIAS_LOGOS.find((v, i, a) => v['RELATED SIA'] == elem['RELATED SIA'])
    }

    return (
        <Box maxHeight={300} overflow="auto">
            <List nav="nav" dense>

                {
                    rmList.map(elem => (
                        <React.Fragment key={elem.Name}>

                            <ListItem
                                button
                                onClick={event => handleListItemClick(event, elem)}
                            >

                                <ListItemIcon>
                                    <Tooltip
                                        title={getLogoInfo(elem).alt}
                                        aria-label="Logo">
                                        <img
                                            src={getLogoInfo(elem).img}
                                            alt={getLogoInfo(elem).alt}
                                            style={{ width: 30, marginRight: 10 }}
                                        />
                                    </Tooltip>
                                </ListItemIcon>

                                <ListItemText
                                    primary={elem.Name}
                                />

                            </ListItem>
                            <Divider/>
                        </React.Fragment>
                    ))
                }

            </List>


            <DialogInfo dialogOpen={dialogOpen}
                        title={selectedRM}
                        handleClose={() => handleDialogClose()}
            >
                <RmPanel db={db} rm={rmDialog}/>
            </DialogInfo>

        </Box>
    )
}
