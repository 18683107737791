import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { theme } from '../theme/theme'

export default class DialogInfo extends Component {
    render () {

        const { dialogOpen, title, handleClose, backgroundColor } = this.props

        // Set background color for dialog content
        let bgColor = theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN
        if(typeof backgroundColor !== 'undefined'){
            bgColor =  backgroundColor
        }

        return (

            <Dialog
                open={dialogOpen} fullScreen
                onClose={handleClose}
                aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"

            >

                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent
                    style={{backgroundColor: bgColor}}
                >

                    {this.props.children}

                </DialogContent>
            </Dialog>
          
        )
    }
}