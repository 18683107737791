import React, { Component } from 'react'

import Box from '@material-ui/core/Box'

import { POLITO_DB_API } from '../js/config'
import { getAllRmFromLL } from '../js/dataFetchUtils'

// import {
//     BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams
// } from 'react-router-dom'
import { withRouter } from 'react-router'

import RmPanel from './RmPanel'
import RMAInfo from './RMAInfo'
import LessonLearntInfo from './LessonLearntInfo'
import BasePaper from './BasePaper'

// Shows RM, LL and RMA info pages by looking into the URL
// URL format: "/links/:listId/:elemId"
// Examples:
// - Role Models: "/links/rmlist/RM1"
// - Lessons Learnt: "/links/lllist/LL01"
// - RMA: "/links/rmalist/RM1-1"
class Datalinks extends Component {

    constructor (props) {
        super(props)

        this.state = {
            selectedRm: {},
            selectedRma: '',
            selectedLL: { title: 'LL01' }
        }

        // Ruritage Data
        this.db = {
            rm: [],
            rma: [],
            ll: [],
            rm_ll: {},
            ll_rm: {},
            rmas: [],
            stakeholders: []
        }

        // Possible link ListId's names
        this.LIST_ID_NAMES = {
            RM_LIST: 'rmlist',
            LL_LIST: 'lllist',
            RMA_LIST: 'rmalist'
        }
        // Variables to hold the listId and elemId comming from the URL ".../:listId/:elemId"
        this.listId = this.props.match.params.listId
        this.elemId = this.props.match.params.elemId

    }

    componentDidMount () {

        const p_rm = fetch(POLITO_DB_API + 'rm').then(res => res.json())
        const p_rma = fetch(POLITO_DB_API + 'rma').then(res => res.json())
        const p_rm_ll = fetch(POLITO_DB_API + 'rm_ll').then(res => res.json())
        const p_ll = fetch(POLITO_DB_API + 'll').then(res => res.json())

        const p_rmas = fetch(POLITO_DB_API + 'rmas').then(res => res.json())
        const p_stackholders = fetch(POLITO_DB_API + 'stakeholders').then(res => res.json())

        Promise.all([p_rm, p_rma, p_rm_ll, p_ll, p_rmas, p_stackholders])
            .then((data) => {
                // console.log('data: ', data)

                let ll_rm = getAllRmFromLL(data[3])

                // update Ruritage data
                this.db = {
                    rm: data[0], //p_rm,
                    rma: data[1], //p_rma,
                    rm_ll: data[2], //p_rm_ll,
                    ll: data[3],// p_ll,
                    ll_rm: ll_rm,
                    rmas: data[4], // p_rmas
                    stakeholders: data[5] //p_stackeholders
                }
                // console.log(this.db)
            })
            .then(() => this.updatePageData())

    }

    // Update the page with data
    updatePageData = () => {
        if (this.listId === this.LIST_ID_NAMES.RM_LIST) {
            this.updateRmData()
        } else if (this.listId === this.LIST_ID_NAMES.LL_LIST) {
            this.updateLLData()
        } else if (this.listId === this.LIST_ID_NAMES.RMA_LIST) {
            this.updateRmaList()
        } else {
            console.log('DataLinks: Unknown List: ', this.list)
        }
    }

    updateRmData = () => {

        // Get the rm data
        let selectedRm = this.db.rm.find(rm => rm.RM === this.elemId.toLowerCase())
        if (typeof selectedRm === 'undefined') {
            console.log('RM ID UNDEFINED')
            selectedRm = {}
        }

        // Update state of the showed component
        this.setState({
            ...this.state,
            selectedRm: selectedRm
        })
    }
    updateLLData = () => {

        // Get the rm data
        let selectedLL = { title: this.elemId.toUpperCase() }

        // Update state of the showed component
        this.setState({
            ...this.state,
            selectedLL: selectedLL
        })
    }
    updateRmaList = () => {
        // Get the rm data
        let selectedRma = this.elemId.toUpperCase()

        // Update state of the showed component
        this.setState({
            ...this.state,
            selectedRma: selectedRma
        })
    }

    render () {

        let elemDom
        if (this.listId === this.LIST_ID_NAMES.RM_LIST) {
            elemDom = <RmPanel db={this.db} rm={this.state.selectedRm}/>
        } else if (this.listId === this.LIST_ID_NAMES.LL_LIST) {
            elemDom = <LessonLearntInfo db={this.db} llContent={this.state.selectedLL}/>
        } else if (this.listId === this.LIST_ID_NAMES.RMA_LIST) {
            elemDom = <BasePaper><RMAInfo db={this.db} actionId={this.state.selectedRma}/></BasePaper>
        } else {
            elemDom = <p>Data not found!</p>
        }

        return (
            <Box m={1}>
                {elemDom}
            </Box>
        )
    }
}

export default withRouter(Datalinks)
