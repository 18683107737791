import React from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Box, Container, Paper } from '@material-ui/core'

import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    Link
} from 'react-router-dom'

import { theme } from '../../theme/theme'

import { RURITAGE_SIAS_LOGOS } from '../../js/utils'

function SiasIcons () {
    return (
        <Grid container
              direction="row"
            // justify="space-around"
              alignItems="stretch">
            {RURITAGE_SIAS_LOGOS.map(elem =>
                <Grid key={elem.alt} item xs={4} sm={4} md={2}>
                    <Box my={3} border={0} align={'center'} style={{ height: '100%' }}>
                        <img
                            src={elem.img}
                            alt={elem.alt}
                            style={{ width: 80 }}
                        />
                        <Typography variant={'h6'} component={'div'}>
                            {elem.alt}
                        </Typography>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

export default function Home () {

    return (
        <React.Fragment>

            <Container fixed>
                <Paper style={{ padding: theme.spacing(2) }}>

                    <Box textAlign="center">
                        <Typography variant="h5">
                            <Box my={4}>
                                <Box component="span"
                                     color={theme.palette.secondary.main}
                                >
                                    WELCOME</Box> to the RURITAGE Decision Support System!
                            </Box>
                        </Typography>
                    </Box>

                    <Typography variant="body1">
                        The RURITAGE Decision Support System (DSS) is a system for supporting the discovery and
                        composition of possible heritage-led regeneration scenarios, which considers previous
                        initiatives and provides suggestions for combining good practices and Lessons Learnt from
                        various Role Models, allowing the choice of comprehensive programmes to be implemented at
                        replicating sites.
                    </Typography>

                    <SiasIcons/>

                    <Box my={3}>
                        <Typography variant={'body1'} component={'div'}>
                            You might want to try different approaches to find the right information for you. Take a
                            look on the following ones.
                        </Typography>
                    </Box>

                    <Grid container spacing={1}>

                        <Grid item xs={12} sm={6}>

                            <Box height={1} p={1} boxShadow={3} display="flex" flexDirection="column"
                                 justifyContent="end">

                                <Box flexGrow={1}>
                                    <Typography variant={'h6'} color='secondary'>
                                        Take the Wizard
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        The Wizard page is meant to simplify the search of information by guiding you
                                        through predefined questions and aiming, at the end, to provide a report with
                                        relevant information and tips that might help you get better ideas of best
                                        practices to be implemented on your projects.
                                    </Typography>
                                </Box>
                                {/*<Typography variant={'body1'}>*/}
                                {/*    Answer some questions and we will provide to you a report with what we think might*/}
                                {/*    be the best practices that you can perform to achieve your goals*/}
                                {/*</Typography>*/}

                                <Box mt={3}>
                                    <Link to="/wizard" style={{ textDecoration: 'none' }}>
                                        <Button variant="contained" color="primary" fullWidth size={'small'}>
                                            Wizard
                                        </Button>
                                    </Link>
                                </Box>

                            </Box>

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box height={1} p={1} boxShadow={3} display="flex" flexDirection="column"
                                 justifyContent="end">

                                <Box flexGrow={1}>
                                    <Typography variant={'h6'} color='secondary'>
                                        Explore the Data
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        Freely explore all data contained in the DSS. The data includes the contents of
                                        Role Models and Lessons Learnt. Mechanisms of filtering and sorting data are
                                        available to help you refine your searches.
                                    </Typography>
                                </Box>

                                <Box mt={3}>
                                    <Link to="/exploredata" style={{ textDecoration: 'none' }}>
                                        <Button variant="contained" color="primary" fullWidth size={'small'}>
                                            Explore Data
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>

                        </Grid>

                    </Grid>

                </Paper>

            </Container>

        </React.Fragment>

    )
}