import React, { Component } from 'react'
import Chart from 'react-apexcharts'

export default class RmDrivers extends Component {

    render (props) {
        let { data, options } = this.props

        return (
            <Chart options={options}//{this.state.options}
                   series={data}//{this.state.series}
                   type="pie" // donut
                   height="100%" // ("100%"| "auto") // !! If set to 100% then set parent to have a fixed height !!
            />
        )
    }
}