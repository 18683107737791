import React from 'react'
import { Box, Button, Paper } from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import RMAInfo from '../../RMAInfo'
import DialogInfo from '../../DialogInfo'
import RmPanel from '../../RmPanel'

function RMATable (props) {

    const { db, modelActions } = props

    const [dialogRmaOpen, setDialogRmaOpen] = React.useState(false)
    const [dialogID, setDialogId] = React.useState(false)

    const [dialogRmOpen, setDialogRmOpen] = React.useState(false)
    const [dialogRmID, setDialogRmID] = React.useState(false)

    // ---- function handlers for dialogs ----
    function handleRmaOpen (rmaDescription) {

        // Find rma
        let rma = db.rma.find(rma => rma['NAME OF THE RM ACTION'] === rmaDescription)

        // if rma was found then set rmaId
        let rmaId = rma ? rma['CODE RM ACTION'] : ''

        setDialogId(rmaId)
        setDialogRmaOpen(true)
    }

    function handleRmaClose () {
        setDialogRmaOpen(false)
    }

    function handleDialogRmOpen (rmId) {
        setDialogRmID(rmId)
        setDialogRmOpen(true)
    }

    function handleDialogRmClose () {
        setDialogRmOpen(false)
    }

    //------------

    function findRmFromRmDescription (rmaDescription) {

        let rm = {}

        db.rm.forEach(rmObj => {
            let ma = rmObj['ModelActions']

            // if ModelActions exist find the one with the right description
            ma && ma.forEach(maObj => {
                if (maObj['description'] === rmaDescription){
                    rm = rmObj
                }
            })
        })
        return rm

    }

    function rmButton (rmaDescription) {

        let rm = findRmFromRmDescription(rmaDescription)
        // let rm = findRmFromRmaId(rmaId)

        return (
            <React.Fragment>
                {
                    rm ?
                        <Button
                            onClick={() => handleDialogRmOpen(rm)}
                            variant="outlined" size="small" fullWidth
                        >
                            {rm['RM']}
                        </Button>
                        :
                        <Button disabled variant="outlined" size="small" fullWidth>--</Button>
                }
            </React.Fragment>
        )

    }

    return (
        <Box maxHeight={300} overflow="auto" component={Paper} >
            <Table size="small" stickyHeader>
                <TableHead >
                    <TableRow>
                        <TableCell width={90}>RELEVANCE</TableCell>
                        <TableCell align="left">RMA DESCRIPTION</TableCell>
                        <TableCell>RM</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        modelActions.map(ma => (
                            <TableRow key={ma.description} hover>

                                <TableCell>{ma.relevance}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleRmaOpen(ma.description)}
                                        style={{ justifyContent: 'flex-start', textAlign: 'left' }}
                                        variant="outlined" size="small"
                                        fullWidth
                                    >
                                        {ma.description}
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    {rmButton(ma.description)}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            {/*Dialog for RMA*/}
            <Dialog
                open={dialogRmaOpen}
                onClose={handleRmaClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogContent>
                    <RMAInfo db={db} actionId={dialogID}/>
                </DialogContent>
            </Dialog>

            {/*Dialog for RM*/}
            <DialogInfo dialogOpen={dialogRmOpen}
                        title={dialogRmID['Name']}
                        handleClose={() => handleDialogRmClose()}
            >
                <RmPanel db={db} rm={dialogRmID}/>
            </DialogInfo>
        </Box>
    )
}

export default function RMAGroupAnalyze (props) {

    const { db, rmList } = props

    // Create array of all ModelActions presented in the rmList
    let allModelActions = []
    rmList.forEach(rm => {

        if(rm['ModelActions']){
            allModelActions = [...allModelActions, ...rm['ModelActions']]
        }

    })

    return (
        <Box>


            <RMATable db={db} modelActions={allModelActions}/>

            {/*<Box>*/}
            {/*    RMA GRAPHS*/}
            {/*</Box>*/}


        </Box>
    )
}