
export const preprocessRmData = (allRM) => {

    for (let i = 0; i < allRM.length; i++) {
        allRM[i]['RM'] = allRM[i]['RM'].toUpperCase() // turn to uppercase all RM ids
    }

    return allRM
}


// Returns an object contained to link between LL to RMs based on all LL in db
export const getAllRmFromLL = (allLL) => {

    let llObj = {}

    // let allLL = this.db.ll
    for (let i = 0; i < allLL.length; i++) {
        let ll = allLL[i]
        llObj[ll['LL CODE']] = getRmFromLL(ll)
    }

    return llObj
}

// Returns a RM list mentioned in the LL based on RM actions field.
function getRmFromLL (ll) {

    let rmList = []
    if (typeof ll['CODE RM ACTION'] !== 'undefined') {
        let rmaList = ll['CODE RM ACTION']
        for (let i = 0; i < rmaList.length; i++) {
            let actionName = rmaList[i].trim()
            let idx = actionName.indexOf('-')
            let rmName = actionName.slice(0, idx)
            rmList.push(rmName)
        }
    } else {
        console.log('Warning! Lesson Learnt ' + ll['LL CODE'] + ' does not have CODE RM ACTION key')
    }

    // filter list to have unique RMs
    let uniqueRmList = rmList.filter((v, i, a) => a.indexOf(v) === i) // array of unique RM

    return uniqueRmList
}