import { createMuiTheme } from '@material-ui/core'

const DSS_PALLET =
    {
        mainColours: {
            GRAY: '#635f60',
            YELLOW: '#f8b445',
            GREEN: '#95c23d',
            DARK_GREEN: '#0d9142',
            BROWN: '#b28252',
            DARK_BROWN: '#6d451f',
        },
        supplementaryColours: {
            LIGHT_GREEN: '#c7db96',
            DARK_GRAY: '#525050',
            TEST: '#fafafa'
        }
    }

export const theme = createMuiTheme({
    //spacing: 8,
    palette: {
        primary: {
            main: DSS_PALLET.mainColours.DARK_GREEN,
            contrastText: '#fff'
        },
        secondary: {
            main: DSS_PALLET.mainColours.BROWN,
        },
        DSS_Pallet: DSS_PALLET,
        DSS_Sia_Colours: {
            //test: DSS_PALLET.mainColours.YELLOW
            'Art and Festival': DSS_PALLET.mainColours.YELLOW,
            'Integrated Landscape Management': DSS_PALLET.mainColours.DARK_GREEN,
            'Migration': DSS_PALLET.mainColours.GRAY,
            'Pilgrimage': DSS_PALLET.mainColours.DARK_BROWN,
            'Resilience': DSS_PALLET.mainColours.BROWN,
            'Sustainable Food Production': DSS_PALLET.mainColours.GREEN
        }
    },
    boxShadow1: '0 3px 5px 2px rgba(248, 180, 69, 0.3)',
    boxShadow: {
        GREEN: '2px 3px 5px 2px rgba(149, 194, 61, 0.5)',
        BROWN: '2px 3px 5px 2px rgba(178, 130, 82, 0.5)',
        DARK_BROWN: '2px 3px 5px 2px rgba(109, 69, 31, 0.5)',
        YELLOW: '2px 3px 5px 2px rgba(248, 180, 69, 0.5)'
    },
    lightGray: '#f8f8f8'
})