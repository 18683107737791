import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(0),
        overflowX: 'auto'
    }
}))

export default function TableChallenges (props) {

    const classes = useStyles()
    let { challenges, mainColor } = props
    if (typeof challenges === 'undefined') {
        console.log('WARNING!! challenges undefined!')
        challenges=[]
        // ctm.Geography = ''
        // ctm.MainEconomicSector = ''
        // ctm.Size = ''
    }

    // Define the primary color for all page
    let primaryColor = mainColor
    if( typeof mainColor === 'undefined'){
        primaryColor = "secondary"
    }


    return(
        <Box className={classes.root}>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><Box color={primaryColor}>CHALLENGE</Box></TableCell>
                        <TableCell><Box color={primaryColor}>YES/PARTIALLY</Box></TableCell>
                        <TableCell align="left"><Box color={primaryColor}>DESCRIPTION</Box></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        challenges.map(elem => (
                            <TableRow hover key={elem.CHALLENGE}>
                                <TableCell>{elem.CHALLENGE}</TableCell>
                                <TableCell align="left">{elem["YES/PARTIALLY"]}</TableCell>
                                <TableCell align="left">{elem.DESCRIPTION}</TableCell>
                            </TableRow>
                        ))
                    }



                </TableBody>
            </Table>
        </Box>
    )
}