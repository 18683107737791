import React from 'react'

import FilterControlLogic from './FilterControlLogic'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { Collapse } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'

import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined'

import IconButton from '@material-ui/core/IconButton'

import { theme } from '../theme/theme'
import SwitchList from './SwitchList'
import { DSSTooltipTile, DSSTooltipDescription, DSSTooltipExample } from '../js/utils'
import DSSHelpIcon from './DSSHelpIcon'

import InputAdornment from '@material-ui/core/InputAdornment'

export default function FiltersContainer (props) {

    const {
        logicFilter,
        logicFilterOnChange,
        filters,
        filterSwitchChanges,
        changeAllSwitches,
        semanticAnalysis,
        progressBarGeo
    } = props

    // Create filter visibility state
    let filterState = {}
    for (let i = 0; i < filters.length; i++) {
        filterState[filters[i].filterName] = true
    }
    const [filtersVisibility, setFiltersVisibility] = React.useState(filterState)

    // Show or Hide individual filters
    function handleFiltersVisibility (filterName) {
        let newState = { ...filtersVisibility }
        newState[filterName] = !newState[filterName]
        setFiltersVisibility(newState)
    }

    // Returns the text for the tooltip in geography textarea
    function geographyTextTooltip () {
        let title = 'GEOGRAPHY',
            description = 'Please describe some geographic features. We will look into Role Model Geography descriptions' +
                ' to identify the most relevant ones.',
            examples = 'Mountains, Sea, River, Forest, Peninsula, Valleys, Geopark, Active Volcanic Area, Coastline, ' +
                'Lakes, Waterfalls, Caves, etc.'

        return (
            <Box m={1}>
                {DSSTooltipTile(title)}
                {DSSTooltipDescription(description)}
                {DSSTooltipExample(examples)}
            </Box>
        )
    }

    function economicSectorTextTooltip () {
        let title = 'ECONOMIC SECTOR',
            description = 'Please describe the Economic Sectors. We will look into Role Model Economic Sectors ' +
                'descriptions to identify the most relevant ones.',
            examples = 'Agriculture, Industry, Manufacture, Food, fishery and forestry activities, tourism, wood ' +
                'processing, garment production, services sector, construction, textile, retail, health and social ' +
                'work, public administration and defence, education, etc.'
        return (
            <Box m={1}>
                {DSSTooltipTile(title)}
                {DSSTooltipDescription(description)}
                {DSSTooltipExample(examples)}
            </Box>
        )
    }

    function barriersTextTooltip () {

        let title = 'BARRIERS',
            description = 'Please describe the faced Barriers. We will look into Role Model Barriers descriptions to ' +
                'identify the most relevant ones.',
            examples = 'Economic sustainability, Raising funds, Lack of financial support, Lack of human resources, ' +
                'Lack of public policies of communication and dissemination in the municipalities, little participation' +
                ' of rural communities, migration of native people to cities, etc.'

        return (
            <Box m={1}>
                {DSSTooltipTile(title)}
                {DSSTooltipDescription(description)}
                {DSSTooltipExample(examples)}
            </Box>
        )
    }

    function endAdornmentIcon (title) {
        return (
            <InputAdornment position="end">
                <DSSHelpIcon tooltipTitle={title}/>
            </InputAdornment>
        )
    }

    return (

        <React.Fragment>

            <Grid container>

                {/*Features*/}
                <Grid item xs={12}>
                    <Box my={1} p={0}>

                        {/*Features Title and Description*/}
                        <Box>
                            <Typography component="div">
                                <Box color="primary.main" fontWeight="fontWeightMedium">
                                    Features
                                </Box>
                            </Typography>
                            {/*Features description*/}
                            <Typography variant="caption">
                                <Box fontWeight="fontWeightRegular" fontStyle="oblique" lineHeight={1.2}>
                                    Combine the right features to get the most refined information. We will show
                                    list elements in accordance to the feature selections that you choose.
                                </Box>
                            </Typography>
                        </Box>

                        {/*Features options*/}
                        <Box display="flex"
                             flexDirection="column"
                             width="100%"
                             border={0}
                             mt={1} px={3} pb={2}
                             bgcolor="background.paper"
                        >

                            {/* Filter Control Painel (And/Or ToggleButton)*/}
                            <Box>
                                <FilterControlLogic
                                    logicFilter={logicFilter}
                                    logicFilterOnChange={logicFilterOnChange}/>
                            </Box>

                            {/*List of filters*/}
                            <Grid container wrap="wrap" spacing={5}>
                                {filters.map(f => (

                                    <Grid key={f.filterName} item xs={12} md={4}>

                                        {/*Filter Title*/}
                                        <Typography variant="subtitle2">
                                            <Box
                                                borderBottom={1}
                                                color={theme.palette.DSS_Pallet.mainColours.BROWN}
                                                display="flex"
                                                alignItems="center"
                                                onClick={(e) => handleFiltersVisibility(f.filterName)}
                                            >
                                                {/*Filter name*/}
                                                <Box flexGrow={1}>{f.filterName.toUpperCase()}</Box>

                                                {/*Expand/collapse button*/}
                                                <Box flexShrink={1}>
                                                    <IconButton size="small"
                                                                onClick={(e) => handleFiltersVisibility(f.filterName)}>
                                                        {
                                                            filtersVisibility[f.filterName] ?
                                                                <RemoveCircleOutlinedIcon fontSize="small"/>
                                                                :
                                                                <AddCircleOutlinedIcon fontSize="small"/>
                                                        }
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                        </Typography>

                                        {/*Filter list options*/}
                                        <Collapse in={filtersVisibility[f.filterName]}>
                                            <Box mx={2}>
                                                <SwitchList
                                                    filterName={f.filterName}
                                                    sList={f.options}
                                                    filterSwitchChanges={filterSwitchChanges}
                                                    masterSwitch={f.masterSwitch}
                                                    changeAllSwitches={changeAllSwitches}
                                                />
                                            </Box>
                                        </Collapse>
                                    </Grid>
                                ))}
                            </Grid>

                        </Box>

                    </Box>
                </Grid>

                {/*Sorting Criteria*/}
                <Grid item xs={12}>

                    <Box my={1} p={0}>

                        <Typography component="div">
                            <Box color="primary.main" fontWeight="fontWeightMedium">
                                Sorting Criteria
                            </Box>
                        </Typography>
                        <Typography variant="caption">
                            <Box fontWeight="fontWeightRegular" fontStyle="oblique" lineHeight={1.2}>
                                Give some keywords describing each topic bellow.
                                RMs and LL will be sorted by relevance according to these descriptions.
                            </Box>
                        </Typography>

                        <Box display="flex" flexDirection="column" width="100%"
                             p={1} mt={1}
                             bgcolor="background.paper">

                            <Box mt={1}>
                                <TextField
                                    fullWidth size="small"
                                    variant="outlined"
                                    label="Geography"
                                    InputProps={{
                                        endAdornment: endAdornmentIcon(geographyTextTooltip()),
                                    }}
                                    onBlur={e => semanticAnalysis(e.target.value, 'geography')} //TODO: Optimize this funtion to trigger semantic evaluation just if input did change
                                    onKeyPress={
                                        (e) => { if (e.key === 'Enter') { e.target.blur() }} // Unfocus elem that triggers function
                                    }
                                />
                                <Collapse in={progressBarGeo}><LinearProgress/></Collapse>
                            </Box>

                            <Box mt={1}>
                                <TextField
                                    fullWidth size="small"
                                    variant="outlined"
                                    label="Economic Sector"
                                    InputProps={{
                                        endAdornment: endAdornmentIcon(economicSectorTextTooltip()),
                                    }}
                                    onBlur={e => semanticAnalysis(e.target.value, 'economic')} //TODO: Optimize this funtion to trigger semantic evaluation just if input did change
                                    onKeyPress={(e) => { if (e.key === 'Enter') { e.target.blur() }} // Unfocus elem that triggers function
                                    }
                                />
                                <Collapse in={progressBarGeo}>
                                    <LinearProgress/>
                                </Collapse>
                            </Box>

                            <Box mt={1}>
                                <TextField
                                    fullWidth size="small"
                                    variant="outlined"
                                    label="Barriers"
                                    InputProps={{
                                        endAdornment: endAdornmentIcon(barriersTextTooltip()),
                                    }}
                                    onBlur={e => semanticAnalysis(e.target.value, 'barriers')} //TODO: Optimize this funtion to trigger semantic evaluation just if input did change
                                    onKeyPress={(e) => { if (e.key === 'Enter') { e.target.blur() }} // Unfocus elem that triggers function
                                    }
                                />
                                <Collapse in={progressBarGeo}>
                                    <LinearProgress/>
                                </Collapse>
                            </Box>

                        </Box>

                    </Box>
                </Grid>

            </Grid>

        </React.Fragment>
    )
}
