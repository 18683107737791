import React from 'react'
import Grid from '@material-ui/core/Grid'

import RMCounter from './Analyses/RMCounter'
import RMReplicability from '../charts/RMReplicability'
import RMsizes from '../charts/RMsizes'
import RMChallenges from '../charts/RMChallenges'
import RMSias from '../charts/RMSias'
import RMAGroupAnalyze from './Analyses/RMAGroupAnalyze'
import { Box, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

function Title (props) {
    return (
        <Typography component="div" variant={'subtitle2'}>
            <Box mb={2}>
                {props.children.toUpperCase()}
            </Box>
        </Typography>
    )
}

function SubTitle (props) {
    return (
        <Typography component="div" variant={'body2'}>
            {/*<Box my={2} fontFamily="Monospace" fontWeight="fontWeightMedium">*/}
            {props.children}
            {/*</Box>*/}
        </Typography>
    )
}

export default function RMAnalyzes (props) {

    const { db, rmList } = props

    return (
        <Grid container>

            <Grid item xs={12}><Box mx="auto" width='30%' my={3}><Divider/></Box></Grid>

            {/*RMCounter*/}
            <Grid item xs={12}>
                <RMCounter rmList={rmList}/>
            </Grid>

            <Grid item xs={12}><Box mx="auto" width='30%' my={3}><Divider/></Box></Grid>

            {/*RMReplicability*/}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <RMReplicability rmList={rmList}/>
                <Box align="center" my={4}>
                    <SubTitle>
                        It shows the Replicability distribution indicator over all selected Role Models.
                    </SubTitle>
                </Box>
            </Grid>

            {/*size*/}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <RMsizes rmList={rmList}/>
                <Box align="center" my={4}>
                    <SubTitle>
                        It shows the Size distribution indicator over all selected Role Models.
                    </SubTitle>
                </Box>

            </Grid>

            {/*RMChallenges*/}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <RMChallenges rmList={rmList}/>
                <Box align="center" my={4}>
                    <SubTitle>
                        It shows the present Challenges distribution over all selected Role Models.
                    </SubTitle>
                </Box>
            </Grid>

            {/*SIAs*/}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <RMSias rmList={rmList}/>
                <Box align="center" my={4}>
                    <SubTitle>
                        It shows the Systemic Innovation Areas distribution over all selected Role Models.
                    </SubTitle>
                </Box>

            </Grid>

            <Grid item xs={12}><Box mx="auto" width='30%' my={3}><Divider/></Box></Grid>

            {/*RMA analyzes*/}
            <Grid item xs={12}>

                <Title>Role Model Actions</Title>
                <SubTitle>
                    Take a look on all Role Model Actions (RMA's) present in the list. Here you can focus on actions
                    that were performed and also explore its details and impact from the full Role Model.
                </SubTitle>
                <Box my={2}>
                    <RMAGroupAnalyze db={db} rmList={rmList}/>
                </Box>
            </Grid>

        </Grid>
    )
}