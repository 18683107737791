import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { DSSTooltip } from '../js/utils'

export default function DSSHelpIcon (props) {
    const {tooltipTitle} = props
    return (
        <DSSTooltip
            title={tooltipTitle}
            enterDelay={500} leaveDelay={200}
            arrow
        >
            <IconButton size="small">
                <HelpOutlineIcon style={{ fontSize: 18 }}/>
            </IconButton>
        </DSSTooltip>
    )
}