import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

import TableActions from './TableActions'
import TableCapitalTransferenceMechanism from './TableCapitalTransferenceMechanism'
import TableChallenges from './TableChallenges'
import TableList from './TableList'
import ProcessPanel from './ProcessPanel'
import BasePaper from './BasePaper'

import { theme } from '../theme/theme'
import TableReplicatorActions from './TableReplicatorActions'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    expansionPanel: {
        height: '100%'
    }
}))

function Title (props) {

    let { bgColor } = props
    if (typeof bgColor === 'undefined') {
        bgColor = 'secondary.main'
    }
    return (
        <Typography variant="h6">
            <Box color={bgColor} letterSpacing={2} fontSize="htmlFontSize"
                 fontWeight="fontWeightMedium">
                {props.children}
            </Box>
        </Typography>
    )
}

function CostumExpandMoreIcon (props) {
    return (<Box color={props.color}><ExpandMoreIcon/></Box>)
}


export default function ReplicatorInfo(props){

    const { db, replicator } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>

            {
                (
                    replicator
                    && Object.keys(replicator).length === 0
                    && Object.getPrototypeOf(replicator) === Object.prototype
                ) ?
                    <p></p>
                    :
                    <Grid container spacing={2}>

                        {/*Replicator info*/}
                        {/*id, description, names*/}
                        <Grid item xs={12}>
                            <BasePaper>
                                <Box pt={1}>
                                    <Typography>
                                        <strong>Description: </strong> {replicator['short_description']}
                                    </Typography>
                                    <Typography>
                                        <strong>Names: </strong> {replicator['names'].join(" | ")}
                                    </Typography>
                                    <Typography>
                                        <strong>ID: </strong> {replicator['id']}
                                    </Typography>
                                </Box>

                            </BasePaper>
                        </Grid>

                        {/*OBJECTIVES*/}
                        <Grid item xs={12} sm={12}>
                            <Accordion xs={12} defaultExpanded>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon/>}>
                                    <Title>OBJECTIVES</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={replicator['objectives']}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*CHALLENGES*/}
                        <Grid item xs={12} sm={12}>
                            <Accordion xs={12} defaultExpanded>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon/>}>
                                    <Title>CHALLENGES</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <TableList list={replicator['challenges']}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                        {/*ACTIONS*/}
                        <Grid item xs={12}>
                            <Accordion xs={12} defaultExpanded>

                                <AccordionSummary expandIcon={<CostumExpandMoreIcon/>}>
                                    <Title>ACTIONS</Title>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {/*<p>Actions Timeline Graph</p>*/}
                                    <TableReplicatorActions db={db} replicatorActions={replicator.actions}/>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>

                    </Grid>
            }

        </div>
    )
}