import React from 'react'

import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'

import FormControl from '@material-ui/core/FormControl'
import WizardQuestion from './wizardStepsUtilities/WizardQuestion'

export default function CrosscuttingStep (props) {

    const { wizardSelections, handleOnChanges } = props

    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <WizardQuestion>
                    In RURITAGE, the best practices from each Role Model have been analysed from a transversal
                    perspective using 11 cross-cutting issues. Which of them are more related to your project
                    interests?
                </WizardQuestion>
            </Grid>

            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormGroup>
                        {Object.keys(wizardSelections.crosscuttings).map(cc => {
                            return (
                                <FormControlLabel
                                    key={cc}
                                    control={
                                        <Checkbox checked={wizardSelections.crosscuttings[cc]}
                                                  onChange={handleOnChanges.crosscuttings}
                                                  name={cc}
                                                  color="primary"/>
                                    }
                                    label={<Typography variant={'overline'}>{cc}</Typography>}
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>

        </Grid>
    )
}