import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { Button } from '@material-ui/core'
import DialogInfo from '../../../DialogInfo'
import RmPanel from '../../../RmPanel'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    // indicator: {
    //     backgroundColor: '#1890ff',
    // },
})(Tabs)

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&$selected': {
            color: 'primary',
            fontWeight: theme.typography.fontWeightMedium,
            outline: 'none'
        },
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&:focus': {
            color: 'primary',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />)

export default function CtmSelectionTables (props) {

    const { db, ctmSelected } = props

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [dialogRmOpen, setDialogRmOpen] = React.useState(false)
    const [dialogRmID, setDialogRmID] = React.useState(false)

    function handleDialogRmOpen (rmId) {
        setDialogRmID(rmId)
        setDialogRmOpen(true)
    }

    function handleDialogRmClose () {
        setDialogRmOpen(false)
    }

    return (
        <Box>

            <AntTabs
                value={value}
                onChange={handleChange}

                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                style={{ borderBottom: '1px solid #e8e8e8' }}
            >
                {Object.keys(ctmSelected).map((capital, idx, arr) =>
                    (
                        <AntTab key={capital} label={capital.toUpperCase()}/>
                    )
                )}
            </AntTabs>

            <Box bgcolor="rgba(245, 245, 245, 0.6)" boxShadow={1} borderColor="secondary.main" width="100%" p={1}>
                {Object.keys(ctmSelected).map((capital, idx, arr) =>
                    <TabPanel key={capital} value={value} index={idx}>

                        <Box maxHeight={300} overflow="auto">

                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ROLE MODEL</TableCell>
                                        <TableCell>INITIAL</TableCell>
                                        <TableCell>DEVELOPED</TableCell>
                                        <TableCell>OBTAINED</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        ctmSelected[capital].map(elem => (
                                            <TableRow hover key={elem.ctm.DEVELOPED}>
                                                <TableCell width="10%">
                                                    <Button
                                                        onClick={() => handleDialogRmOpen(elem.rm)}
                                                        variant={'outlined'} size={'small'} color='primary' fullWidth>
                                                        {elem.rm['RM']}
                                                    </Button>
                                                </TableCell>
                                                <TableCell width='30%' align="left">{elem.ctm.INITIAL}</TableCell>
                                                <TableCell width='30%' align="left">{elem.ctm.DEVELOPED}</TableCell>
                                                <TableCell width='30%' align="left">{elem.ctm.OBTAINED}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            {/*Dialog for RM*/}
                            <DialogInfo dialogOpen={dialogRmOpen}
                                        title={dialogRmID['Name']}
                                        handleClose={() => handleDialogRmClose()}
                            >
                                <RmPanel db={db} rm={dialogRmID}/>
                            </DialogInfo>

                        </Box>
                    </TabPanel>
                )}
            </Box>
        </Box>
    )
}