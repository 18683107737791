export const HOSTNAME = process.env.REACT_APP_HOSTNAME
export const DATA_MANAGER_PORT = process.env.REACT_APP_DATA_MANAGER_PORT
export const FAKEDB_PORT = process.env.REACT_APP_FAKEDB_PORT

export const LINE = process.env.REACT_APP_LINE

export const POLITO_DB_API = getPolitoAPI()
function getPolitoAPI () {
    let API;
    let PATH = "/polito/getFile/"
    if(LINE === "development"){

        // API = HOSTNAME + ':' + DATA_MANAGER_PORT  + PATH
        API = HOSTNAME + ':' + FAKEDB_PORT + '/fakeData/getFakeData/' //TODO: !!! CHANGE IT TO THE PREVIOUS LINE TO NOT USE FAKE DATA !!!
        // API = "http://localhost:5015/fakeData/getFakeData/"
    } else if (LINE === "test") {
        API = HOSTNAME + 'datamanager-dev' + PATH
        // API = HOSTNAME + ':' + FAKEDB_PORT + '/fakeData/getFakeData/' //TODO: !!! CHANGE IT TO THE PREVIOUS LINE TO NOT USE FAKE DATA !!!
    } else if (LINE === "production"){
        API = HOSTNAME + 'datamanager' + PATH
    } else {
        console.log("ERROR: Unknown LINE. LINE = " + LINE)
    }
    // console.log(API)
    return API
}

export const POLITO_DRHH_API = getPolitoDrhhAPI()
function getPolitoDrhhAPI() {

    let API;

    let PATH = "/polito/getDrhhDiscussions/" //TODO: define the real path
    //PATH = "/polito/getFile/rm" // TODO: !!!!! TEST. TO BE REMOVED!!!!!
    if(LINE === "development"){
        API = HOSTNAME + ':' + DATA_MANAGER_PORT  + PATH
    } else if (LINE === "test") {
        API = HOSTNAME + 'datamanager-dev' + PATH
    } else if (LINE === "production"){
        API = HOSTNAME + 'datamanager' + PATH
    } else {
        console.log("ERROR: Unknown LINE. LINE = " + LINE)
    }
    // console.log(API)
    return API

}

export const TEXT_ANALYSIS_PORT = process.env.REACT_APP_TEXT_ANALYSIS_PORT
export const TEXT_ANALYSIS_API = getTextAnalysisAPI()
function getTextAnalysisAPI(){
    let API;
    let PATH = "/"
    if(LINE === "development"){
        API = HOSTNAME + ':' + TEXT_ANALYSIS_PORT  + PATH
    } else if (LINE === "test") {
        API = HOSTNAME + 'textanalysis-dev' + PATH
    } else if (LINE === "production"){
        API = HOSTNAME + 'textanalysis' + PATH
    } else {
        console.log("ERROR: Unknown LINE. LINE = " + LINE)
    }
    // console.log(API)
    return API
}