import React, { Component } from 'react'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'

import TableHead from '@material-ui/core/TableHead'

import DialogInfo from './DialogInfo'
import LessonLearntInfo from './LessonLearntInfo'
import DSSHelpIcon from './DSSHelpIcon'
import {
    DSSTooltipDescription,
    DSSTooltipTile,
    getLLInfo,
    CustomProgress,
    scaleScore
} from '../js/utils'

function relevanceTooltip () {
    let title = 'RELEVANCE',
        description = 'Based on your Criteria descriptions we try to find and bring to the top the most relevant ' +
            'content to you. The higher relevance the higher we think it does relate to your search.'
    return (
        <Box m={1}>
            {DSSTooltipTile(title)}
            {DSSTooltipDescription(description)}
        </Box>
    )
}

export default class LessonsLearntFilteredList extends Component {

    constructor (props) {
        super(props)

        this.state = {
            dialogOpen: false,
            dialogContent: {
                title: 'LL01' // predefined for now
            },
        }
    }

    handleClickOpen (name) {
        // Updates the states with the name of the clicked LL
        this.setState(
            {
                dialogOpen: true,
                dialogContent: {
                    title: name
                }
            })
    }

    handleClose () {
        this.setState({ dialogOpen: false })
    }

    // Returns an object with information for a single row in LessonsLearnt panel list
    // llName   - Lesson learnt name or "EMPTY" string
    // perc     - Percentage of how many displayed RM contain this lesson learnt
    createRowInfo (llName, score) {

        let { db } = this.props

        let rowInfo = {
            name: '--',
            description: '--',
            score: 0
        }

        if (llName !== 'EMPTY') {
            let llAux = getLLInfo(llName, db.ll)   // find LL
            let llDescription = llAux.found ? llAux.llInfo['LESSONS LEARNT'] : '---' // get the description

            // if (score < 0) { score = 0} else if (score > 1) {score = 1}

            rowInfo.name = llName
            rowInfo.description = llDescription
            // rowInfo.score = (score * 100.0).toFixed(2) //perc.toFixed(2)
            rowInfo.score = score //perc.toFixed(2)
        }

        return rowInfo
    }

    getLLScoreRangeFromVisibleList(ll, llDisplay){
        let max = -Infinity,
            min = Infinity
        for(let i = 0; i < ll.length; i++){
            let llName = ll[i].llName
            let llScore = ll[i].score

            // if rm is visible and rmScore is bigger than max
            if( (llDisplay[llName]) && (llScore > max) ) {
                max = llScore
            }

            // if rm is visible and rmScore is lower than min
            if( (llDisplay[llName]) && (llScore < min) ) {
                min = llScore
            }
        }
        return {min: min, max: max}
    }

    // Function to help sorting array of objects in getLLInfo()
    compare (a, b) {
        if (a.count < b.count) { return 1 }
        if (a.count > b.count) { return -1 }
        return 0
    }

    createRows (ll) {
        let allRows = []

        for (let i = 0; i < ll.length; i++) {
            // allRows.push(this.createRowInfo(ll[i].ll, 100))
            allRows.push(this.createRowInfo(ll[i].llName, ll[i].score))
        }

        return allRows
    }

    render () {

        let { llDisplay, ll, db } = this.props

        const rows = this.createRows(ll)


        // get ranges for Relevance Progress bar
        let maxScore = 1,
            minScore = 0
        if (ll.length > 0) {
            let visibleRange = this.getLLScoreRangeFromVisibleList(ll, llDisplay)
            maxScore = visibleRange.max
            minScore = visibleRange.min
        }


        return (
            <React.Fragment>

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><Box color="secondary.main" fontSize={12}>NAME</Box></TableCell>
                            <TableCell align="center"><Box color="secondary.main"
                                                           fontSize={12}>DESCRIPTION</Box></TableCell>
                            <TableCell align="center">
                                <Box display="inline" mr={1}>
                                    <DSSHelpIcon tooltipTitle={relevanceTooltip()}/>
                                </Box>
                                <Box display="inline" color="secondary.main" fontSize={12}>
                                    RELEVANCE
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (

                            <TableRow key={row.name} hidden={llDisplay[row[row.name]]}>

                                <TableCell component="th" scope="row">
                                    <Button variant="outlined" color="primary" size="small" width={''}
                                            onClick={row.name === '--' ? () => null : () => this.handleClickOpen(row.name)}>
                                        {row.name}
                                    </Button>
                                </TableCell>

                                <TableCell>
                                    <Box textAlign="left">{row.description}</Box>
                                </TableCell>

                                <TableCell align="right" width="200px">
                                    <CustomProgress
                                        // now={row.score}
                                        now={scaleScore(minScore, maxScore, row.score)}
                                        // label={scaleScore(minScore, maxScore, row.score) + '%'}
                                    />
                                </TableCell>

                            </TableRow>

                        ))}
                    </TableBody>
                </Table>

                <DialogInfo
                    dialogOpen={this.state.dialogOpen}
                    title={'Lesson learnt ' + this.state.dialogContent.title}
                    handleClose={() => this.handleClose()}
                >
                    <LessonLearntInfo llContent={this.state.dialogContent} db={db}/>
                </DialogInfo>

            </React.Fragment>
        )
    }
}
