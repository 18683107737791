import React, { Component } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import FiltersContainer from '../FiltersContainer'
import ResultsContainer from '../ResultsContainer'
import LessonsLearntFilteredList from '../LessonsLearntFilteredList'
import SummaryModuleBox from '../SummaryModuleBox'
import LLRelatedCrosscutting from '../charts/LLRelatedCrosscutting'
import LLctm from '../charts/LLctm'
import RmDrivers from '../charts/RmDrivers'
import DRHHRelatedDiscussions from '../DRHHRelatedDiscussions'

import TuneIcon from '@material-ui/icons/Tune'

import { POLITO_DB_API, TEXT_ANALYSIS_API, POLITO_DRHH_API } from '../../js/config'
import { DSSTooltipDescription, DSSTooltipTile, getLLInfo, getRmData } from '../../js/utils'
import { theme } from '../../theme/theme'

import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import IconButton from '@material-ui/core/IconButton'
import ReplicatorsList from '../ReplicatorsList'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import DSSInfo from '../DSSInfo'

//let POLITO_DB_API = HOSTNAME + ':' + DATA_MANAGER_PORT + '/polito/getFile/' // e.g.: "http://localhost:3010/polito/getFile/"
// PROD:
//     let POLITO_DB_API = 'https://ruritage.almende.com/' + 'datamanager-dev/' + 'polito/getFile/'
// TEST:
//    let POLITO_DB_API = 'https://dev.ruritage.almende.com/' + 'datamanager-dev/' + 'polito/getFile/'
// DEV:
//    let POLITO_DB_API = 'http://localhost:4010/' + '' + 'polito/getFile/' // e.g.: "http://localhost:3010/polito/getFile/"

export default class ExploreData extends Component {

    constructor (props) {
        super(props)
        // this.state = { db: require('./data/ruritage_mongodb_v5.json') }
        // var db = require('./data/ruritage_mongodb_v5.json') // get database
        // console.log(db.length)

        // Set initial values and structure of the component state
        this.state = {
            dataLoaded: false, // it turns to true when all data as been stored in this.db

            rmDisplay: {}, // Tells if RM is visible displayed or not (rm2: True)
            rmScores: {},  // Tells the scores based on Text Semantic Analysis (rm2: 0.72)

            llDisplay: {}, // Tells if LL is visible displayed or not (LL12: True)
            llScores: {},  // (Not used yet) Tells the LL scores to be have sorted lists

            replicatorsDisplay: {}, // Tells if Replicators are visible displayed or not (R1: True)
            replicatorsScores: {}, // TODO: NOT IMPLEMENTED YET

            semanticScores: {
                geography: {},
                economic: {},
                barriers: {}
            },
            progressBarGeo: false,  // While waiting for the scores a progress bar is shown

            logicFilter: 'AND', // ('AND' or 'OR') Logic to be applied to the combination of filters selection
            //sias: {},
            challenges: {},
            drivers: {},
            heritages: {},
            masterSwitches: {
                // sias: false,
                challenges: false,
                drivers: false,
                heritages: false
            },

            // filters: {
            //     logicFilter: 'AND', // 'AND' or 'OR'
            //     //sias: {},
            //     sections: {
            //         challenges: {
            //             masterSwitch: false,
            //             list: {}
            //         },
            //         drivers: {
            //             masterSwitch: false,
            //             list: {}
            //         },
            //     }
            // },

            drhhInfo: {} // Accordantly to the filter selections this object contains the Discussions data in the DRHH
        }

        // this.db = props.db
        // console.log('db:', this.db)
        this.db = {
            rm: [],
            rma: [],
            ll: [],
            rm_ll: {},
            ll_rm: {},
            rmas: [],
            stakeholders: [],
            replicators: []
        }
    }

    preprocessRmData (allRM) {

        for (let i = 0; i < allRM.length; i++) {
            allRM[i]['RM'] = allRM[i]['RM'].toUpperCase() // turn to uppercase all RM ids
        }

        return allRM
    }

    componentDidMount () {

        // Fetch data
        const p_rm = fetch(POLITO_DB_API + 'rm')
        const p_rma = fetch(POLITO_DB_API + 'rma')
        const p_rm_ll = fetch(POLITO_DB_API + 'rm_ll')
        const p_ll = fetch(POLITO_DB_API + 'll')
        const p_rmas = fetch(POLITO_DB_API + 'rmas')
        const p_stakeholders = fetch(POLITO_DB_API + 'stakeholders')
        const p_replicators = fetch(POLITO_DB_API + 'replicators')

        // Get all the fetched data promises
        Promise.all([
                p_rm.catch(error => console.log('ERROR: ' + error + '. rm data not Fetched!') ),
                p_rma.catch(error => console.log('ERROR: ' + error + '. rma data not Fetched!') ),
                p_rm_ll.catch(error => console.log('ERROR: ' + error + '. rm_ll data not Fetched!') ),
                p_ll.catch(error => console.log('ERROR: ' + error + '. ll data not Fetched!') ),
                p_rmas.catch(error => console.log('ERROR: ' + error + '. rmas data not Fetched!') ),
                p_stakeholders.catch(error => console.log('ERROR: ' + error + '. stakholders data not Fetched!') ),
                p_replicators.catch(error => console.log('ERROR: ' + error + '. replicators data not Fetched!') )
            ])
            .then(function (responses) {
                // Get a JSON object from each of the responses
                return Promise.all(responses.map( response => response.json() ));
            })
            .then(data => {

                // Get all data in variables
                let rm          = data[0]
                let rma         = data[1]
                let rm_ll       = data[2]
                let ll          = data[3]
                let rmas        = data[4]
                let stakholders = data[5]
                let replicators = data[6]

                // Store all data in db
                this.db = {
                    ...this.db,
                    rm: this.preprocessRmData(rm),
                    rma: rma,
                    rm_ll: rm_ll,
                    ll: ll,
                    ll_rm: this.getAllRmFromLL(ll),
                    rmas: rmas,
                    stakeholders: stakholders,
                    replicators: replicators
                }

            })
            .then( () => {

                // Update this.state with the new data

                // Set sia filter states
                // let siaFilterStates = {}
                // let siasArr = this.getSIAsArr()
                // for (let i = 0; i < siasArr.length; i++) {
                //     siaFilterStates[siasArr[i]] = false
                // }

                // Set Challenges filter states
                let challengesFilterStates = {}
                let challengesArr = this.getChallengesArr()
                for (let i = 0; i < challengesArr.length; i++) {
                    challengesFilterStates[challengesArr[i]] = false
                }

                // Set Drivers filter states
                let driversFilterStates = {}
                let driversArr = this.getDriversArr()
                for (let i = 0; i < driversArr.length; i++) {
                    driversFilterStates[driversArr[i]] = false
                }

                // Set Heritages filter states
                let heritagesFilterStates = {}
                let heritagesArr = this.getHeritagesArr()
                for (let i = 0; i < heritagesArr.length; i++) {
                    heritagesFilterStates[heritagesArr[i]] = false
                }

                // Update state
                this.setState({
                    // ...this.state,
                    dataLoaded: true, // Update state to indicate the data got loaded and we can render the page

                    // --- FROM RM ---
                    rmDisplay: this.getRmDisplay(),
                    rmScores: this.initializeRmScores(),
                    semanticScores: {   // keep the current semantic scores and initialize the ones for RMs
                        ...this.state.semanticScores,
                        geography: this.initializeRmScores(),
                        economic: this.initializeRmScores(),
                        barriers: this.initializeRmScores()
                    },

                    challenges: challengesFilterStates,
                    drivers: driversFilterStates,
                    heritages: heritagesFilterStates,
                    // sias: siaFilterStates,

                    // --- FROM LL ---
                    llDisplay: this.getLLDisplay(),
                    llScores: this.initializeLLScores(),

                    // --- FROM Replicators ---
                    replicatorsDisplay: this.getReplicatorsDisplay()
                })

            }, this.refreshResults)
            .catch(error => {
                // ...handle/report error...
                console.log('ERROR: ' + error + '. Promise.all() error!')
            });

    }

    getRmDisplay (props) {

        //var rm = this.props.db
        var rm = this.db.rm // get RM list

        // Create object state to show or hide RM displayed in results section
        let rmDisplay = {}
        for (let i = 0; i < rm.length; i++) {
            rmDisplay[rm[i].RM] = true // set all visible
        }

        return rmDisplay
    }

    getLLDisplay () {

        var ll = this.db.ll // get RM list

        // Create object state to show or hide LL displayed in results section
        let llDisplay = {}
        for (let i = 0; i < ll.length; i++) {
            llDisplay[ll[i]['LL CODE']] = true // set all visible
        }

        return llDisplay
    }

    getReplicatorsDisplay () {

        let replicators = this.db.replicators // get RM list

        // Create object state to show or hide LL displayed in results section
        let replicatorsDisplay = {}
        for (let i = 0; i < replicators.length; i++) {
            replicatorsDisplay[replicators[i].id] = true // set all visible
        }

        return replicatorsDisplay
    }

    initializeRmScores () {
        //var rm = this.props.db
        var rm = this.db.rm // get RM list

        // Create object state to show or hide RM displayed in results section
        let rmScores = {}
        for (let i = 0; i < rm.length; i++) {
            rmScores[rm[i].RM] = 0.0
        }
        return rmScores
    }

    initializeLLScores () {
        //var rm = this.props.db
        var ll = this.db.ll // get RM list

        // Create object state to show or hide RM displayed in results section
        let llScores = {}
        for (let i = 0; i < ll.length; i++) {
            llScores[ll[i]['LL CODE']] = 0.0 // initialize
        }
        return llScores
    }

    //Get sia Name from RM
    getSiaName (rm) {
        return rm['RELATED SIA'] + ': ' + rm['SIA NAME']
    }

    // Looks in a object for the CHALLENGE key and returns it as a 'clean string'.
    // This clean string is meant to be used as reference for states
    getChallenge (obj) {
        return this.cleanStringToOptionsList(obj['CHALLENGE']) // clean element
    }

    // Returns a 'clean string' driver.
    // This clean string is meant to be used as reference for states
    getDriver (driver) {
        return this.cleanStringToOptionsList(driver) // clean element
    }

    getHeritage (heritage) {
        return this.cleanStringToOptionsList(heritage) // clean element
    }

    // Returns an array of all options available for SIAs
    getSIAsArr () {
        // Find and create an array with all possible options
        let optionsArr = [] // array of options to send back
        for (let i = 0; i < this.db.rm.length; i++) {   // run through all database elements
            let opt = this.getSiaName(this.db.rm[i]) // create option
            if (!optionsArr.includes(opt)) { // check if option already exist in array
                optionsArr.push(opt)
            }
        }
        optionsArr.sort() // sort array
        return optionsArr
    }

    // Returns an object with all KeyResources in rma plus the number of times they show up in all RMs
    getAllKeyResources () {
        let allKeyResources = {}
        for (let i = 0; i < this.db.rma.length; i++) {
            let elem = this.db.rma[i]
            let elemArr = elem['Practices']['KEY RESOURCES']

            for (let k = 0; k < elemArr.length; k++) {
                let rsc = elemArr[k]

                if (!(rsc in allKeyResources)) {
                    allKeyResources[rsc] = 1
                } else {
                    allKeyResources[rsc] += 1
                }
            }
        }

        return allKeyResources
    }

    // Returns an array of all options available for Challenges
    getChallengesArr () {
        // Find and create an array with all possible options
        let optionsArr = [] // array of options to send back

        // Get list from the RM elements
        for (let i = 0; i < this.db.rm.length; i++) {   // run through all RM elements
            let array = this.db.rm[i]['Practices']['CHALLENGES']  // get array of challenges
            for (let k = 0; k < array.length; k++) {  // for all elements in that array
                let opt = this.getChallenge(array[k]) // Take challenge element
                // If element is not in the list then add it
                if (!optionsArr.includes(opt)) {
                    optionsArr.push(opt)
                }
            }
        }

        // Get list from Replicators elements
        for (let i = 0; i < this.db.replicators.length; i++){
            let array = this.db.replicators[i]['challenges']
            for (let k = 0; k < array.length; k++) {  // for all elements in that array
                let opt = this.cleanStringToOptionsList(array[k]) // Take challenge element
                // If element is not in the list then add it
                if (!optionsArr.includes(opt)) {
                    optionsArr.push(opt)
                }
            }
        }

        return optionsArr.sort() // sort array
    }

    // Returns an array of all options available for Drivers
    getDriversArr () {
        // Find and create an array with all possible options
        let optionsArr = [] // array of options to send back
        for (let i = 0; i < this.db.rm.length; i++) {   // run through all database elements
            let array = this.db.rm[i]['Practices']['DRIVERS']  // get array
            for (let k = 0; k < array.length; k++) {  // for all elements in that array
                // let opt = array[k] // Take new element
                // opt = this.cleanStringToOptionsList(opt) // clean element
                let opt = this.getDriver(array[k]) // Take new clean element
                // If element is not in the list then add it
                if (!optionsArr.includes(opt)) {
                    optionsArr.push(opt)
                }
            }
        }
        return optionsArr.sort() // sort array
    }

    // Returns an array of all options available for Heritages
    getHeritagesArr () {

        let optionsArr = []

        // From RM data
        for (let i = 0; i < this.db.rm.length; i++) {   // run through all database elements
            let array = this.db.rm[i]['Practices']['RELEVANT HERITAGE']  // get array
            for (let k = 0; k < array.length; k++) {  // for all elements in that array
                let opt = this.cleanStringToOptionsList(array[k]) // Take new clean element
                // If element is not in the list then add it
                if (!optionsArr.includes(opt)) {
                    optionsArr.push(opt)
                }
            }
        }

        // From Replicators data
        for (let i = 0; i < this.db.replicators.length; i++) {   // run through all database elements
            let replicatorActionsArray = this.db.replicators[i]['actions'] //['relevant_heritage']  // get array

            for( let n = 0; n < replicatorActionsArray.length; n++){ // Go through the list of all replicator actions
                let array = replicatorActionsArray[n]["relevant_heritage"] // Take heritages array from action

                for (let k = 0; k < array.length; k++) {  // for all elements in that array
                    let opt = this.cleanStringToOptionsList(array[k]) // Take new clean element
                    // If element is not in the list then add it
                    if (!optionsArr.includes(opt)) {
                        optionsArr.push(opt)
                    }
                }
            }
        }

        return optionsArr.sort()
    }

    // Returns an object with states of SIAs filter
    // E.g.: { SIA1 : False, SIA2 : False, ...}
    getSIAsStateOptions = () => {
        // Get an array with all possible options
        let optionsArr = this.getSIAsArr()

        // Then create an object to hold the status for each options
        let SIAStatusObj = {}
        for (let i = 0; i < optionsArr.length; i++) {
            SIAStatusObj[optionsArr[i]] = false
        }
        return SIAStatusObj
    }

    // Returns an object with states of Challenges filter
    // { challenge1 : False,  challenge2 : False}
    getChallengesStateOptions = () => {

        // Get an array with all possible options
        let optionsArr = this.getChallengesArr()

        // Then create an object to hold the status for each options
        let challengesStatusArr = {}
        for (let i = 0; i < optionsArr.length; i++) {
            challengesStatusArr[optionsArr[i]] = false
        }

        return challengesStatusArr
    }

    // Returns an object with states of Drivers filter
    //{ driver1 : False, driver2 : False}
    getDriversStateOptions = () => {
        // Get an array with all possible options
        let optionsArr = this.getDriversArr()

        // Then create an object to hold the status for each options
        let driversStatusArr = {}
        for (let i = 0; i < optionsArr.length; i++) {
            driversStatusArr[optionsArr[i]] = false
        }

        return driversStatusArr // return array with options
    }

    // String will be formatted to be displayed on the lists of filters
    cleanStringToOptionsList = (str) => {
        // Remove spaces at the edges and replace multiple spaces just for a single one
        let strFormat = str.trim().replace(/ {1,}/g, ' ').toLowerCase()
        strFormat = strFormat.charAt(0).toUpperCase() + strFormat.slice(1)
        return strFormat
    }

    // Updates the states of each filter switch when toggled and then shows or hides RMs regarding all filter states
    filterSwitchChanges = (filterName, label) => event => {
        //this.setState({ [name]: event.target.checked })

        // set the new state to the switch
        let newState = { ...this.state[filterName] }
        newState[label] = event.target.checked
        this.setState({ [filterName]: newState }, this.refreshResults)

    }

    // Called on logic filter button click. Switch filter logic between And and OR
    changeLogicFilter = () => () => {
        const newLogic = this.state.logicFilter === 'AND' ? 'OR' : 'AND'
        this.setState({ logicFilter: newLogic }, this.refreshResults)
    }

    changeAllSwitches = filterName => event => {
        // console.log('name = ' + filterName + ' event = ' + event.target.checked)

        // change master switch
        let masters = { ...this.state.masterSwitches }
        masters[filterName] = event.target.checked

        // changes all switches from this filter
        let masterSwitch = event.target.checked
        let switches = { ...this.state[filterName] }
        for (let s in switches) {
            switches[s] = masterSwitch
        }

        // update state and then refresh results
        this.setState(
            {
                [filterName]: switches,
                masterSwitches: masters
            },
            this.refreshResults
        )
    }

    // Returns a list of the current displayed RM
    getListOfDisplayedRms () {
        let listDisplayedRM = []
        let rmDisplay = { ...this.state.rmDisplay }
        Object.keys(rmDisplay).forEach(function (key) {
            if (rmDisplay[key]) {
                listDisplayedRM.push(key)
            }
        })
        return listDisplayedRM
    }

    // Returns a list of the current displayed LL
    getListOfDisplayedLL () {
        let listDisplayedLL = []
        let llDisplay = { ...this.state.llDisplay }
        Object.keys(llDisplay).forEach(function (key) {
            if (llDisplay[key]) {
                listDisplayedLL.push(key)
            }
        })
        return listDisplayedLL
    }

    getSelectionData_relatedCrosscutting () {

        let selection = {
            labels: [],
            data: []
        }

        // get list of displayed LL
        let llList = this.getListOfDisplayedLL()

        // Create a counter for each crosscutting category
        let crosscuttingCounter = {}
        // let driversList = this.getDriversArr()
        // driversList.forEach((d) => {
        //     crosscuttingCounter[d] = 0
        // })

        // For each displayed LL count the crosscutting

        for (let i = 0; i < llList.length; i++) {
            let llName = llList[i]

            let llAux = getLLInfo(llName, this.db.ll)

            if (llAux.found) {
                let ll = llAux.llInfo

                // concat ot crosscuting lists
                let llCrosscuttingList = (ll['MAIN RELATED CROSSCUTTING']).concat(ll['OTHER RELATED CROSSCUTTING'])

                // clean sentences
                llCrosscuttingList.forEach((v, i, a) => {
                    a[i] = v.trim() //.toUpperCase()
                })

                // console.log(llCrosscuttingList)

                for (let k = 0; k < llCrosscuttingList.length; k++) {
                    let category = llCrosscuttingList[k]

                    if (category in crosscuttingCounter) {
                        crosscuttingCounter[category] += 1 // increment counter category
                    } else {
                        crosscuttingCounter[category] = 1  // initialize counter category
                    }

                }

            }

        }

        // console.log(crosscuttingCounter)

        // Transform counter to the required selection object
        let labels = Object.keys(crosscuttingCounter).sort()
        for (let i = 0; i < labels.length; i++) {
            selection.labels.push(labels[i])
            selection.data.push(crosscuttingCounter[labels[i]])
        }

        return selection
    }

    // This function returns a object containg the drivers categories and its amount presented in the current displayed RMs
    // Used for Drivers Summary Graph Panel
    getSelectionData_drivers () {
        // Object selection to be returned
        let selection = {
            labels: [],
            data: []
        }

        // get a list of RM's that are currently displayed
        let rmList = this.getListOfDisplayedRms()

        // Create a counter for each Driver category
        let driversCounter = {}
        let driversList = this.getDriversArr()
        driversList.forEach((d) => {
            driversCounter[d] = 0
        })

        // For each displayed RM count the drivers
        for (let i = 0; i < rmList.length; i++) {
            let rmName = rmList[i] // get RM name

            // get RM data
            // let rm_aux = this.getRmData(rmName)
            let rm_aux = getRmData(rmName, this.db.rm)
            let rm = {}
            if (rm_aux.found) {
                rm = rm_aux.data
                let rmDrivers = rm['Practices']['DRIVERS'] // get rm drivers list
                for (let k = 0; k < rmDrivers.length; k++) { // for each rm driver
                    let driver = this.getDriver(rmDrivers[k])  // clean it
                    driversCounter[driver] += 1  // and increment the counter
                }
            } else {
                console.log('Warning: RM not found in database!')
                break
            }
        }

        // Transform counter to the required selection object
        for (let i = 0; i < driversList.length; i++) {
            selection.labels.push(driversList[i])
            selection.data.push(driversCounter[driversList[i]])
        }

        // selection = {
        //     labels: [
        //         'Innovation in culture and heritage',
        //         'Environmental impact reduction',
        //         'Cultural heritage preservation',
        //         'Tourism',
        //         'Job creation',
        //         'Natural heritage preservation',
        //         'Making high quality arts available to rural communities'
        //     ],
        //     data: [44, 55, 5, 23, 20, 30, 70],
        //     clickEvent: () => console.log('ClickEvent Passed on data_drivers')
        //
        // }
        return selection
    }

    getSelectionData_CTM () {

        // get list of displayed LL
        let llList = this.getListOfDisplayedLL()

        // Create counters for each category
        let capitalInit = {
            CULTURAL: 0,
            NATURAL: 0,
            HUMAN: 0,
            SOCIAL: 0,
            FINANCIAL: 0,
            BUILT: 0
        }
        let capitalDev = {
            CULTURAL: 0,
            NATURAL: 0,
            HUMAN: 0,
            SOCIAL: 0,
            FINANCIAL: 0,
            BUILT: 0
        }
        let capitalObt = {
            CULTURAL: 0,
            NATURAL: 0,
            HUMAN: 0,
            SOCIAL: 0,
            FINANCIAL: 0,
            BUILT: 0
        }

        // Create list of CTM to be passed to dialog tables
        let listsCTM = {
            CULTURAL: [],
            NATURAL: [],
            HUMAN: [],
            SOCIAL: [],
            FINANCIAL: [],
            BUILT: []
        }

        // Loop through all displayed LL and increase the counter for any developed CTM category
        for (let i = 0; i < llList.length; i++) {
            let llName = llList[i]

            let llAux = getLLInfo(llName, this.db.ll)
            let ll = llAux.found ? llAux.llInfo : {}

            if (Object.keys(ll).length > 0) {
                if (typeof ll['CAPITAL TRANSFERENCE MECHANISM']['List'] !== 'undefined') {
                    let ctmList = ll['CAPITAL TRANSFERENCE MECHANISM']['List']

                    // Go through ctm list of this LL
                    for (let k = 0; k < ctmList.length; k++) {

                        let ctmElem = ctmList[k] // get CTM element data

                        if (typeof ctmElem['RELATED CAPITAL'] !== 'undefined') {
                            let capital = ctmElem['RELATED CAPITAL'].trim().toUpperCase() // get Capital Category

                            let initText = ctmElem['INITIAL'].trim()
                            let devText = ctmElem['DEVELOPED'].trim()
                            let obtText = ctmElem['OBTAINED'].trim()

                            // Count capitals
                            if (initText) {
                                capitalInit[capital] += 1
                            }

                            if (devText) {
                                capitalDev[capital] += 1
                            }

                            if (obtText) {
                                capitalObt[capital] += 1
                            }

                            // Collect info for dialog tabels
                            listsCTM[capital].push(
                                {
                                    llName: llName,
                                    initial: initText,
                                    developed: devText,
                                    obtained: obtText
                                }
                            )

                        }

                    }
                }

            }
        }

        // Transform the counter in arrays
        let labels = Object.keys(capitalDev).sort()

        let dataCapitalsInit = []
        let dataCapitalsDev = []
        let dataCapitalsObt = []
        for (let i = 0; i < labels.length; i++) {
            dataCapitalsInit.push(capitalInit[labels[i]])
            dataCapitalsDev.push(capitalDev[labels[i]])
            dataCapitalsObt.push(capitalObt[labels[i]])
        }

        let data = [
            {
                name: 'Initial',
                data: dataCapitalsInit
            },
            {
                name: 'Developed',
                data: dataCapitalsDev
            },
            {
                name: 'Obtained',
                data: dataCapitalsObt
            },
        ]

        let ctm = {
            labels: labels,
            data: data,
            listsCTM: listsCTM,
            clickEvent: () => () => console.log('ClickEvent Passed CTM')
        }

        // console.log(ctm)

        return ctm
    }

    // This function requests geographic semantic analyses to textAnalysis module and reorganize the RMs list according
    // to the given scores
    semanticAnalysis = (stringInput, criteria) => {

        // all possible criteria available
        let criteriaOpt = {
            geography: 'geography',
            economic: 'economic',
            barriers: 'barriers'
        }

        // if criteria does not exist return
        if (!(criteria in criteriaOpt)) {
            console.log('Warning! Criteria \'' + criteria + '\' unknown!')
            return
        }

        // if string is empty then reset state on criteria
        if (stringInput === '') {

            let semanticScores = { ...this.state.semanticScores }
            semanticScores[criteria] = this.initializeRmScores()
            this.setState(
                {
                    ...this.state,
                    semanticScores
                }
            )

        } else { // If input is not empty

            // Activate progress bar
            this.setState({ ...this.state, progressBarGeo: true })

            // Create object to hold arrays of the data to be analysed and insert the input as the reference to be analysed
            let rmScores_semantic = {
                rmNames: ['input'],
                rmTexts: [stringInput]
            }

            // get the rm fields to be analysed
            let rmList = this.db.rm
            for (let i = 0; i < rmList.length; i++) {
                let rm = rmList[i] // get rm
                let rmName = rm['RM'] // get rm Name
                rmScores_semantic.rmNames.push(rmName)                          // insert rmName in array

                if (criteria === criteriaOpt.geography) {

                    if (typeof rm['Context']['Geography'] !== 'undefined') {        // If field exist in RM
                        rmScores_semantic.rmTexts.push(rm['Context']['Geography'])  // push field to be analysed
                    } else {
                        rmScores_semantic.rmTexts.push('')                            // Push and empty string
                        console.log('Warning: RM ' + rm + ' does not have \'Geography\' description!')
                    }

                } else if (criteria === criteriaOpt.economic) {

                    if (typeof rm['Context']['MainEconomicSector'] !== 'undefined') {        // If field exist in RM
                        rmScores_semantic.rmTexts.push(rm['Context']['MainEconomicSector'])  // push field to be analysed
                    } else {
                        rmScores_semantic.rmTexts.push('')                            // Push and empty string
                        console.log('Warning: RM ' + rm + ' does not have \'MainEconomicSector\' description!')
                    }

                } else if (criteria === criteriaOpt.barriers) {

                    if (typeof rm['Practices']['BARRIERS'] !== 'undefined') {        // If field exist in RM
                        rmScores_semantic.rmTexts.push(rm['Practices']['BARRIERS'].join('. '))  // join strings and push field to be analysed
                    } else {
                        rmScores_semantic.rmTexts.push('')                            // Push and empty string
                        console.log('Warning: RM ' + rm + ' does not have \'BARRIERS\' description!')
                    }

                } else {
                    console.log('WARNING! Criteria unkown: ' + criteria)
                }

            }

            // Data to be analysed
            let data = {
                sentences: rmScores_semantic.rmTexts,
                sentencesID: rmScores_semantic.rmNames
            }

            let options = {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }

            fetch(TEXT_ANALYSIS_API, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(criteria)

                        // get scores array
                        let scores = result.scores

                        // get rmScores
                        //let rmScores = { ...this.state.rmScores }

                        let semanticScores = { ...this.state.semanticScores }

                        // Translate result to rmScores
                        for (let i = 1; i < scores.length; i++) { // Skip the 1st position since it is the user input
                            let scoreElem = scores[i]
                            let score = scoreElem[0]
                            // let sentence = scoreElem[1]
                            let sentName = scoreElem[2]

                            // Replace scores
                            semanticScores[criteriaOpt[criteria]][sentName] = score
                        }

                        // Update state
                        this.setState({
                            ...this.state,
                            progressBarGeo: false, // hide progress bar
                            //rmScores,
                            semanticScores
                        })

                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log('ERROR: ' + error + '. Similarities not Fetched!')

                        // Update state
                        this.setState({
                            ...this.state,
                            progressBarGeo: false // hide progress bar
                        })
                    }
                )
        }
    }

    updateDiscussionLinks () {

        // TODO: After putting all filters in a single object in state, make a loop here to get the text from all of them. This is to ugly.
        // get arrays of all filters
        let challengesArr = this.getChallengesArr()
        let driversArr = this.getDriversArr()

        let discussionsRequest = [] // array to hold all active filters text

        for (let i = 0; i < challengesArr.length; i++) {
            let f = challengesArr[i]
            if (this.state.challenges[f] === true) {
                discussionsRequest.push(f)
            }
        }

        for (let i = 0; i < driversArr.length; i++) {
            let f = driversArr[i]
            if (this.state.drivers[f] === true) {
                discussionsRequest.push(f)
            }
        }

        // if all inputs are stored in our object
        // then display them, do not request more data
        // else
        // request the missed inputs
        // update discussions

        let args = {
            method: 'POST',
            body: JSON.stringify({
                sentences: discussionsRequest,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        // get filters data
        fetch(POLITO_DRHH_API, args)
            .then(res => res.json())
            .then(
                (result) => {

                    // console.log("RECEIVED:", result)

                    // let drhhTest = {
                    //     combined: [
                    //         {
                    //             link: '/link/to/d4',
                    //             text: 'd11',
                    //             semVal: 0.94
                    //         },
                    //         {
                    //             link: '/link/to/d0',
                    //             text: 'd00',
                    //             semVal: 0.5
                    //         },
                    //         {
                    //             link: '/link/to/d33',
                    //             text: 'd33',
                    //             semVal: 0.5
                    //         }
                    //     ],
                    //     poverty: [
                    //         {
                    //             link: '/link/to/d1',
                    //             text: 'd1',
                    //             semVal: 0.9
                    //         },
                    //         {
                    //             link: '/link/to/d0',
                    //             text: 'd0',
                    //             semVal: 0.8
                    //         }
                    //     ],
                    //     unemployment: [
                    //         {
                    //             link: '/link/to/d2',
                    //             text: 'd2',
                    //             semVal: 0.7
                    //         },
                    //         {
                    //             link: '/link/to/d3',
                    //             text: 'd3',
                    //             semVal: 0.6
                    //         }
                    //     ]
                    // }
                    // result = drhhTest
                    // Update state
                    this.setState({ ...this.state, drhhInfo: result })

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log('ERROR: ' + error + '. DRHH Discussions data not Fetched!')
                }
            )
    }

    // This function sets visibility state of RMs and LL
    refreshResults () {

        // Clone states
        let rmDisplay = { ...this.state.rmDisplay }
        let llDisplay = { ...this.state.llDisplay }
        let replicatorsDisplay = { ...this.state.replicatorsDisplay }

        // Perform calculations on RM and LL states
        rmDisplay = this.setRMVisivility(rmDisplay)
        llDisplay = this.setLLVisibility(llDisplay, rmDisplay) // Because for now LL visibility is depended of RM visibility we pass the new rmDisplay
        replicatorsDisplay = this.setReplicatorsVisibility(replicatorsDisplay)

        // Update all
        this.setState(
            {
                ...this.state,
                rmDisplay: rmDisplay,
                llDisplay: llDisplay,
                replicatorsDisplay: replicatorsDisplay
            })

        // Update the list of discussions happening in the DRHH
        this.updateDiscussionLinks()
    }

    setRMVisivility (rmDisplay) {

        // For each RM in database
        for (let i = 0; i < this.db.rm.length; i++) {

            let rm = this.db.rm[i]
            if (this.state.logicFilter === 'OR') {  // if Logic filter is OR

                // Check SIAs filter
                // let sia = this.getSiaName(rm)
                // let fSIA = this.state.sias[sia]

                // Check Challenges filter
                // On Rm challenges array
                let fChallenges = false
                for (let k = 0; k < rm.Practices.CHALLENGES.length; k++) {
                    let challenge = this.getChallenge(rm.Practices.CHALLENGES[k])
                    fChallenges = fChallenges || this.state.challenges[challenge]
                }
                // On rma_enhancements
                if("rma_enhancements" in rm) {
                    for (let k = 0; k < rm.rma_enhancements.challenges.length; k++) {
                        let challenge = this.cleanStringToOptionsList(rm.rma_enhancements.challenges[k])
                        fChallenges = fChallenges || this.state.challenges[challenge]
                    }
                }

                // Check Drivers filter
                let fDrivers = false
                for (let k = 0; k < rm.Practices.DRIVERS.length; k++) {
                    let drive = this.getDriver(rm.Practices.DRIVERS[k])
                    fDrivers = fDrivers || this.state.drivers[drive]
                }

                // Check Heritages filter
                let fHeritages = false
                for (let k = 0; k < rm.Practices["RELEVANT HERITAGE"].length; k++) {
                    let heritage = this.getHeritage(rm.Practices["RELEVANT HERITAGE"][k])
                    fHeritages = fHeritages || this.state.heritages[heritage]
                }

                // Make OR logic
                let showRM = fChallenges || fDrivers || fHeritages
                rmDisplay[rm.RM] = showRM // set state to this RM

            }
            else if (this.state.logicFilter === 'AND') {

                // Check SIAs filter
                // let sia = this.getSiaName(rm)
                // let fSIA = this.state.sias[sia]

                // Get all active sias
                // let fSIA = true
                // let rmSia = this.getSiaName(rm)
                // for (let sia in this.state.sias) {
                //     if (this.state.sias[sia] === true) { // If switch is On
                //         // Check if rm has this sia
                //         fSIA = fSIA && (rmSia === sia)
                //     }
                // }

                // Check Challenges filter
                let fChallenges = true
                for (let stateChallenge in this.state.challenges) { // run through all state challenges
                    if (this.state.challenges[stateChallenge] === true) { // If state challenge is active

                        // Check if rm has this challenge
                        let challengeExist = false
                        // On Rm challenges array
                        for (let k = 0; k < rm.Practices.CHALLENGES.length; k++) {
                            let rmChallenge = this.getChallenge(rm.Practices.CHALLENGES[k])
                            if (rmChallenge === stateChallenge) { // if challenge exit turn flag true
                                challengeExist = true
                            }
                        }

                        // On rma_enhancements
                        if("rma_enhancements" in rm){
                            for (let k = 0; k < rm.rma_enhancements.challenges.length; k++) {
                                let rmaChallenge = this.cleanStringToOptionsList(rm.rma_enhancements.challenges[k])
                                if (rmaChallenge === stateChallenge) { // if challenge exit turn flag true
                                    challengeExist = true
                                }
                            }
                        }

                        // Apply logic
                        fChallenges = fChallenges && challengeExist // AND logic
                    }
                }

                // Check Drivers filter
                let fDrivers = true
                for (let stateDriver in this.state.drivers) { // run through all state drivers
                    if (this.state.drivers[stateDriver] === true) { // If state driver is active
                        // Check if rm has this driver
                        let driverExist = false
                        for (let k = 0; k < rm.Practices.DRIVERS.length; k++) {
                            let rmDriver = this.getDriver(rm.Practices.DRIVERS[k])
                            if (rmDriver === stateDriver) { // if driver exit turn flag true
                                driverExist = true
                            }
                        }
                        fDrivers = fDrivers && driverExist // AND logic
                    }
                }

                // Check Heritage filter
                let fHeritages = true
                for (let stateHeritage in this.state.heritages) { // run through all states
                    if (this.state.heritages[stateHeritage] === true) { // If state driver is active
                        // Check if rm has this driver
                        let heritageExist = false
                        for (let k = 0; k < rm.Practices['RELEVANT HERITAGE'].length; k++) {
                            let rmHeritage = this.getHeritage(rm.Practices['RELEVANT HERITAGE'][k])
                            if (rmHeritage === stateHeritage) { // if heritage exit turn flag true
                                heritageExist = true
                            }
                        }
                        fHeritages = fHeritages && heritageExist // AND logic
                    }
                }

                // Make AND logic
                let showRM = fChallenges && fDrivers && fHeritages
                rmDisplay[rm.RM] = showRM // set state to this RM

            }
            else {
                console.log('ERROR!! Unkown logicFilter value: ', this.state.logicFilter)
            }

        }
        //console.log(rmDisplay)

        return rmDisplay
    }

    // For now LL visibility is related to RM visibility
    setLLVisibility (llDisplay, rmDisplay) {

        // Get all displayed RM names
        let visibleRM = []
        for (let rm in rmDisplay) {
            if (rmDisplay[rm]) { // if RM is visible
                visibleRM.push(rm)
            }
        }

        // Get all unique LL from the displayed RM
        let allLessonsLearnt = []
        for (let i = 0; i < visibleRM.length; i++) { // Go through all visible RM
            let rmName = visibleRM[i].toUpperCase()

            // if rmName exist already in the state then concat the info
            if (this.db.rm_ll.hasOwnProperty(rmName)) {
                allLessonsLearnt = allLessonsLearnt.concat(this.db.rm_ll[rmName])
            } else {
                console.log('rmName does not exist in state!')
            }
        }

        // get a list of unique LL
        let uniqueLL = allLessonsLearnt.filter((v, i, a) => a.indexOf(v) === i) // array of unique LL
        // console.log(uniqueLL.sort())

        // reset llDisplay
        for (let ll in llDisplay) {
            llDisplay[ll] = false
        }

        // Set visibility to LL
        for (let i = 0; i < uniqueLL.length; i++) {
            if (uniqueLL[i] in llDisplay) {
                llDisplay[uniqueLL[i]] = true
            }
        }

        return llDisplay
    }

    setReplicatorsVisibility(replicatorsDisplay){

        // For each RM in database
        for (let i = 0; i < this.db.replicators.length; i++) {

            let replicator = this.db.replicators[i] // get replicator data

            if (this.state.logicFilter === 'OR') {  // if Logic filter is OR

                // Check Challenges
                let fChallenges = false
                for (let k = 0; k < replicator.challenges.length; k++) {
                    let challenge = this.cleanStringToOptionsList(replicator.challenges[k])
                    fChallenges = fChallenges || this.state.challenges[challenge]
                }

                // Check Heritage filter
                let fHeritages = false // Indicates if this replicator should be visible according to heritages replicator data
                let heritagesSwitches = Object.keys(this.state.heritages)   // Get all switches names from states
                for(let s = 0; s < heritagesSwitches.length; s++){          // Go through all switches
                    let heritageSwitchName  = heritagesSwitches[s]                      // Get heritage Switch Name
                    let heritageSwitchState = this.state.heritages[heritageSwitchName]  // Get Heritage Switch State

                    // If switch state is active
                    if (heritageSwitchState === true){

                        // Go through all replicator actions, if one of the actions contains the heritage type then
                        // activate the filter
                        let heritageExist = false
                        for (let action of replicator.actions) {
                            let actionHeritages = action.relevant_heritage.map(h=>h.toLowerCase()) // Get action heritage list

                            // If action list contains the selected switch heritage
                            if (actionHeritages.includes(heritageSwitchName.toLowerCase())) {
                                heritageExist = true // heritage exist
                            }
                        }

                        // apply OR logic for heritages switches
                        fHeritages = fHeritages || heritageExist
                    }
                }

                // Apply OR logic through all filter types
                let showReplicator = fHeritages || fChallenges //|| fDrivers
                replicatorsDisplay[replicator.id] = showReplicator // set state to this Replicators

            }
            else if (this.state.logicFilter === 'AND') { // if Logic filter is AND

                // Check Challenges
                let fChallenges = true
                for (let stateChallenge in this.state.challenges) { // run through all state challenges
                    if (this.state.challenges[stateChallenge] === true) { // If state challenge is active
                        // Check if replicator has this challenge
                        let challengeExist = false
                        for (let k = 0; k < replicator.challenges.length; k++) {
                            let replicatorChallenge = this.cleanStringToOptionsList(replicator.challenges[k])
                            if (replicatorChallenge === stateChallenge) { // if challenge exit turn flag true
                                challengeExist = true
                            }
                        }
                        fChallenges = fChallenges && challengeExist // AND logic
                    }
                }

                // Check Heritage filter
                let fHeritages = true // Indicates if this replicator should be visible according to heritages replicator data

                let heritagesSwitches = Object.keys(this.state.heritages)   // Get all switches names from states
                for(let s = 0; s < heritagesSwitches.length; s++){          // Go through all switches
                    let heritageSwitchName  = heritagesSwitches[s]                      // Get heritage Switch Name
                    let heritageSwitchState = this.state.heritages[heritageSwitchName]  // Get Heritage Switch State

                    // If switch state is active
                    if (heritageSwitchState === true){

                        // Go through all replicator actions, if one of the actions contains the heritage type then
                        // activate the filter
                        let heritageExist = false
                        for (let action of replicator.actions) {
                            let actionHeritages = action.relevant_heritage.map(h=>h.toLowerCase()) // Get action heritage list

                            // If action list contains the selected switch heritage
                            if (actionHeritages.includes(heritageSwitchName.toLowerCase())) {
                                heritageExist = true // heritage exist
                            }
                        }

                        // apply AND logic for heritages switches
                        fHeritages = fHeritages && heritageExist
                    }
                }

                // Make AND logic
                let showReplicator = fHeritages && fChallenges //&& fDrivers
                replicatorsDisplay[replicator.id] = showReplicator // set state to this Replicators
            } else {
                console.log('ERROR!! Unkown logicFilter value: ', this.state.logicFilter)
            }

        }

        return replicatorsDisplay
    }

    // sort RM List according to geography scores
    sortRM () {

        let rmScored = [
            // {
            //     score: 0.7,
            //     data: { ...rm data...}
            // }
        ]

        let scores = { ...this.state.semanticScores }
        // Calculate total scores

        for (let rmName in scores.geography) {
            let rm = getRmData(rmName, this.db.rm)
            if (rm.found) {
                rmScored.push({
                    score: this.rmScoreTotal(rmName),
                    data: rm.data
                })
            } else {
                console.log('WARNING: rm "' + rmName + '" not found!')
            }
        }
        rmScored.sort(this.compare)

        return rmScored

    }

    // returns the total score of a RM based on semantic evaluation scores
    rmScoreTotal (rmName) {
        let scores = { ...this.state.semanticScores }

        let score = 0
        let semanticCategory = Object.keys(scores)
        for (let i = 0; i < semanticCategory.length; i++) {
            score += scores[semanticCategory[i]][rmName]
        }

        // let score = scores.geography[rmName] + scores.economic[rmName]
        return score
    }

    // Returns an object contained to link between LL to RMs based on all LL in db
    getAllRmFromLL (allLL) {

        let llObj = {}

        // let allLL = this.db.ll
        for (let i = 0; i < allLL.length; i++) {
            let ll = allLL[i]
            llObj[ll['LL CODE']] = this.getRmFromLL(ll)
        }

        return llObj
    }

    // Returns a RM list mentioned in the LL based on RM actions field.
    getRmFromLL (ll) {

        let rmList = []
        if (typeof ll['CODE RM ACTION'] !== 'undefined') {
            let rmaList = ll['CODE RM ACTION']
            for (let i = 0; i < rmaList.length; i++) {
                let actionName = rmaList[i].trim()
                let idx = actionName.indexOf('-')
                let rmName = actionName.slice(0, idx)
                rmList.push(rmName)
            }
        } else {
            console.log('Warning! Lesson Learnt ' + ll['LL CODE'] + ' does not have CODE RM ACTION key')
        }

        // filter list to have unique RMs
        let uniqueRmList = rmList.filter((v, i, a) => a.indexOf(v) === i) // array of unique RM

        return uniqueRmList
    }

    // sort LL list according to the number of each LL is related to RM
    sortLL () {

        let llList = [] // list to be returned

        let llDisplay = { ...this.state.llDisplay }

        // Make the selection of visible LL to be returned
        let visibleLL = []
        for (let llName in llDisplay) {
            if (llDisplay[llName]) {
                visibleLL.push(llName)
            }
        }

        // Return empty array if none of the LL are visible
        if (visibleLL.length === 0) {
            return llList
        }

        for (let i = 0; i < visibleLL.length; i++) { // Go throught all visible LL
            let llName = visibleLL[i] // get LL name

            let llAux = getLLInfo(llName, this.db.ll) // get LL info
            if (llAux.found) {  // if LL found
                let ll = llAux.llInfo // get LL data

                let llScore = 0     // initialize score for this LL
                let rmList = this.db.ll_rm[llName] // get the RM list associated to this LL
                for (let k = 0; k < rmList.length; k++) { // Go through all RM list associated to this LL
                    let rmName = rmList[k] // get RM name
                    let rmScore = this.rmScoreTotal(rmName) // get RM score
                    llScore += rmScore // Sum all scores associated to this LL
                }
                llScore = llScore / rmList.length // average score. (Is it a good choice?!)

                // create object for the LL
                let obj =
                    {
                        llName: llName,
                        description: ll['LESSONS LEARNT'],
                        score: llScore
                    }

                // add object to the LL list
                llList.push(obj)

            } else {
                console.log('Warning! Lesson Learnt "' + llName + '" not found!')
            }

        }

        // Sort LLlist
        llList.sort(this.compare)

        return llList

    }

    //TODO: Merge those two compare functions by passing the field to be compare
    compare (a, b) {
        if (a.score < b.score) { return 1 }
        if (a.score > b.score) { return -1 }
        return 0
    }

    compareField (a, b, field) {
        if (a['perc'] < b['perc']) { return 1 }
        if (a['perc'] > b['perc']) { return -1 }
        return 0
    }

    render () {

        // ---------------------------------------
        // ------------ Filters ------------------
        // ---------------------------------------
        // Create filter options for all type of filters

        // let optionsSIA = []
        // let siasArr = this.getSIAsArr()
        // for (let i = 0; i < siasArr.length; i++) {
        //     optionsSIA.push({ label: siasArr[i], checked: this.state.sias[siasArr[i]] })
        // }

        let optionsChallenges = []
        let challengesArr = this.getChallengesArr()
        for (let i = 0; i < challengesArr.length; i++) {
            optionsChallenges.push({ label: challengesArr[i], checked: this.state.challenges[challengesArr[i]] })
        }

        let optionsDrivers = []
        let driversArr = this.getDriversArr()
        for (let i = 0; i < driversArr.length; i++) {
            optionsDrivers.push({ label: driversArr[i], checked: this.state.drivers[driversArr[i]] })
        }

        let optionsHeritages = []
        let heritagesArr = this.getHeritagesArr()
        for (let i = 0; i < heritagesArr.length; i++) {
            optionsHeritages.push({ label: heritagesArr[i], checked: this.state.heritages[heritagesArr[i]] })
        }

        // Create an array to hold all filters to show
        const filters = [
            // {
            //     title: 'SIAs',
            //     filterName: 'sias', // filterName in state
            //     options: optionsSIA,
            //     masterSwitch: this.state.masterSwitches.sias
            // },
            {
                title: 'Challenges',
                filterName: 'challenges', // filterName in state
                options: optionsChallenges,
                masterSwitch: this.state.masterSwitches.challenges
            },
            {
                title: 'Drivers',
                filterName: 'drivers', // filterName in state
                options: optionsDrivers,
                masterSwitch: this.state.masterSwitches.drivers
            },
            {
                title: 'Heritages',
                filterName: 'heritages', // filterName in state
                options: optionsHeritages,
                masterSwitch: this.state.masterSwitches.heritages
            }
        ]
        // -------------------------------------------------------

        // ---------------------------------------
        // ------ Selection Summary --------------
        // ---------------------------------------

        // General options to be applied on Graphs. Some graphs might tune some parameters for specific situations
        let genericOpt = {
            colors: [
                theme.palette.DSS_Pallet.mainColours.BROWN,
                theme.palette.DSS_Pallet.mainColours.YELLOW,
                theme.palette.DSS_Pallet.mainColours.DARK_GREEN,
                theme.palette.DSS_Pallet.mainColours.GRAY,
                theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN,
                theme.palette.DSS_Pallet.supplementaryColours.DARK_GRAY,
                theme.palette.DSS_Pallet.mainColours.GREEN,
                theme.palette.DSS_Pallet.mainColours.DARK_BROWN
            ],
            yaxis: {
                show: false
            },
            chart: {
                // parentHeightOffset: 15
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    size: 60,
                    offsetX: 10,
                },
                radar: {
                    size: 50,
                    polygons: {
                        strokeColor: '#e8e8e8',
                        fill: {
                            colors: ['#f8f8f8', '#fff']
                        }
                    }
                }
            },
            legend: {
                show: true,
                position: 'right', //'bottom',
                horizontalAlign: 'left',
                floating: false,//true,
                width: 250,
                height: 140, // should be the height of the graph
                offsetY: -10, // For some reason it hiddes to last element of the legend
            }
        }

        // LL Related Crosscutting
        let selectionData_RelatedCrosscutting = this.getSelectionData_relatedCrosscutting()
        let optRelatedCrosscutting = {
            ...genericOpt,
            labels: selectionData_RelatedCrosscutting.labels,
        }

        // Capital Transference Mechanism Data
        let selectionData_CTM = this.getSelectionData_CTM()
        let optCTM = {
            ...genericOpt,
            labels: selectionData_CTM.labels, // array
            chart: {
                toolbar: {
                    show: false
                }
            },
            legend: {
                ...genericOpt.legend,
                width: 150 // adjust width since it doesn't required so much space for this graph
            }
        }

        // RM Drivers Data
        let selectionData_drivers = this.getSelectionData_drivers()
        let optDrivers = {
            ...genericOpt,
            labels: selectionData_drivers.labels
        }

        // ---------------------------------------

        // ---------------------------------------
        // ------------ ROLE MODELS --------------
        // ---------------------------------------
        let rmSorted = this.sortRM()
        let llSorted = this.sortLL()

        function llRelatedCrosscuttingTooltip () {

            let title = 'LL - RELATED CROSSCUTTING',
                description = 'Indicates the achieved related crosscutting concerns present in the selected Lessons Learnt.'

            let tooltipObj = {
                title: title,
                tooltip: (
                    <Box m={1}>
                        {DSSTooltipTile(title)}
                        {DSSTooltipDescription(description)}
                    </Box>
                )
            }

            return tooltipObj
        }

        function llCTMTooltip () {
            let title = 'LL - CAPITAL TRANSFERENCE MECHANISM',
                description = 'Indicates which types of capital were present in the initial phase, which were developed and which were eventually obtained.'

            let tooltipObj = {
                title: title,
                tooltip: (
                    <Box m={1}>
                        {DSSTooltipTile(title)}
                        {DSSTooltipDescription(description)}
                    </Box>
                )
            }

            return tooltipObj
        }

        function rmDriversTooltip () {
            let title = 'RM - DRIVERS',
                description = 'Indicates the drivers shaping the processes performed by the Role Models.'

            let tooltipObj = {
                title: title,
                tooltip: (
                    <Box m={1}>
                        {DSSTooltipTile(title)}
                        {DSSTooltipDescription(description)}
                    </Box>
                )
            }

            return tooltipObj
        }

        let selectionSummaryTooltips = {
            llRelatedCrosscutting: llRelatedCrosscuttingTooltip(),
            llCTM: llCTMTooltip(),
            rmDrivers: rmDriversTooltip()
        }

        return (
            <React.Fragment>
                { !this.state.dataLoaded ?
                    <Box m={2} p={2}>Loading data...</Box>
                    :
                    <Box mt={1} mx={1}>

                        {/* (DSS) info and Filters */}
                        <Grid container>

                            {/*Decision Support System (DSS) info*/}
                            <Grid item xs={12}>
                                <DSSInfo />
                            </Grid>

                            {/*FILTERS*/}
                            <Grid item xs={12}>

                                <Box mx={1} mb={2} p={0} color="background.paper" border={1} boxShadow={theme.boxShadow.BROWN}>

                                    <Accordion elevation={0} /*defaultExpanded*/>

                                        {/*FILTERS title*/}
                                        <AccordionSummary expandIcon={<Box color="secondary.main"><ExpandMoreIcon/></Box>}>
                                            <TuneIcon color="secondary" my="auto"/>
                                            <Box my="auto" pl={1} textAlign="center" color="secondary.main" letterSpacing={2}
                                                 fontWeight="fontWeightMedium">
                                                FILTERS
                                            </Box>
                                        </AccordionSummary>

                                        {/*FILTERS container*/}
                                        <AccordionDetails style={{backgroundColor: theme.lightGray }}>
                                            <FiltersContainer
                                                logicFilter={this.state.logicFilter}
                                                logicFilterOnChange={this.changeLogicFilter()}

                                                filters={filters}
                                                filterSwitchChanges={this.filterSwitchChanges}

                                                masterSwitch={this.state.masterSwitches}
                                                changeAllSwitches={this.changeAllSwitches}

                                                semanticAnalysis={this.semanticAnalysis}
                                                progressBarGeo={this.state.progressBarGeo}
                                            />
                                        </AccordionDetails>

                                    </Accordion>

                                </Box>

                            </Grid>

                        </Grid>

                        {/* RM, Replicators and LL*/}
                        <Grid container>

                            {/*ROLE MODELS*/}
                            <Grid item xs={12} md={6}>
                                <Box m={1} p={1}
                                     bgcolor="background.paper" boxShadow={3}>
                                    <Box p={1} textAlign="center" color="secondary.main" letterSpacing={2}
                                         fontWeight="fontWeightMedium" borderBottom={1}>
                                        ROLE MODELS
                                    </Box>
                                    <Box maxHeight={600}
                                         minHeight={200}
                                         overflow="auto">
                                        <ResultsContainer
                                            db={this.db}
                                            rmDisplay={this.state.rmDisplay}
                                            rm={rmSorted}
                                            // handleSChange={() => this.handleSChange()}
                                        />
                                    </Box>
                                </Box>
                            </Grid>

                            {/*REPLICATORS*/}
                            <Grid item xs={12} md={6}>
                                <Box m={1} p={1}
                                     bgcolor="background.paper" boxShadow={3}>
                                    <Box p={1} textAlign="center" color="secondary.main" letterSpacing={2}
                                         fontWeight="fontWeightMedium" borderBottom={1}>
                                        REPLICATORS
                                    </Box>
                                    <Box maxHeight={600}
                                         minHeight={200}
                                         overflow="auto">

                                        <ReplicatorsList
                                            db={this.db}
                                            replicatorsDisplay={this.state.replicatorsDisplay}
                                        />

                                    </Box>
                                </Box>
                            </Grid>

                            {/*LESSONS LEARNT*/}
                            <Grid item xs={12} md={12}>
                                <Box m={1} p={1}

                                     bgcolor="background.paper" boxShadow={3}>
                                    <Box p={1} textAlign="center" color="secondary.main" letterSpacing={2}
                                         fontWeight="fontWeightMedium" borderBottom={1}>
                                        LESSONS LEARNT
                                    </Box>
                                    <Box maxHeight={400}
                                         minHeight={200}
                                         overflow="auto">
                                        <LessonsLearntFilteredList
                                            llDisplay={this.state.llDisplay}
                                            ll={llSorted} // LL to be inserted
                                            db={this.db}
                                        />
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>

                        {/* SELECTION SUMMARY and RELATED DISCUTSSONS (DRHH) */}
                        <Grid container>

                            {/*SELECTION SUMMARY*/}
                            <Grid item xs={12}>
                                <Box m={1} p={1}
                                     bgcolor="background.paper"
                                     boxShadow={3}
                                >

                                    <Box bgcolor="DSS_Pallet.mainColours.YELLOW" color="background.paper"
                                         letterSpacing={2}
                                         display="flex" alignItems="center" justifyContent="center"
                                    >
                                        SELECTION SUMMARY
                                    </Box>

                                    <Typography variant="caption">
                                        <Box mt={1} fontWeight="fontWeightLight" fontStyle="oblique" lineHeight={1.2}>
                                            This panel shows summary insights of the remaining selection of Role Models and
                                            Lessons Learnt. On each cell you have a graphical representation of parts of these
                                            contents where you can explore in more detail each one.
                                        </Box>
                                    </Typography>

                                    <Box display="flex"
                                         flexDirection="row"
                                         flexWrap="nowrap"
                                         style={{ overflowX: 'auto', }}
                                    >

                                        <SummaryModuleBox graphtitle={selectionSummaryTooltips.llRelatedCrosscutting.title}
                                                          tooltip={selectionSummaryTooltips.llRelatedCrosscutting.tooltip}>
                                            <LLRelatedCrosscutting
                                                options={optRelatedCrosscutting}
                                                data={selectionData_RelatedCrosscutting.data}
                                            />
                                        </SummaryModuleBox>

                                        <SummaryModuleBox graphtitle={selectionSummaryTooltips.llCTM.title}
                                                          tooltip={selectionSummaryTooltips.llCTM.tooltip}>
                                            <LLctm
                                                options={optCTM}
                                                data={selectionData_CTM.data}
                                                listsCTM={selectionData_CTM.listsCTM}
                                                ll={llSorted}
                                                db={this.db}
                                            />
                                        </SummaryModuleBox>

                                        <SummaryModuleBox graphtitle={selectionSummaryTooltips.rmDrivers.title}
                                                          tooltip={selectionSummaryTooltips.rmDrivers.tooltip}>
                                            <RmDrivers
                                                options={optDrivers}
                                                data={selectionData_drivers.data}
                                            />
                                        </SummaryModuleBox>
                                    </Box>

                                </Box>
                            </Grid>

                            {/*RELATED DISCUTSSONS (DRHH)*/}
                            <Grid item xs={12}>
                            <Box m={1} p={1}
                                 bgcolor="background.paper"
                                 boxShadow={3}
                            >

                                <Box bgcolor="DSS_Pallet.mainColours.YELLOW" color="background.paper"
                                     letterSpacing={2}
                                     display="flex" alignItems="center" justifyContent="center"
                                >
                                    RELATED DISCUSSIONS (DRHH)
                                </Box>

                                <Typography variant="caption">
                                    <Box mt={1} fontWeight="fontWeightLight" fontStyle="oblique" lineHeight={1.2}>
                                        This panel tries to show related discussions that are happening in the DRHH. It
                                        might help you to find more useful information.
                                    </Box>
                                </Typography>

                                <DRHHRelatedDiscussions drhhInfo={this.state.drhhInfo}/>
                            </Box>
                        </Grid>

                        </Grid>

                    </Box>
                }
            </React.Fragment>
            )
    }

}
