import React from 'react'

import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import SchoolIcon from '@material-ui/icons/School';

import DialogInfo from '../DialogInfo'
import LLContent from '../LLContent'


export default function LLList(props) {

    const {
        db,
        llList,
        // filter,
    } = props

    const [selectedLL, setSelectedLL] = React.useState('undefined')
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [llDialog, setLlDialog] = React.useState({})

    function handleDialogClose () {setDialogOpen(false)}
    function handleListItemClick (event, elem) {
        setSelectedLL(elem["LESSONS LEARNT"])    // keeps the highligh of the selected LL
        setLlDialog(elem)           // gives the data be displayed in dialog window
        setDialogOpen(true)   // Opens the dialog
    }

    return (
        <Box maxHeight={300} overflow="auto">
            <List nav="nav" dense>

                {
                    llList.map(elem => (
                        <Collapse in={true} key={elem['LL CODE']}>
                            <ListItem
                                button
                                onClick={event => handleListItemClick(event, elem)}
                            >
                                <ListItemIcon>
                                    <SchoolIcon />
                                </ListItemIcon>

                                <ListItemText
                                    primary={
                                        <Typography component="div" variant='body2'>
                                            {elem["LESSONS LEARNT"]}
                                        </Typography>
                                    }
                                />

                            </ListItem>
                            <Divider/>
                        </Collapse>
                    ))
                }

            </List>


            <DialogInfo dialogOpen={dialogOpen}
                        title={selectedLL}
                        handleClose={() => handleDialogClose()}
            >
                <LLContent db={db} lesson={llDialog}/>
            </DialogInfo>

        </Box>
    )
}