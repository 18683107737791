import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import RMList from '../RMList'
import RMAnalyzes from '../RMAnalyzes'

import { Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Collapse from '@material-ui/core/Collapse'

import { RM_SIZES } from '../../../js/utils'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps (index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
}

// Returns a list of RM objects that contain a specific Sia
function filterRmBySize (rms, size) {
    return rms.filter(rm => rm['Context']['Size'] === size)
}

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    // indicator: {
    //     backgroundColor: '#1890ff',
    // },
})(Tabs)

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&$selected': {
            color: 'primary',
            fontWeight: theme.typography.fontWeightMedium,
            outline: 'none'
        },
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&:focus': {
            color: 'primary',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />)

export default function SizePanel (props) {

    const { db, sizeState } = props

    const [analyzesOpen, setAnalyzesOpen] = React.useState(false)
    const handleAnalyzesOpenChange = (event) => {
        setAnalyzesOpen(() => !analyzesOpen)
    }

    const [value, setValue] = React.useState(0) // TODO: Pass the position of sizeState

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    // Get all active Sias in an array
    // let siasActiveList = Object.keys(siasStates).filter(sia => siasStates[sia] === true)

    let sizeModules = {
        // SMALL: list1,
        // MEDIUM: list2,
    }

    let rmSizes = [...RM_SIZES, '']

    rmSizes.forEach(size => {
        sizeModules[size] = filterRmBySize(db.rm, size)
    })

    sizeModules['undefined'] = sizeModules['']
    delete sizeModules['']
    // console.log('siasModules:', siasModules)

    return (
        <React.Fragment>

            <AntTabs
                value={value}
                onChange={handleChange}

                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                style={{ borderBottom: '1px solid #e8e8e8' }}
            >
                {Object.keys(sizeModules).map((size, idx, arr) =>
                    (
                        <AntTab key={size} label={size.toUpperCase()}/>
                    )
                )}
            </AntTabs>


            <Box borderTop={0} borderColor="grey.500">
                {Object.keys(sizeModules).map((size, idx, arr) =>
                    <TabPanel key={size} value={value} index={idx}>
                        {/*list*/}
                        <Collapse in={!analyzesOpen}>
                            <Box>
                                <Typography component="div" variant="overline">
                                    <ArrowRightIcon/><Box component={'span'} fontWeight="fontWeightBold">Role
                                    Models</Box> related list:
                                </Typography>
                                <RMList db={db} rmList={sizeModules[size]} filter={() => true}/>
                            </Box>
                        </Collapse>
                        {/*Analyses*/}
                        <Box mt={1}>
                            <Button
                                onClick={handleAnalyzesOpenChange}
                                color="primary"
                                variant={'contained'} style={{ height: '32px' }} fullWidth>
                                Analysis {analyzesOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </Button>

                            <Collapse in={analyzesOpen}>
                                <Box bgcolor="rgba(245, 245, 245, 0.6)" boxShadow={1} borderColor="secondary.main"
                                     width="100%" p={1}>
                                    <RMAnalyzes db={db} rmList={sizeModules[size]}/>
                                </Box>
                            </Collapse>
                        </Box>
                    </TabPanel>
                )}

            </Box>
        </React.Fragment>
    )
}