import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import TableList from './TableList'
import LLList from './wizard/LLList'
import RMAList from './RMAList'
import BasePaper from './BasePaper'
import Avatar from '@material-ui/core/Avatar'

import {siasDefinition} from '../js/utils'

function getSiaName(siaId){
    let siaElem = siasDefinition.find(elem => elem['RELATED SIA'] === siaId)
    return siaElem['SIA NAME']
}

function Title (props) {
    return (
        <Typography variant="h6">
            <Box color="secondary.main" letterSpacing={2} fontSize="htmlFontSize"
                 fontWeight="fontWeightMedium" borderBottom={1}>
                {props.children}
            </Box>
        </Typography>
    )
}

function dataNotFound () {
    return <div>Data not found!</div>
}

function getLLbyId(db, llListIds){

    let llList = [] // array of lessons learnt to retrieve

    // Go trhough all LL ids
    llListIds.forEach(llId=>{

        // Find the ll by id
        let ll_temp = db.ll.find(elem => elem['LL CODE'] === llId)
        // Push ll data to llList array (If not found then prints a warning!)
        ll_temp !== 'undefined' ? llList.push(ll_temp) : console.log("Warning! LL id not found: " + llId)
    })

    return llList

}

export default function ReplicatorActionInfo (props) {
    const { db, actionId } = props

    // console.log("db", db)

    function dataInfo (action) {

        let llList = getLLbyId(db, action['useful_ll'])

        return (
            <div>
                <Grid container spacing={2}>
                    {/*ACTION ID*/}
                    <Grid item xs={3}>
                        <Title>ACTION ID</Title>
                        <Typography>{action['action_id']}</Typography>
                    </Grid>

                    {/*ACTION NAME*/}
                    <Grid item xs={3}>
                        <Title>ACTION NAME</Title>
                        <Typography>{action['action_name']}</Typography>
                    </Grid>

                    {/*COSTS*/}
                    <Grid item xs={3}>
                        <Title>ACTION COSTS</Title>
                        <Typography>{action['costs']['indicative_costs'] + ' ' + action['costs']['currency']}</Typography>
                    </Grid>

                    {/*TIME*/}
                    <Grid item xs={3}>
                        <Title>TIME</Title>
                        <Typography>Started: {action['time']['start_time']}</Typography>
                        <Typography>Ended:   {action['time']['end_time']}</Typography>
                    </Grid>

                    {/*DESCRIPTION*/}
                    <Grid item xs={12}>
                        <Title>DESCRIPTION</Title>
                        <Typography>{action['description']}</Typography>
                    </Grid>



                    {/*OBJECTIVE*/}
                    <Grid item xs={6}>
                        <Title>OBJECTIVE</Title>
                        <Typography>{action['objective']}</Typography>
                    </Grid>

                    {/*RELATED OBJECTIVES*/}
                    <Grid item xs={3}>
                        <Title>RELATED OBJECTIVES</Title>
                        {action['related_objectives'] ? <TableList list={action['related_objectives']}/> : null}
                    </Grid>

                    {/*RELEVANT HERITAGE*/}
                    <Grid item xs={3}>
                        <Title>RELEVANT HERITAGE</Title>
                        {action['relevant_heritage'] ? <TableList list={action['relevant_heritage']}/> : null}
                    </Grid>


                    {/*ACTIVITIES*/}
                    <Grid item xs={12}>
                        <Title>ACTIVITIES</Title>
                        { action['activities'] ? <TableList list={action['activities']}/> : null}
                    </Grid>

                    {/*SUSTAINABILITY*/}
                    <Grid item xs={12}>
                        <Title>SUSTAINABILITY</Title>
                        <Typography>{action['sustainability']}</Typography>
                    </Grid>

                    {/*RELATED CHALLENGES*/}
                    <Grid item xs={12}>
                        <Title>RELATED CHALLENGES</Title>
                        {action['related_challenges'] ? <TableList list={action['related_challenges']}/> : null}
                    </Grid>


                    {/*MAIN STAKEHOLDERS*/}
                    <Grid item xs={4}>
                        <Title>MAIN STAKEHOLDERS</Title>
                        {action['main_stakeholders'] ? <TableList list={action['main_stakeholders']}/> : null}
                    </Grid>

                    {/*FORMAL PARTNERSHIPS ESTABLISHED*/}
                    <Grid item xs={4}>
                        <Title>FORMAL PARTNERSHIPS ESTABLISHED</Title>
                        {action['formal_partnerships_established'] ? <TableList list={action['formal_partnerships_established']}/> : null}
                    </Grid>

                    {/*BENEFICIARIES*/}
                    <Grid item xs={4}>
                        <Title>BENEFICIARIES</Title>
                        { action['beneficiaries'] ? <TableList list={action['beneficiaries']}/> : null }
                    </Grid>

                    {/*INDICATIVE FUNDING SOURCES*/}
                    <Grid item xs={12}>
                        <Title>INDICATIVE FUNDING SOURCES</Title>
                        {action['indicative_funding_sources'] ? <TableList list={action['indicative_funding_sources']}/> : null}
                    </Grid>

                    {/*CAPITALS*/}
                    <Grid item xs={6}>
                        <Title>CAPITALS</Title>
                        {action['capitals'] ? <TableList list={action['capitals']}/> : null}
                    </Grid>

                    {/*RELEVANT SIAS*/}
                    <Grid item xs={6}>
                        <Title>RELEVANT SIAS</Title>
                        {action['relevant_sias'] ? <TableList list={action['relevant_sias'].map(siaId => getSiaName(siaId))}/> : null}
                    </Grid>

                    {/*USEFUL LESSONS LEARNT*/}
                    <Grid item xs={12}>
                        <Title>USEFUL LESSONS LEARNT</Title>
                        {action['useful_ll'] ? <LLList db={db} llList={llList} /> : null}
                    </Grid>

                    {/*REFERENCE ROLE MODEL ACTION*/}
                    <Grid item xs={12}>
                        <Title>REFERENCE ROLE MODEL ACTION</Title>
                        <RMAList db={db} rmaList={action['reference_rma']}/>
                    </Grid>

                    {/*MONITORING INDICATORS*/}
                    <Grid item xs={12}>
                        <Title>MONITORING INDICATORS</Title>
                        {action['monitoring_indicators'] ? <TableList list={action['monitoring_indicators']}/> : null}
                    </Grid>

                </Grid>

            </div>
        )
    }

    function getReplicatorAction (id) {

        let action = {}

        // Go through all Replicators
        for (let i = 0; i < db.replicators.length; i++) {
            let replicator = db.replicators[i]

            // Go through all Replicators actions
            for (let k = 0; k < replicator.actions.length; k++) {
                let action_temp = replicator.actions[k]

                // If action is found by id then return action
                if (action_temp.action_id === id) {
                    action = action_temp
                    break
                }
            }
        }
        return action
    }

    let action = getReplicatorAction(actionId)

    // If action object is not empty then show data otherwise show data not found
    return (Object.entries(action).length === 0 && action.constructor === Object) ? dataNotFound() : dataInfo(action)

}