import React from 'react'
import { Box, Button, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
// import { theme } from '../../../theme/theme'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import ViewListIcon from '@material-ui/icons/ViewList'

import Divider from '@material-ui/core/Divider'
// import styled from 'styled-components'
// import { ProgressBar } from 'react-bootstrap'

import DialogInfo from '../../DialogInfo'
import LLContent from '../../LLContent'

function getLLAchievementsList (llList) {

    let all = []
    llList.forEach(lesson => {
        all = [...all, ...lesson['ACHIVEMENTS']]
    })

    all = [...new Set(all)]

    return all
}

function getLLAchievementsCounter (llList) {

    let aCounter = []

    llList.forEach(lesson => {

        lesson['ACHIVEMENTS'].forEach(achievement => {

            // Find object that contains this achievement
            let achievementObj = aCounter.find(obj => obj.achievement === achievement)
            if (typeof achievementObj !== 'undefined') {

                // Increase counter
                achievementObj.counter++

                // Add LL if it doesn't exist yet
                if (!(lesson['LL CODE'] in achievementObj.LLIdArray)) {
                    achievementObj.LLIdArray.push(lesson['LL CODE'])
                }

            } else {
                // Add new entry
                aCounter.push(
                    {
                        achievement: achievement,
                        counter: 1,
                        LLIdArray: [lesson['LL CODE']]
                    })
            }

        })
    })
    return aCounter
}

const compareField = field => (a, b) => {
    if (a[field] < b[field]) { return 1 }
    if (a[field] > b[field]) { return -1 }
    return 0
}

function LLAchievementsTableList (props) {

    const { db, llList } = props

    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [lesson, setLesson] = React.useState({})

    function handleDialogClose () {
        setDialogOpen(false)
    }

    function handleDialogOpen (newLesson) {
        setLesson(newLesson)
        setDialogOpen(true)
    }

    return (
        <Box>


            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" width="100%">Achievement</TableCell>
                        <TableCell align="center">Lesson Learnt</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        llList.map(lesson => (
                            <React.Fragment key={lesson['LL CODE']}>

                                <TableRow hover>

                                    <TableCell>{lesson['ACHIVEMENTS'][0]}</TableCell>
                                    <TableCell rowSpan={lesson['ACHIVEMENTS'].length}>
                                        <Tooltip title={lesson['LESSONS LEARNT']}>
                                            <Button
                                                onClick={() => handleDialogOpen(lesson)}
                                                variant="contained" size="small"
                                                color={'primary'}
                                            >
                                                {lesson['LL CODE']}
                                            </Button>
                                        </Tooltip>
                                    </TableCell>

                                </TableRow>

                                {
                                    lesson['ACHIVEMENTS'].length > 1 &&
                                    <React.Fragment>
                                        {
                                            lesson['ACHIVEMENTS'].slice(1).map((achievement, idx) =>
                                                <TableRow key={lesson['LL CODE'] + achievement + idx} hover>
                                                    <TableCell>{achievement}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </React.Fragment>
                                }

                            </React.Fragment>

                        ))
                    }

                </TableBody>
            </Table>

            <DialogInfo
                dialogOpen={dialogOpen}
                title={'Lesson learnt ' + lesson['LL CODE']}
                handleClose={handleDialogClose}
            >
                <LLContent db={db} lesson={lesson}/>
            </DialogInfo>
        </Box>
    )
}

// const CustomProgress = styled(ProgressBar)`
//   .progress-bar {
//     background-color: ${theme.palette.DSS_Pallet.mainColours.GREEN};
//     // color: ${theme.palette.DSS_Pallet.mainColours.DARK_BROWN};
//     // text-align: left;
//   },
//   .progress {
//     // height: 15px;
//     // cursor: pointer;
//   }
// `

function LLAchievementsTableCounter (props) {

    const { db, llList } = props

    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [lesson, setLesson] = React.useState({})

    function handleDialogClose () {
        setDialogOpen(false)
    }

    function handleDialogOpen (lessonID) {
        let newLesson = llList.find(ll => ll['LL CODE'] === lessonID) // find LL object
        setLesson(newLesson)
        setDialogOpen(true)
    }

    // Get achievements counter object
    let achievementsCounter = getLLAchievementsCounter(llList)

    // Sort rma Array
    achievementsCounter = achievementsCounter.sort(compareField('counter'))

    // Max Counter
    // let maxCounter = achievementsCounter[0].counter

    return (
        <Box>
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" width='100%'>Achievement</TableCell>
                        <TableCell align="center" width={90}>Lesson Learnt</TableCell>
                        {/*<TableCell align="center" width={90}>Mentioned</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        achievementsCounter.map(achievementObj => (
                            <React.Fragment key={achievementObj.achievement}>

                                <TableRow hover>

                                    <TableCell>{achievementObj.achievement}</TableCell>
                                    <TableCell>
                                        {achievementObj.LLIdArray.map((llId, idx) => (

                                                <Tooltip key={achievementObj.achievement + llId + idx}
                                                         title={llList.find(lesson => lesson['LL CODE'] === llId)['LESSONS LEARNT']}>
                                                    <Box>
                                                        <Button
                                                            onClick={() => handleDialogOpen(llId)}
                                                            variant="contained" size="small"
                                                            color={'primary'}
                                                        >
                                                            {llId}
                                                        </Button>

                                                        <Divider/>
                                                    </Box>
                                                </Tooltip>
                                            )
                                        )}

                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    <CustomProgress*/}
                                    {/*        label={achievementObj.counter}*/}
                                    {/*        now={100.0 * (achievementObj.counter / maxCounter)}*/}
                                    {/*    />*/}
                                    {/*</TableCell>*/}
                                </TableRow>

                            </React.Fragment>

                        ))
                    }

                </TableBody>
            </Table>

            <DialogInfo
                dialogOpen={dialogOpen}
                title={'Lesson learnt ' + lesson['LL CODE']}
                handleClose={handleDialogClose}
            >
                <LLContent db={db} lesson={lesson}/>
            </DialogInfo>

        </Box>
    )
}

export default function LLAchievementsList (props) {

    const { db, llList } = props

    let MODES = {
        COUNTER: 'CounterMode',
        LIST: 'ListMode'
    }
    const [listMode, setListMode] = React.useState(MODES.COUNTER)

    const handleModeChange = (event, newMode) => { setListMode(newMode) }

    let allAchievements = getLLAchievementsList(llList)

    return (

        <Box display="flex" flexDirection={'column'} height={450}>

            <Box>
                <Box align={'right'}>
                    <ToggleButtonGroup
                        value={listMode}
                        exclusive
                        size={'small'}
                        onChange={handleModeChange}
                        aria-label="Achievements Modes"
                    >

                        <ToggleButton value={MODES.LIST}>
                            <Tooltip title={'Group Achievements by Lessons Learnt'}>
                                <VerticalSplitIcon/>
                            </Tooltip>
                        </ToggleButton>

                        <ToggleButton value={MODES.COUNTER}>
                            <Tooltip
                                title={'Group Achievements by how frequently they are mentioned in those Lessons Learnt'}>
                                <ViewListIcon/>
                            </Tooltip>
                        </ToggleButton>

                    </ToggleButtonGroup>
                </Box>

                <Typography component="div" variant="caption">
                    <Box textAlign="right" fontStyle="italic">
                        Total Achievements: {allAchievements.length}
                    </Box>
                </Typography>
            </Box>


            <Box flexGrow={1} overflow="auto">

                {
                    listMode === MODES.LIST ?
                        <LLAchievementsTableList db={db} llList={llList}/> :
                        <LLAchievementsTableCounter db={db} llList={llList}/>
                }

            </Box>

        </Box>
    )
}