import React from 'react'
// import { withStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

// import ResultsControlPanel from './ResultsControlPanel'
import RmPanel from './RmPanel'
import DialogInfo from './DialogInfo'
import DSSHelpIcon from './DSSHelpIcon'
import { CustomProgress, DSSTooltipDescription, DSSTooltipTile, scaleScore } from '../js/utils'

// const db = require('../data/ruritage_mongodb_v5.json')

export default function ResultsContainer (props) {

    const [selectedRM, setSelectedRM] = React.useState('undefined')
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [rmDialog, setRmDialog] = React.useState({})

    function handleListItemClick (event, elem) {
        setSelectedRM(rmFullName(elem))    // keeps the highligh of the selected RM
        setRmDialog(elem)                   // gives the data be displayed in dialog window
        setDialogOpen(true)                 // Opens the dialog
    }

    function handleDialogClose () {
        setDialogOpen(false)
    }

    function rmFullName (elem) {
        // return elem.RM.toUpperCase() + ': ' + elem.Name
        return elem.Name
    }

    function relevanceTooltip () {
        let title = 'RELEVANCE',
            description = 'Based on your Criteria descriptions we try to find and bring to the top the most relevant ' +
                'content to you. The higher relevance the higher we think it does relate to your search.'
        return (
            <Box m={1}>
                {DSSTooltipTile(title)}
                {DSSTooltipDescription(description)}
            </Box>
        )
    }

    // // oldMin -> Minimum value in the previous range
    // // oldMax -> Maximum value in the previous range
    // // oldValue -> Value to be converter to between [0, 100]
    // function scaleScore (oldMin, oldMax, oldValue) {
    //     let newMin = 0.0,
    //         newMax = 100.0
    //     let newRange = (newMax - newMin)
    //     let scaledScore = newMin
    //     let oldRange = (oldMax - oldMin)
    //     if (oldRange !== 0) {
    //         scaledScore = (((oldValue - oldMin) * newRange) / oldRange) + newMin
    //     }
    //     return scaledScore
    // }

    function getScoreRangeFromVisibleList(rm, rmDisplay ){

        let max = -Infinity,
            min = Infinity
        for(let i = 0; i < rm.length; i++){
            let rmName = rm[i].data.RM
            let rmScore = rm[i].score

            // if rm is visible and rmScore is bigger than max
            if( (rmDisplay[rmName]) && (rmScore > max) ) {
                max = rmScore
            }

            // if rm is visible and rmScore is lower than min
            if( (rmDisplay[rmName]) && (rmScore < min) ) {
                min = rmScore
            }
        }
        return {min: min, max: max}
    }

    const { db, rm, rmDisplay } = props


    // get ranges for Relevance Progress bar
    let maxScore = 1,
        minScore = 0
    if (rm.length > 0) {
        let visibleRange = getScoreRangeFromVisibleList(rm, rmDisplay)
        maxScore = visibleRange.max
        minScore = visibleRange.min
    }

    return (
        <React.Fragment>

            <List component="nav">

                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>

                    <ListItemText
                        primary={
                            <Typography component="div" variant='body2'>
                                <Box color="secondary.main" fontWeight={500} fontSize={12}>NAME</Box>
                            </Typography>
                        }
                    />

                    <ListItemSecondaryAction>
                        <Typography component="div" variant='body2'>
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                    <DSSHelpIcon tooltipTitle={relevanceTooltip()}/>
                                </Box>
                                <Box color="secondary.main" fontWeight={500} fontSize={12}>
                                    RELEVANCE
                                </Box>
                            </Box>
                        </Typography>
                    </ListItemSecondaryAction>

                </ListItem>

                <Divider/>

                {
                    rm.map(elem => (
                        <Collapse key={elem.data.RM + '_key'} in={rmDisplay[elem.data.RM]}>
                            <ListItem
                                button
                                selected={selectedRM === elem.data.RM}
                                onClick={event => handleListItemClick(event, elem.data)}
                            >
                                <ListItemText
                                    // style={{ width: '80%' }}
                                    primary={
                                        <Typography component="div" variant='body2'>
                                            <Box style={{ width: '80%' }}>{elem.data.Name}</Box>
                                        </Typography>
                                    }
                                    secondary={
                                        <Box component="span">{elem.data.RM.toUpperCase()}</Box>
                                    }
                                />
                                <ListItemSecondaryAction style={{ width: '20%' }}>

                                    <CustomProgress
                                        style={{ width: '100%' }}
                                        // now={elem.score * 100.0}
                                        now={scaleScore(minScore, maxScore, elem.score)}
                                        // label={scaleScore(minScore, maxScore, elem.score) + '%'}
                                    />

                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider/>
                        </Collapse>
                    ))
                }
            </List>

            <DialogInfo dialogOpen={dialogOpen}
                        title={selectedRM}
                        handleClose={() => handleDialogClose()}
            >
                <RmPanel db={db} rm={rmDialog}/>
            </DialogInfo>

        </React.Fragment>
    )
}