import React, { Component } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

class SwitchList extends Component {

    constructor (props, context) {
        super(props, context)

        this.state = {
            //allSwitchs: objState,
            masterSwitch: false
        }
    }

    render () {
        const { filterName, sList, filterSwitchChanges, masterSwitch, changeAllSwitches } = this.props
        // console.log(this.state)
        return (
            <div>
                {/*Master switch*/}
                <Box mt={2} display='flex' justifyContent="flex-end" fontSize={9}>
                    <Typography component='div'>
                        <FormControlLabel
                            labelPlacement="start"
                            label={<Box fontSize={10}>ALL</Box>}
                            control={<Switch size="small" color="primary"
                                             checked={masterSwitch}
                                             onChange={changeAllSwitches(filterName)}
                            />}
                        />
                    </Typography>
                </Box>


                {/*List of Switchs*/}
                <FormGroup>
                    {sList.map(elem => (
                        <FormControlLabel
                            key={elem.label}
                            label={
                                <Typography variant="overline">
                                    <Box lineHeight={1.5}>
                                        {elem.label}
                                    </Box>
                                </Typography>}
                            control={<Switch color="primary" size="small"
                                             checked={elem.checked}
                                             onChange={filterSwitchChanges(filterName, elem.label)}
                            />}
                        />
                    ))}
                </FormGroup>
            </div>
        )
    }

}

export default SwitchList