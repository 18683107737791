import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import TableList from './TableList'


function Title (props) {
    return (
        <Typography variant="h6">
            <Box color="secondary.main" letterSpacing={2} fontSize="htmlFontSize"
                 fontWeight="fontWeightMedium" borderBottom={1}>
                {props.children}
            </Box>
        </Typography>
    )
}

function dataNotFound () {
    return <div>Data not found!</div>
}


export default function RMAInfo(props) {

    const { db, actionId } = props
    // console.log("db", db)

    function dataInfo (action) {
        return (
            <div>
                <Grid container spacing={2}>

                    <Grid item xs={4} xm={4}>
                        <Title>CODE RM ACTION</Title>
                        <Typography>{action['CODE RM ACTION']}</Typography>
                    </Grid>
                    <Grid item xs={4} xm={4}>
                        <Title>NAME OF THE RM ACTION:</Title>
                        <Typography>{action['NAME OF THE RM ACTION']}</Typography>
                    </Grid>
                    <Grid item xs={4} xm={4}>
                        <Title>REPLICABILITY</Title>
                        <Typography>{action['REPLICABILITY']}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Title>OBJECTIVE OF THE ACTION</Title>
                        <Typography variant="body2" component="div">
                            <Box py={1}>{action['OBJECTIVE OF THE ACTION']}</Box>
                        </Typography>
                    </Grid>


                    <Grid item xs={3}>
                        <Title>INITIAL CONDITIONS</Title>
                        <List>{action['INITIAL CONDITIONS'] ? <TableList list={action['INITIAL CONDITIONS']}/> : null}</List>
                    </Grid>
                    <Grid item xs={3}>
                        <Title>BARRIERS</Title>
                        <List>{action['BARRIERS'] ? <TableList list={action['BARRIERS']}/> : null}</List>
                    </Grid>
                    <Grid item xs={3}>
                        <Title>KEY ELEMENTS</Title>
                        <List>{action['KEY ELEMENTS'] ? <TableList list={action['KEY ELEMENTS']}/> : null}</List>
                    </Grid>
                    <Grid item xs={3}>
                        <Title>RELATED CAPITALS</Title>
                        <List>{action['RELATED CAPITALS'] ? <TableList list={action['RELATED CAPITALS']}/> : null}</List>
                    </Grid>


                    <Grid item xs={12}>

                        <Title>CAPITAL TRANSFERENCE MECHANISM</Title>

                        <Typography variant="body2" component="div">
                            <Box py={1}>{action['CAPITAL TRANSFERENCE MECHANISM']['DESCRIPTION']}</Box>
                        </Typography>

                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell><Box color="secondary.main">INITIAL</Box></TableCell>
                                    <TableCell><Box color="secondary.main">DEVELOPED</Box></TableCell>
                                    <TableCell><Box color="secondary.main">FINAL</Box></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    action['CAPITAL TRANSFERENCE MECHANISM']['List'] ? action['CAPITAL TRANSFERENCE MECHANISM']['List'].map((elem, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>{elem.INITIAL}</TableCell>
                                                <TableCell>{elem.DEVELOPED}</TableCell>
                                                <TableCell>{elem.FINAL}</TableCell>
                                            </TableRow>
                                        ))
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid item xs={12}>
                        <Title>RELATED MILESTONE:</Title>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell><Box color="secondary.main">MILESTONE</Box></TableCell>
                                    <TableCell><Box color="secondary.main">YEAR</Box></TableCell>
                                    <TableCell><Box color="secondary.main">CONCEPTUAL STEP</Box></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    action['RELATED MILESTONE'] ? action['RELATED MILESTONE'].map((elem, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>{elem['MILESTONE']}</TableCell>
                                                <TableCell>{elem['YEAR']}</TableCell>
                                                <TableCell>{elem['CONCEPTUAL STEP']}</TableCell>
                                            </TableRow>
                                        ))
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </Grid>


                    <Grid item xs={12}>
                        <Title>RMA-STAKEHOLDERS</Title>

                        <Grid container>
                            <Grid item xs={4}>
                                <Typography>Goverment: {action['goverment']}</Typography>
                                <Typography>Community: {action['community']}</Typography>
                                <Typography>Private Sector: {action['private sector']}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Level Of Participation: {action['level of participation']}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Polycentric/Monocentric: {action['polycentric/monocentric']}</Typography>
                                <Typography>Bottom up/Top Down: {action['BOTTOM UP/TOP DOWN']}</Typography>
                                <Typography>Governance Cluster: {action['governance cluster']}</Typography>
                            </Grid>
                        </Grid>


                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="overline"><Box color="secondary.main">ORGANIZATIONS LIST</Box></Typography>
                        </Box>

                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell><Box color="secondary.main">Name of the organisation</Box></TableCell>
                                    <TableCell><Box color="secondary.main">stcode</Box></TableCell>
                                    <TableCell><Box color="secondary.main">initiating actor?</Box></TableCell>

                                    <TableCell><Box color="secondary.main">Type</Box></TableCell>
                                    <TableCell><Box color="secondary.main">Subtype</Box></TableCell>
                                    <TableCell><Box color="secondary.main">Role</Box></TableCell>
                                    <TableCell><Box color="secondary.main">Which was their contribution to the process?</Box></TableCell>
                                    <TableCell><Box color="secondary.main">Describe the contributions</Box></TableCell>
                                    <TableCell><Box color="secondary.main">Was this stakeholder…?</Box></TableCell>
                                    <TableCell><Box color="secondary.main">When this stakeholder was joined to the process?</Box></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    action['organizations'] ? action['organizations'].map((elem, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell>{elem['Name of the organisation']}</TableCell>
                                                <TableCell>{elem['stcode']}</TableCell>
                                                <TableCell>{elem['initiating actor?']}</TableCell>

                                                {getOrganizationCells(elem['stcode'])}

                                            </TableRow>
                                        ))
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </Grid>


                    <Grid item xs={12}>
                        <Title>KEYWORDS:</Title>
                        <List>{action['KEYWORDS'] ? <TableList list={action['KEYWORDS']}/> : null}</List>
                    </Grid>

                </Grid>

            </div>
        )
    }

    function getOrganizationInfo (stcode) {
        let orgInfo = {}
        for (let i = 0; i < db.stakeholders.length; i++) {
            let org = db.stakeholders[i]
            if (org.stcode === stcode) {
                orgInfo = org
                break
            }
        }
        return orgInfo
    }

    function getOrganizationCells (stcode) {

        let org = getOrganizationInfo(stcode)

        // put info into cells
        let tableCells = []
        tableCells.push(<TableCell key={'Type'}>{org['Type (please, select)']}</TableCell>)
        tableCells.push(<TableCell key={'Subtype'}>{org['Subtype (please, select)']}</TableCell>)
        tableCells.push(<TableCell
            key={'Role'}>{org['Role (please describe briefly the role of the entity in the rural regeneration of your experience)']}</TableCell>)
        tableCells.push(<TableCell
            key={'contribution'}>{org['Which was their contribution to the process? (please select)']}</TableCell>)
        tableCells.push(<TableCell
            key={'Describe'}>{org['Describe the contributions (please describe briefly the main contributions by the entity in the process)']}</TableCell>)
        tableCells.push(<TableCell
            key={'stakeholder'}>{org['Was this stakeholder…? (select from the options)']}</TableCell>)
        tableCells.push(<TableCell key={'joined'}>{org['When this stakeholder was joined to the process?']}</TableCell>)

        return tableCells
    }

    function getRMAction (id) {

        let action = {}

        // Go through all RMA
        for (let i = 0; i < db.rma.length; i++) {
            let rma = db.rma[i]

            // Find the RMA that has the some ID
            if (rma['CODE RM ACTION'] === id) {
                action = { ...rma } // clone content of action object

                // Go through all RMA Stakeholders
                for (let k = 0; k < db.rmas.length; k++) {
                    let rmas = db.rmas[k]

                    // find the RMA that has the some reference as the RMA Stakeholder
                    if (rma['CODE RM ACTION'].toUpperCase() === rmas['Related RMA'].toUpperCase()) {
                        action = { ...action, ...rmas } // add stakeholder info to the action object
                        break
                    }
                }
                break // rma gets the correct object
            }
        }

        return action
    }

    let action = getRMAction(actionId)

    // If action object is not empty then show data otherwise show data not found
    return (Object.entries(action).length === 0 && action.constructor === Object) ? dataNotFound() : dataInfo(action)

}
