import React, { Fragment, useState } from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
// import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { ListItemIcon } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import ListItemText from '@material-ui/core/ListItemText'
import StorageIcon from '@material-ui/icons/Storage'
import ShortTextIcon from '@material-ui/icons/ShortText'
// import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import { createBrowserHistory } from 'history'
import { makeStyles } from '@material-ui/core/styles'

import Home from './pages/Home'
import ExploreData from './pages/ExploreData'
import Wizard from './pages/Wizard'
// import RmPanel from './RmPanel'
// import ExtraResources from './pages/ExtraResources'

import Datalinks from './Datalinks'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";

const drawerWidth = 240
const history = createBrowserHistory()

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    flex: {
        flex: 1
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    toolbarMargin: theme.mixins.toolbar,
    aboveDrawer: {
        zIndex: theme.zIndex.drawer + 1
    }
}))

const MyToolbar = (({ title, onMenuClick }) => {
    const classes = useStyles()
    return (
        <Fragment>
            <AppBar>
                <Toolbar variant="dense">
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={onMenuClick}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.flex}>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.toolbarMargin}/>
        </Fragment>
    )
})

// function Rmodels (){
//     // We can use the `useParams` hook here to access
//     // the dynamic pieces of the URL.
//     let { id } = useParams();
//
//     return (
//         <div>
//             <h3>ID: {id}</h3>
//         </div>
//     );
//
// }

function MyDrawer (props)  {
    const { variant, open, onClose, onItemClick } = props
    const classes = useStyles()

    return (
        <Router history={history}>
            <Drawer
                variant={variant}
                open={open}
                onClose={onClose}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <List>

                    <ListItem
                        button
                        component={Link}
                        to="/"
                        onClick={onItemClick('Home')}
                    >
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText>Home</ListItemText>
                    </ListItem>

                    <ListItem
                        button
                        component={Link}
                        to="/exploredata"
                        onClick={onItemClick('Decision Support System')}
                    >
                        <ListItemIcon><StorageIcon/></ListItemIcon>
                        <ListItemText>Explore Data</ListItemText>
                    </ListItem>

                    <ListItem
                        button
                        component={Link}
                        to="/wizard"
                        onClick={onItemClick('Wizard')}
                    >
                        <ListItemIcon><ShortTextIcon/></ListItemIcon>
                        <ListItemText>Wizard</ListItemText>
                    </ListItem>

                    {/*<ListItem*/}
                    {/*    button*/}
                    {/*    component={Link}*/}
                    {/*    to="/extraresources"*/}
                    {/*    onClick={onItemClick('Extra Resources')}*/}
                    {/*>*/}
                    {/*    <ListItemIcon><LocalLibraryIcon/></ListItemIcon>*/}
                    {/*    <ListItemText>Extra Resources</ListItemText>*/}
                    {/*</ListItem>*/}

                </List>
            </Drawer>

            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/exploredata">
                    <ExploreData/>
                </Route>
                <Route exact path="/wizard">
                    <Wizard/>
                </Route>
                {/*<Route path="/rmlist/:rmId">*/}
                {/*    <Datalinks/>*/}
                {/*    /!*<Rmodels/>*!/*/}

                {/*    /!*<RmPanel db={db} rm={rmDialog}/>*!/*/}
                {/*</Route>*/}
                <Route path="/links/:listId/:elemId">
                    <Datalinks/>
                </Route>
                {/*<Route exact path="/extraresources">*/}
                {/*    <ExtraResources/>*/}
                {/*</Route>*/}
            </Switch>

        </Router>
    )
}

export default function Body (props) {

    const { db } = props

    const classes = useStyles()

    const [drawer, setDrawer] = useState(false)
    const [title, setTitle] = useState('Home')

    let variant = 'temporary' // 'permanent' | 'persistent' | 'temporary'

    const toggleDrawer = () => {
        setDrawer(!drawer)
    }

    const onItemClick = title => () => {
        setTitle(title)
        setDrawer(variant === 'temporary' ? false : drawer)
        setDrawer(!drawer)
    }

    return (
        <div className={classes.root}>
            <MyToolbar title={title} onMenuClick={toggleDrawer}/>
            <MyDrawer
                db={db}
                open={drawer}
                onClose={toggleDrawer}
                onItemClick={onItemClick}
                variant={variant}
            />
        </div>
    )
}
