import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Box } from '@material-ui/core'

// const useStyles = makeStyles(theme => ({
//     root: {
//         width: '100%',
//         marginTop: theme.spacing(3),
//         overflowX: 'auto'
//     },
//     table: {
//         minWidth: 650
//     }
// }))

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(0),
        overflowX: 'auto'
    }
}))

export default function TableCapitalTransferenceMechanism (props) {

    const classes = useStyles()
    let { ctm, mainColor } = props
    if (typeof ctm === 'undefined') {
        console.log('WARNING!! CapitalTransferenceMechanism undefined!')
        ctm=[]
    }
    // Define the primary color for all page
    let primaryColor = mainColor
    if( typeof mainColor === 'undefined'){
        primaryColor = "secondary"
    }

    return(
        <Box className={classes.root}>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><Box color={primaryColor}>CAPITALS</Box></TableCell>
                        <TableCell><Box color={primaryColor}>RELEVANCE</Box></TableCell>
                        <TableCell><Box color={primaryColor}>INITIAL</Box></TableCell>
                        <TableCell><Box color={primaryColor}>DEVELOPED</Box></TableCell>
                        <TableCell align="left"><Box color={primaryColor}>OBTAINED</Box></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        ctm.map(elem => (
                            <TableRow hover key={elem.CAPITALS}>
                                <TableCell>{elem.CAPITALS}</TableCell>
                                <TableCell align="left">{elem.RELEVANCE}</TableCell>
                                <TableCell align="left">{elem.INITIAL}</TableCell>
                                <TableCell align="left">{elem.DEVELOPED}</TableCell>
                                <TableCell align="left">{elem.OBTAINED}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    )
}