import React, { Component } from 'react'

import vis from 'vis'

class ProcessPanel extends Component {
    constructor (props) {
        super(props)
        this.updateGraph = this.updateGraph.bind(this)
        this.myRef = React.createRef()
        //let elem = props.rm
    }

    componentDidMount () {
        const { rm, stack } = this.props
        this.updateGraph(rm, stack)
    }

    // componentDidUpdate () {
    //     this.updateGraph(this.props.rm)
    // }

    render () {
        return (
            <div style={{ width: '100%' }}>
                {/*<div id="vis-timeline"></div>*/}
                <div ref={this.myRef}></div>
            </div>
        )
    }


    updateGraph (elem) {

        // var container = document.getElementById('vis-timeline')
        var container = this.myRef.current

        // Options
        var options = {
            editable: false,
            clickToUse: true,
            stack: true,
            tooltip: {
                followMouse: true,
                overflowMethod: 'flip'
            }
        }

        // Get array of information about the element
        var graphElemInfo = this.getGraphElementInfo(elem)

        // Create a dataset
        var items = new vis.DataSet({
            type: { start: 'ISODate', end: 'ISODate' }
        })

        // If element has information to show
        if (graphElemInfo.length > 0) {

            for (var i = 0; i < graphElemInfo.length; i++) {

                var startDate = new Date(graphElemInfo[i].startDate),
                    endDate = new Date(graphElemInfo[i].endDate),
                    tooltip = graphElemInfo[i].htmlTooltip,
                    content = graphElemInfo[i].content

                // Add info to items
                items.add(
                    {
                        id: i,
                        content: content,
                        start: startDate,
                        end: endDate,
                        title: tooltip
                    })
            }
        } else {
            console.log('----- No information to show process on rm: ' + elem.RM)
        }

        new vis.Timeline(container, items, options)
    }

    getGraphElementInfo (elem) {

        // ---- Check Date formats ----
        var proc = elem.Practices.PROCESS

        var infoElemArray = [] // Array to hold all elements info
        var yearString // process year as a string

        for (var i = 0; i < proc.length; i++) {
            yearString = proc[i].YEAR // get the year

            // Check if year is defined
            if (typeof yearString === 'undefined') {
                console.log('Date undefined')
                continue // go to next date
            }

            var yearObj = this.checkYearFormat(yearString)

            // If flag changed (means it found a year format)
            if (yearObj.flag) {
                // Append date to array
                infoElemArray.push(
                    {
                        'startDate': yearObj.startDate,
                        'endDate': yearObj.endDate,
                        'content': proc[i]['CONCEPTUAL STEP'],
                        'htmlTooltip': this.getTooltip(proc[i])
                    })
            } else {
                // If string does not match with Date formats
                //console.log("Date unreadable: ", yearString);
                continue // Go to next date
            }

        }

        return infoElemArray
    }

    checkYearFormat (yearString) {

        var startDate, endDate,  // variables to hold start and end dates of a single process
            flagChanged = false // Flag to sign if yearString was recognized with a right format

        // For years like "1989"
        if ((yearString.length == 4) && Number.isInteger(Number(yearString))) {
            startDate = yearString
            endDate = (parseInt(yearString) + 1).toString() // full year
            flagChanged = true
        }

        // For years like "1960-2010"
        if ((yearString.length == 9)) {

            var idx = yearString.indexOf('-')
            if (idx == 4) {
                var firstPar = yearString.slice(0, idx)
                var secondPar = yearString.slice(idx + 1)

                if (
                    (firstPar.length == 4) &&
                    (secondPar.length == 4) &&
                    (Number.isInteger(Number(firstPar))) &&
                    (Number.isInteger(Number(secondPar)))) {
                    startDate = firstPar
                    endDate = secondPar
                    flagChanged = true
                }
            }
        }

        return {
            'startDate': startDate,
            'endDate': endDate,
            'flag': flagChanged
        }

    }

    getTooltip (process) {

        var tooltip = '' +
            //"<div style='max-width: 200px'>" +
            '<p>' +
            //"BARRIERS: " + process["BARRIERS"] + "<br>" +
            'CONCEPTUAL STEP: ' + process['CONCEPTUAL STEP'] + '<br>' +
            'YEAR: ' + process['YEAR'] + '<br>' +
            //"CO-BENEFITS: " + process["CO-BENEFITS"] + "<br>" +
            'MILESTONE: ' + process['MILESTONE'] + '' +
            '</p>'
        //"</div>"

        return tooltip
    }
}

export default ProcessPanel
