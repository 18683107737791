import React from 'react'
import Grid from '@material-ui/core/Grid'
import BasePaper from './BasePaper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { theme } from '../theme/theme'
import TableList from './TableList'
import RMAList from './RMAList'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'


function Title (props) {
    return (
        <Typography variant="h6">
            <Box color="secondary.main" letterSpacing={2} fontSize="htmlFontSize"
                 fontWeight="fontWeightMedium" borderBottom={1}>
                {props.children}
            </Box>
        </Typography>
    )
}

function tableCapitalTransferenceMechanism (list) {

    if (typeof list === 'undefined') {
        console.log('WARNING!! CapitalTransferenceMechanism undefined!')
        list = []
    }

    return (
        <Box
            // boxShadow={theme.boxShadow.GREEN}
            border={1}
            borderColor={theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN}>
            <Table size="small">
                <TableHead>
                    <TableRow p={0} m={0}>
                        <TableCell><Box color="primary.main">RELATED CAPITAL</Box></TableCell>
                        <TableCell><Box color="primary.main">INITIAL</Box></TableCell>
                        <TableCell><Box color="primary.main">DEVELOPED</Box></TableCell>
                        <TableCell><Box color="primary.main">OBTAINED</Box></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        list.map(elem => (
                            <TableRow hover key={elem['RELATED CAPITAL']}>
                                <TableCell>{elem['RELATED CAPITAL']}</TableCell>
                                <TableCell align="left">{elem.INITIAL}</TableCell>
                                <TableCell align="left">{elem.DEVELOPED}</TableCell>
                                <TableCell align="left">{elem.OBTAINED}</TableCell>
                            </TableRow>
                        ))
                    }

                </TableBody>
            </Table>
        </Box>
    )
}

export default function LLContent(props) {

    const { db, lesson } = props

    return(
        <Box mt={1}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <BasePaper>

                        <Title>DESCRIPTION</Title>
                        <Typography component='div' variant="body2">
                            <Box pt={1}>{lesson['LESSONS LEARNT']}</Box>
                        </Typography>

                        <Box display="flex" justifyContent="flex-end">
                            <Box color={theme.palette.secondary.main}>
                                <Typography component="legend" variant="caption">REPLICABILITY:</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            {/*<Rating name="customized-empty" readOnly value={this.labels[lesson['REPLICABILITY']]} precision={1} emptyIcon={<StarBorderIcon fontSize="inherit"/>}/>*/}
                            <Box ml={2}>{lesson['REPLICABILITY']}</Box>
                        </Box>

                    </BasePaper>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <BasePaper>
                        <Title>MAIN RELATED CROSSCUTTING</Title>
                        <TableList list={lesson['MAIN RELATED CROSSCUTTING']}/>
                        <Box my={2}></Box>
                        <Title>OTHER RELATED CROSSCUTTING</Title>
                        <TableList list={lesson['OTHER RELATED CROSSCUTTING']}/>
                    </BasePaper>
                </Grid>

                <Grid item xs={12}>
                    <BasePaper>
                        <Title>CAPITAL TRANSFERENCE MECHANISM</Title>
                        <Typography component='div' variant="body2">
                            <Box pt={1} pb={3}>
                                {lesson['CAPITAL TRANSFERENCE MECHANISM'].DESCRIPTION}
                            </Box>
                        </Typography>
                        {tableCapitalTransferenceMechanism(lesson['CAPITAL TRANSFERENCE MECHANISM'].List)}
                    </BasePaper>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <BasePaper>
                        <Title>ACHIEVEMENTS</Title>
                        <TableList list={lesson['ACHIVEMENTS']}/>
                    </BasePaper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <BasePaper>
                        <Title>REQUIRED INITIAL CONDITIONS / REPLICABILITY CONDITIONS</Title>
                        <TableList list={lesson['REQUIRED INITIALCONDITIONS/ REPLICABILITY CONDITIONS']}/>
                    </BasePaper>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <BasePaper>
                        <Title>KEY ELEMENTS</Title>
                        <TableList list={lesson['KEY ELEMENTS']}/>
                    </BasePaper>
                </Grid>

                <Grid item xs={12}>
                    <BasePaper>
                        <Title>CODE RM ACTION</Title>
                        <RMAList db={db} rmaList={lesson['CODE RM ACTION']}/>

                    </BasePaper>
                </Grid>

                <Grid item xs={12}>
                    <BasePaper>
                        <Title>KEYWORDS</Title>
                        <TableList list={lesson['KEYWORDS']}/>
                    </BasePaper>
                </Grid>

            </Grid>

        </Box>
    )
}