import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        overflowX: 'auto'
    }
    // ,
    // table: {
    //     minWidth: 650
    // }
}))

export default function TableList (props) {

    const classes = useStyles()
    let { list } = props

    if (typeof list === 'undefined') {
        console.log('WARNING!! list undefined!')
        list=[]
    }

    return(

            <Table className={classes.table} size="small">
                <TableBody>
                    {
                        list.map( (elem, idx) => (
                            <TableRow hover key={elem + idx}>
                                <TableCell align="left">{elem}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>


    )
}