import React from 'react'

import { Box, Button, Divider } from '@material-ui/core'
import { ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Dialog from '@material-ui/core/Dialog'
import RMAInfo from './RMAInfo'
import RmPanel from './RmPanel'
import DialogInfo from './DialogInfo'

export default function RMAList (props) {

    const { db, rmaList } = props

    const [dialogRmaOpen, setDialogRmaOpen] = React.useState(false)
    const [dialogID, setDialogId] = React.useState(false)

    const [dialogRmOpen, setDialogRmOpen] = React.useState(false)
    const [dialogRmID, setDialogRmID] = React.useState(false)

    function handleRmaOpen (id) {
        setDialogId(id)
        setDialogRmaOpen(true)
    }

    function handleRmaClose () {
        setDialogRmaOpen(false)
    }

    function handleDialogRmOpen (rmId) {
        setDialogRmID(rmId)
        setDialogRmOpen(true)
    }

    function handleDialogRmClose () {
        setDialogRmOpen(false)
    }

    function findRmFromRmaId (rmaId) {

        let rm // rm to be sent back

        // get full rma
        let rma = db.rma.find(rma => rma['CODE RM ACTION'].trim() === rmaId.trim()) //TODO: If we have a good DB we can take out those trims (Ugly fix for now)

        // find rm that contains the rma
        for (let i = 0; i < db.rm.length; i++) {
            let rmElem = db.rm[i] // get rm element to be searched

            // if property exist
            if (typeof rmElem['ModelActions'] !== 'undefined') {

                // for each rm modelAction description find if there is one that matches the rma action description (there
                // is no id here to compare unfortunatly)
                let rmMA = rmElem['ModelActions'].find(elem => elem['description'] === rma['NAME OF THE RM ACTION'])

                // if found then return the rm object else go to next rm in the list
                if (typeof rmMA !== 'undefined') {
                    rm = rmElem
                    break
                }
            }

        }

        return rm
    }

    function getRMADescription (rmaID) {

        let description = rmaID // set initial description as the ID

        // Find rma
        let rma = db.rma.find(a => a['CODE RM ACTION'] === rmaID.trim()) // TODO: ("RM20-2 ") This trim() wouldn't be necessary if data was clean (dirty fix for now...)

        // if rma exist then take the description
        if ('NAME OF THE RM ACTION' in rma) { description = rma['NAME OF THE RM ACTION']}

        return description
    }

    function rmButton (rmaId) {

        let rm = findRmFromRmaId(rmaId)

        return (
            <React.Fragment>
                {
                    rm ?
                        <Button
                            onClick={() => handleDialogRmOpen(rm)}
                            variant="outlined" size="small" fullWidth
                        >
                            {rm['RM']}
                        </Button>
                        :
                        <Button disabled variant="outlined" size="small" fullWidth>--</Button>
                }
            </React.Fragment>
        )

    }

    return (

        <Box>
            {
                (rmaList.length > 0) &&

                <Box p={1} height={'100%'} maxHeight={450} display="flex" flexDirection={'column'}>

                    <Box flexGrow={1} overflow="auto">

                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={90}>RMA ID</TableCell>
                                    <TableCell align="left">RMA DESCRIPTION</TableCell>
                                    <TableCell>RM</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rmaList.map((rma, idx) => (
                                        <TableRow key={rma + idx} hover>

                                            <TableCell>{rma}</TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => handleRmaOpen(rma)}
                                                    style={{ justifyContent: 'flex-start', textAlign: 'left' }}
                                                    variant="outlined" size="small"
                                                    fullWidth
                                                >
                                                    {getRMADescription(rma)}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="left">
                                                {rmButton(rma)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Box>

                </Box>
            }

            {/*Dialog for RMA*/}
            <Dialog
                open={dialogRmaOpen}
                onClose={handleRmaClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogContent>
                    <RMAInfo db={db} actionId={dialogID}/>
                </DialogContent>
            </Dialog>

            {/*Dialog for RM*/}
            <DialogInfo dialogOpen={dialogRmOpen}
                        title={dialogRmID['Name']}
                        handleClose={() => handleDialogRmClose()}
            >
                <RmPanel db={db} rm={dialogRmID}/>
            </DialogInfo>

        </Box>

    )
}

