import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import WizardQuestion from './wizardStepsUtilities/WizardQuestion'

export default function ChallengesStep (props) {

    const { wizardSelections, handleOnChanges } = props

    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <WizardQuestion>
                    In RURITAGE, the geographical and economic context is analysed to frame the challenges that the
                    Role Models faces. Which of those challenges are also present in your project?
                </WizardQuestion>
            </Grid>

            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormGroup>
                        {Object.keys(wizardSelections.challenges).map(challenge => {
                            return (
                                <FormControlLabel
                                    key={challenge}
                                    control={
                                        <Checkbox checked={wizardSelections.challenges[challenge]}
                                                  onChange={handleOnChanges.challenges}
                                                  name={challenge}
                                                  color="primary"/>
                                    }
                                    label={<Typography variant={'overline'}>{challenge}</Typography>}
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )

}