import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import RMAInfo from './RMAInfo'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ReplicatorActionInfo from './ReplicatorActionInfo'
import RMAEnhancementInfo from './RMAEnhancementInfo'

function Title (props) {
    return (
        <Typography variant="h6">
            <Box color={props.mainColor} letterSpacing={2} fontSize="htmlFontSize"
                 fontWeight="fontWeightMedium" borderBottom={0}>
                {props.children}
            </Box>
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto'
    },
    button: {
        color: props => props.mainColor,
        borderColor: props => props.mainColor,
        justifyContent: "flex-start",
        textAlign:'left'
    }
}))

function MyButton(props) {
    const { mainColor, ...other } = props;
    const classes = useStyles(props);
    return <Button variant="outlined" size="small" className={classes.button} {...other} />;
}

export default function TableActionsEnhancements (props) {

    const classes = useStyles()
    const { db, rmaEnhancements, mainColor } = props

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogID, setDialogId] = React.useState(false);

    function handleOpen(actionId){
        setDialogId(actionId)
        setDialogOpen(true)
    }
    function handleClose(){
        setDialogOpen(false)
    }


    return (
        <React.Fragment>

            <Title mainColor={mainColor}>ENHANCEMENT ACTIONS</Title>

            <Box className={classes.root}>

                <Table size="small">
                    {/*<TableHead>*/}
                    {/*    <TableRow>*/}
                    {/*        <TableCell align="left"><Box color={primaryColor}>DESCRIPTION</Box></TableCell>*/}
                    {/*    </TableRow>*/}
                    {/*</TableHead>*/}
                    <TableBody>
                        {
                            rmaEnhancements.actions.map(row => (
                                <TableRow key={row.action_id} hover>
                                    <TableCell align="left" >
                                        <MyButton
                                            mainColor={mainColor}
                                            onClick={()=>handleOpen(row.action_id)}
                                            >
                                            {row.action_name}
                                        </MyButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>

                <Dialog
                    open={dialogOpen}
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogContent>
                        <RMAEnhancementInfo db={db} actionId={dialogID}/>
                    </DialogContent>
                </Dialog>
            </Box>


        </React.Fragment>

    )
}