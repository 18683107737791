import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

export default function LLCounter(props) {

    const {llList} = props

    return(
        <Box>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>
                        LESSONS LEARNT
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={'h3'}>
                        {llList.length}
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    )

}