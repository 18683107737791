import React from 'react'
import Box from '@material-ui/core/Box'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from '@material-ui/core'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import LinkIcon from '@material-ui/icons/Link'

function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: '100%', height: '100%', overflow: 'auto' }}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps (index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 250,
        width: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    content: {
        backgroundColor: theme.palette.background.paper,
    }
}))

function ListItemLink (props) {
    return <ListItem button divider component={Link} target="_blank" rel="noreferrer" {...props}/>
}

export default function DRHHRelatedDiscussions (props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)


    const {drhhInfo} = props

    // If no data
    if(Object.keys(drhhInfo).length === 0 && drhhInfo.constructor === Object){
        return (
            <Box>No Data Available</Box>
        )
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    // Prepare tabs and tabPlanels
    var tabsList = []
    var tabPanelsList = []
    var keys = Object.keys(drhhInfo).sort()
    keys.forEach((k, i) => {

        // Create tabs
        tabsList.push(<Tab key={k} label={k} {...a11yProps(i)} />)

        // Create tabPlanels
        tabPanelsList.push(
            <TabPanel key={k} value={value} index={i}>
                <div className={classes.content}>
                    <List component="nav" aria-label="links to DRHH" dense>
                        {
                            drhhInfo[k].map(elemList => (
                                <ListItemLink key={elemList.link} href={elemList.link}>
                                    <ListItemIcon><LinkIcon/></ListItemIcon>
                                    <ListItemText primary={elemList.content}/>
                                </ListItemLink>
                            ))
                        }
                    </List>
                </div>
            </TabPanel>
        )
    })

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="DRHH Discussions"
                className={classes.tabs}
            >
                {tabsList.map(elem => elem)}
            </Tabs>
            {tabPanelsList.map(elem => elem)}
        </div>
    )
}
