import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export default function Footer () {
    return (
        <Box display="flex" justifyContent="center" bgcolor="primary.dark">
            <Typography variant="overline" >
                <Box color="background.paper">Copyright © Ruritage {new Date().getFullYear()}</Box>
            </Typography>
        </Box>
    )
}
