import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { theme } from '../../theme/theme'

import EcoIcon from '@material-ui/icons/Eco'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import RmsList from './RmsList'
import GoalsList from './GoalsList'
import CrossCuttingPanel from './reportPanels/CrossCuttingPanel'
import DriversPanel from './reportPanels/DriversPanel'
import ChallengesPanel from './reportPanels/ChallengesPanel'
import SiasPanel from './reportPanels/SiasPanel'
import OurSelectionPanel from './reportPanels/OurSelectionPanel'
import SizePanel from './reportPanels/SizePanel'

function ReportTitle () {
    return (
        <React.Fragment>
            <Typography component={'div'}>
                <Box my={1} textAlign="center" fontWeight="fontWeightBold" fontSize="h6.fontSize" fontFamily="Monospace"
                     letterSpacing={2}>
                    REPORT
                </Box>
            </Typography>
            <Box mt={2}><Divider/></Box>
        </React.Fragment>
    )
}

function ReportSessionTitle (props) {
    return (
        <Typography component={'div'} variant={'h6'} color={'primary'}>
            <Box
                fontWeight={600}
                fontFamily="Monospace"
                letterSpacing={1}
                borderBottom={1}
                mb={1}
            >
                {props.children}
            </Box>
        </Typography>
    )
}

function PanelTitle (props) {
    return (
        <Typography component={'span'} color={'secondary'}>
            <Box fontSize={18} component={'span'} fontWeight="fontWeightBold" /*borderBottom={1}*/>
                {props.children.toUpperCase()}
            </Box>
            {/*<Divider/>*/}
        </Typography>
    )
}

function PanelSubtitle (props) {
    return (
        <Typography variant={'body1'} component={'span'} /*color={'secondary'}*/>
            <Box component={'span'} my={2} fontFamily="Monospace" fontWeight="fontWeightMedium">
                {props.children.toUpperCase()}
            </Box>
        </Typography>
    )
}

function CellInfo (props) {
    return (
        <Box p={0} mb={1} boxShadow={6}>{props.children}</Box>
    )
}

export default function ReportInfo (props) {

    const { db, wizardSelections, handleGoToWizard } = props

    return (

        <Grid container spacing={4}>

            {/*Report Title*/}
            <Grid item xs={12}>
                <ReportTitle/>
            </Grid>

            <Grid item xs={12}>

                <ReportSessionTitle>
                    WELCOME
                </ReportSessionTitle>

                <Typography variant={'body2'}>
                    We have prepared this report according to your answers. From our valuable RURITAGE data, we try
                    bringing relevant information and tips that might help you get better ideas of best practices to be
                    implemented on your project. In this report you can explore data like Role Models, Lessons Learnt
                    and Role Models Actions. Statistics of collections are also provided to help you better understand
                    the data and in some cases you might even explore further from there.
                </Typography>
            </Grid>


            <Grid item xs={12} md={12}>

                <ReportSessionTitle>
                    RESULTS
                </ReportSessionTitle>

                <Typography component={'div'} variant={'body2'}>
                    <Box mb={2}>
                        Through a process of internal filtration and modulation of the DSS data, here we present our
                        selections and recommendations that we think that are more aligned with your project. By open
                        and exploring those selections you might find useful information for your project.
                    </Box>
                </Typography>

                <CellInfo>

                    <Box display='flex' alignItems="center" pl={3} pt={3}>
                        <EcoIcon/> <PanelTitle>OUR SELECTIONS</PanelTitle>
                    </Box>

                    <OurSelectionPanel db={db} wizardSelections={wizardSelections}/>

                </CellInfo>

            </Grid>

            <Grid item xs={12}>

                <ReportSessionTitle>
                    SPECIALIZED RESULTS
                </ReportSessionTitle>

                <Typography variant={'body2'}>
                    Due to the complexity that projects can grow to, we have created this session where you can take a
                    look on multiple selections based on direct matches of your inputs. From here you can have a more
                    directional search for specific fields, allowing this way the possibility to find more refined
                    solutions. For each selection we have also provided analysis to help you understand the insights of
                    it.
                </Typography>

            </Grid>

            {/*Based on your Crosscutting selection, those are the related Lessons Learnt*/}
            <Grid item xs={12} md={12}>

                {/*CROSSCUTTING*/}
                <CellInfo>
                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography component={'div'}>
                                <Box display='flex' alignItems="center" mb={1}>
                                    <EcoIcon/> <PanelTitle>CROSSCUTTING</PanelTitle>
                                </Box>
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box width='100%'>
                                <CrossCuttingPanel
                                    db={db}
                                    crosscuttingsStates={wizardSelections.crosscuttings}
                                />
                            </Box>
                        </AccordionDetails>

                    </Accordion>
                </CellInfo>

                {/*Challenges*/}
                <CellInfo>
                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography component={'div'}>
                                <Box display='flex' alignItems="center">
                                    <EcoIcon/> <PanelTitle>Challenges</PanelTitle>
                                </Box>
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box width='100%'>
                                <ChallengesPanel db={db} challengesStates={wizardSelections.challenges}/>
                            </Box>
                        </AccordionDetails>

                    </Accordion>
                </CellInfo>

                {/*DRIVERS*/}
                <CellInfo>
                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography component={'div'}>
                                <Box display='flex' alignItems="center">
                                    <EcoIcon/> <PanelTitle>DRIVERS</PanelTitle>
                                </Box>
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box width='100%'>
                                <DriversPanel db={db} driversStates={wizardSelections.drivers}/>
                            </Box>
                        </AccordionDetails>

                    </Accordion>
                </CellInfo>

                {/*SIAs*/}
                <CellInfo>
                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography component={'div'}>
                                <Box display='flex' alignItems="center">
                                    <EcoIcon/> <PanelTitle>Systemic Innovation Areas (SIA's)</PanelTitle>
                                </Box>
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box width='100%'>
                                <SiasPanel db={db} siasStates={wizardSelections.sias}/>
                            </Box>
                        </AccordionDetails>

                    </Accordion>
                </CellInfo>

                {/*Size*/}
                <CellInfo>
                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography component={'div'}>
                                <Box display='flex' alignItems="center">
                                    <EcoIcon/> <PanelTitle>Size</PanelTitle>
                                </Box>
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box width='100%'>
                                <SizePanel db={db} sizeState={wizardSelections.size}/>
                            </Box>
                        </AccordionDetails>

                    </Accordion>
                </CellInfo>

            </Grid>

            {/*Buttons control*/}
            <Grid item xs={12}>
                <Box mt={3}>
                    <Button variant={'outlined'} color={'primary'} onClick={handleGoToWizard}>
                        Back to Wizard
                    </Button>
                </Box>
            </Grid>


        </Grid>

    )
}