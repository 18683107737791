import React from 'react'
import LLList from '../LLList'
import { Box, Button } from '@material-ui/core'
import LLAnalyzes from '../LLAnalyzes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'

import { withStyles } from '@material-ui/core/styles'

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    // indicator: {
    //     backgroundColor: '#1890ff',
    // },
})(Tabs)

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&$selected': {
            color: 'primary',
            fontWeight: theme.typography.fontWeightMedium,
            outline: 'none'
        },
        '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
        },
        '&:focus': {
            color: 'primary',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />)

function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function filterCC (ll, cc) {

    let list = []

    ll.forEach(lesson => {

        if (
            lesson['MAIN RELATED CROSSCUTTING'].includes(cc) ||
            lesson['OTHER RELATED CROSSCUTTING'].includes(cc)
        ) {
            list.push(lesson)
        }

    })

    return list
}

export default function CrossCuttingPanel (props) {

    const { db, crosscuttingsStates } = props

    const [analyzesOpen, setAnalyzesOpen] = React.useState(false) // TODO: make it false when dev is done
    const handleAnalyzesOpenChange = (event) => {
        setAnalyzesOpen(() => !analyzesOpen)
    }
    const [value, setValue] = React.useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    // Get all active crosscuttings in an array
    let ccActiveList = Object.keys(crosscuttingsStates).filter(cc => crosscuttingsStates[cc] === true)

    let ccModules = {
        // cc1: list1,
        // cc2: list2,
    }

    ccActiveList.forEach(activeCC => {
        ccModules[activeCC] = filterCC(db.ll, activeCC)
    })

    return (
        <React.Fragment>

            <AntTabs
                value={value}
                onChange={handleChange}

                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                style={{ borderBottom: '1px solid #e8e8e8' }}
            >
                {Object.keys(ccModules).map((cc, idx, arr) =>
                    (
                        <AntTab key={cc} label={cc.toUpperCase()}/>
                    )
                )}
            </AntTabs>

            <Box borderTop={0} borderColor="grey.500">

                {Object.keys(ccModules).map((cc, idx, arr) =>
                    <TabPanel key={cc} value={value} index={idx}>
                        {/*list*/}
                        <Collapse in={!analyzesOpen}>
                            <Box>
                                <Typography component="div" variant="overline">
                                    <ArrowRightIcon/><Box component={'span'} fontWeight="fontWeightBold">Lessons
                                    Learnt</Box> related list:
                                </Typography>
                                <LLList db={db} llList={ccModules[cc]} filter={() => true}/>
                            </Box>
                        </Collapse>

                        {/*Analyses*/}
                        <Box mt={1}>
                            <Button
                                onClick={handleAnalyzesOpenChange}
                                color="primary"
                                variant={'contained'} style={{ height: '32px' }} fullWidth>
                                Analysis {analyzesOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </Button>

                            <Collapse in={analyzesOpen}>
                                <Box bgcolor="rgba(245, 245, 245, 0.6)" boxShadow={1} borderColor="secondary.main"
                                     width="100%" p={1}>
                                    <LLAnalyzes db={db} llList={ccModules[cc]}/>
                                </Box>
                            </Collapse>
                        </Box>
                    </TabPanel>
                )}

            </Box>

        </React.Fragment>
    )

}