import React from 'react'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Box } from '@material-ui/core'

import { RURITAGE_SIAS_LOGOS, siasDefinition } from '../../../js/utils'
import WizardQuestion from './wizardStepsUtilities/WizardQuestion'

export default function SiasStep (props) {

    // let { sias, handleSiasChange } = props
    const { wizardSelections, handleOnChanges } = props
    // console.log('wizardSelections: ', wizardSelections)
    return (
        <Grid container spacing={2}>

            {/*Question*/}
            <Grid item xs={12}>
                <WizardQuestion>
                    RURITAGE has initiated six Systemic Innovation Areas, as frameworks to identify unique heritage
                    potential within rural communities. In which of these SIA's does your project best fit?
                </WizardQuestion>
            </Grid>

            {/*Selection*/}
            <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                    <FormGroup>
                        {Object.keys(wizardSelections.sias).map(sia => {
                            return (
                                <FormControlLabel
                                    key={sia}
                                    control={
                                        <Checkbox checked={wizardSelections.sias[sia]}
                                                  onChange={handleOnChanges.sias}
                                                  name={sia}
                                                  color="primary"/>
                                    }
                                    label={<Typography variant={'overline'}>{sia}</Typography>}
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>

            {/*Definitions*/}
            <Grid item xs={12} md={6}>
                <Typography variant={'caption'} component={'div'}>
                    <Box textAlign="right">Systemic Innovation Areas info:</Box>
                </Typography>
                {siasDefinition.map(siaObj => (
                    <Accordion key={siaObj['RELATED SIA']}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >

                            <Box height="100%">
                                <Typography>
                                    <img
                                        src={RURITAGE_SIAS_LOGOS.find(elem => elem['RELATED SIA'] === siaObj['RELATED SIA']).img}
                                        // alt={getLogoInfo(elem).alt}
                                        style={{ width: 30, marginRight: 10 }}
                                    />
                                    {siaObj['SIA NAME']}
                                </Typography>
                            </Box>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{siaObj.DEFINITION}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

            </Grid>
        </Grid>
    )
}