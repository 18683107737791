import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'


import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Typography from '@material-ui/core/Typography'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Chart from 'react-apexcharts'

import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import ZoomInIcon from '@material-ui/icons/ZoomIn';

import LessonLearntInfo from '../LessonLearntInfo'
import DialogInfo from '../DialogInfo'
import IconButton from '@material-ui/core/IconButton'
import { theme } from '../../theme/theme'

const useStyles = makeStyles({
    speedDial: {
        position: 'absolute',
        bottom: '5px', //{theme.spacing(2)},
        right: '5px', //theme.spacing(2),
    }
})

function a11yProps (index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

function CTMTable (props) {
    let { list, handleClickOpen } = props
    return (
        <Table size="small" aria-label="Built table">
            <TableHead>
                <TableRow>
                    <TableCell><Box color="primary.main">LESSON LEARNT</Box></TableCell>
                    <TableCell><Box color="primary.main">INITIAL</Box></TableCell>
                    <TableCell><Box color="primary.main">DEVELOPED</Box></TableCell>
                    <TableCell><Box color="primary.main">OBTAINED</Box></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map(row => (
                    <TableRow key={row.llName} hover>
                        <TableCell component="th" scope="row">
                            <Button variant="outlined" color="primary" size="small" width={''}
                                    onClick={row.llName === '--' ? () => null : () => handleClickOpen(row.llName)}>
                                {row.llName}
                            </Button>

                        </TableCell>
                        <TableCell align="left">{row.initial}</TableCell>
                        <TableCell align="left">{row.developed}</TableCell>
                        <TableCell align="left">{row.obtained}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

function SimpleDialog (props) {
    // const classes = useStyles()
    const { listsCTM, onClose, selectedValue, open, db } = props

    const handleClose = () => {
        onClose(selectedValue)
    }

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [dialogContent, setDialogContent] = React.useState({ title: 'LL01'})

    function handleClickOpen (name) {
        setDialogOpen(true)
        setDialogContent({title: name})
    }

    function handleCloseLLDialog () {
        setDialogOpen(false)
    }

    let rowsCTM = listsCTM//getLLCTM(ll, db)

    return (
        <Dialog onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth
                maxWidth={'lg'}

        >
            <DialogTitle id="simple-dialog-title">LESSONS LEARNT - Capital Transference Mechanism</DialogTitle>
            <DialogContent>

            <DialogContentText>
                 Make Analyses on Capital Transference Mechanisms per category.
            </DialogContentText>

                <AppBar position="static">
                    <Tabs value={value}
                          variant="scrollable"
                          scrollButtons="auto"
                          onChange={handleChange}
                          aria-label="Capital Transference Mechanism Tabs"
                    >
                        <Tab label="BUILT" {...a11yProps(0)} />
                        <Tab label="CULTURAL" {...a11yProps(1)} />
                        <Tab label="FINANCIAL" {...a11yProps(2)} />
                        <Tab label="HUMAN" {...a11yProps(3)} />
                        <Tab label="NATURAL" {...a11yProps(4)} />
                        <Tab label="SOCIAL" {...a11yProps(5)} />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                    <CTMTable list={rowsCTM.BUILT} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CTMTable list={rowsCTM.CULTURAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CTMTable list={rowsCTM.FINANCIAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <CTMTable list={rowsCTM.HUMAN} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <CTMTable list={rowsCTM.NATURAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <CTMTable list={rowsCTM.SOCIAL} handleClickOpen={handleClickOpen}/>
                </TabPanel>


                <DialogInfo
                    dialogOpen={dialogOpen}
                    title={'Lesson learnt ' + dialogContent.title}
                    handleClose={() => handleCloseLLDialog()}
                    // backgroundColor={theme.palette.DSS_Pallet.mainColours.YELLOW}
                >
                    <LessonLearntInfo llContent={dialogContent} db={db}/>
                </DialogInfo>

            </DialogContent>

        </Dialog>
    )
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    // selectedValue: PropTypes.string.isRequired,
}

export default function LLctm (props) {

    let { options, data, listsCTM, ll, db } = props

    const classes = useStyles()

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = (value) => {
        setOpen(false)
    }

    return (
        <React.Fragment>

            <Chart options={options}
                   series={data}
                   type="radar"
                   height="100%" // ("100%"| "auto") // !! If set to 100% then set parent to have a fixed height !!
            />

            <Box width={1} display="flex" justifyContent="flex-end" style={{marginTop:-48}}>
                <IconButton color="primary" size="medium" onClick={handleClickOpen} >
                    <ZoomInIcon />
                </IconButton>
            </Box>

            <SimpleDialog listsCTM={listsCTM} ll={ll} db={db} open={open} onClose={handleClose}/>

        </React.Fragment>
    )
}
