import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        height:'100%',
        padding: theme.spacing(2)
    }
}))

export default function BasePaper (props) {

    const classes = useStyles()

    return (
        <Paper className={classes.root}>
            {props.children}
        </Paper>
    )
}