import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import WizardQuestion from './wizardStepsUtilities/WizardQuestion'

export default function DriversStep (props) {

    const { wizardSelections, handleOnChanges } = props

    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <WizardQuestion>
                    In RURITAGE, drivers that shape the process of the Role Models have been identified. Which of those drivers
                    are more related to your project?
                </WizardQuestion>
            </Grid>

            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormGroup>
                        {Object.keys(wizardSelections.drivers).map(driver => {
                            return (
                                <FormControlLabel
                                    key={driver}
                                    control={
                                        <Checkbox checked={wizardSelections.drivers[driver]}
                                                  onChange={handleOnChanges.drivers}
                                                  name={driver}
                                                  color="primary"/>
                                    }
                                    label={<Typography variant={'overline'}>{driver}</Typography>}
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
            </Grid>

        </Grid>
    )

}