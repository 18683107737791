import React from 'react'
import { Box, Button, Divider } from '@material-ui/core'
import { ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { theme } from '../../../theme/theme'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import Dialog from '@material-ui/core/Dialog'
import RMAInfo from '../../RMAInfo'
import RmPanel from '../../RmPanel'
import DialogInfo from '../../DialogInfo'

function countAllRmaFromLL (llList) {

    let rmaCounters = {}

    // Count all RMA from all LL
    llList.forEach(lesson => {

        lesson['CODE RM ACTION'].forEach(rmaName => {

            if (rmaName in rmaCounters) {
                rmaCounters[rmaName]++
            } else {
                rmaCounters[rmaName] = 1
            }

        })

    })

    return rmaCounters
}

const CustomProgress = styled(ProgressBar)`
  .progress-bar {
    background-color: ${theme.palette.DSS_Pallet.mainColours.GREEN}; 
    // color: ${theme.palette.DSS_Pallet.mainColours.DARK_BROWN}; 
    // text-align: left;
    // font-size: 21px;
  },
  .progress {
    // height: 15px;
    // cursor: pointer;
  }
`

// Used on sorting arrays like: array.sort(compareField('score'))
// It will sort an array of objects according to a specific field name
const compareField = field => (a, b) => {
    if (a[field] < b[field]) { return 1 }
    if (a[field] > b[field]) { return -1 }
    return 0
}

export default function LLInfluences (props) {

    const { db, llList } = props

    const [dialogRmaOpen, setDialogRmaOpen] = React.useState(false)
    const [dialogID, setDialogId] = React.useState(false)

    const [dialogRmOpen, setDialogRmOpen] = React.useState(false)
    const [dialogRmID, setDialogRmID] = React.useState(false)

    function handleRmaOpen (id) {
        setDialogId(id)
        setDialogRmaOpen(true)
    }

    function handleRmaClose () {
        setDialogRmaOpen(false)
    }

    function handleDialogRmOpen (rmId) {
        setDialogRmID(rmId)
        setDialogRmOpen(true)
    }

    function handleDialogRmClose () {
        setDialogRmOpen(false)
    }

    function findRmFromRmaId (rmaId) {

        let rm // rm to be sent back

        // get full rma
        let rma = db.rma.find(rma => rma['CODE RM ACTION'].trim() === rmaId.trim()) //TODO: If we have a good DB we can take out those trims (Ugly fix for now)

        // find rm that contains the rma
        for (let i = 0; i < db.rm.length; i++) {
            let rmElem = db.rm[i] // get rm element to be searched

            // if property exist
            if (typeof rmElem['ModelActions'] !== 'undefined') {

                // for each rm modelAction description find if there is one that matches the rma action description (there
                // is no id here to compare unfortunatly)
                let rmMA = rmElem['ModelActions'].find(elem => elem['description'] === rma['NAME OF THE RM ACTION'])

                // if found then return the rm object else go to next rm in the list
                if (typeof rmMA !== 'undefined') {
                    rm = rmElem
                    break
                }
            }

        }

        return rm
    }

    function getRMADescription (rmaID) {

        let description = rmaID // set initial description as the ID

        // Find rma
        let rma = db.rma.find(a => a['CODE RM ACTION'] === rmaID.trim()) // TODO: ("RM20-2 ") This trim() wouldn't be necessary if data was clean (dirty fix for now...)

        // if rma exist then take the description
        if ('NAME OF THE RM ACTION' in rma) { description = rma['NAME OF THE RM ACTION']}

        return description
    }

    function rmButton (rmaId) {

        let rm = findRmFromRmaId(rmaId)

        return (
            <React.Fragment>
                {
                    rm ?
                        <Button
                            onClick={() => handleDialogRmOpen(rm)}
                            variant="outlined" size="small" fullWidth
                        >
                            {rm['RM']}
                        </Button>
                        :
                        <Button disabled variant="outlined" size="small" fullWidth>--</Button>
                }
            </React.Fragment>
        )

    }

    // Count all RMA
    let rmaCounters = countAllRmaFromLL(llList)

    // Create array with counters
    let rmaArray = []
    Object.keys(rmaCounters).forEach(rma => {
        rmaArray.push({ id: rma, counter: rmaCounters[rma] })
    })

    // Sort rma Array
    rmaArray = rmaArray.sort(compareField('counter'))

    // Get maximum counter
    let maxCounter = rmaArray[0].counter

    return (
        <Box>

            <Box height={450} display="flex" flexDirection={'column'}>

                <Box>
                    {/*<Title>Explore all Role Module Actions</Title>*/}
                    {/*<SubTitle>*/}
                    {/*    Those Role Module Actions (RMA) are sorted on how often they are mentioned and inspired the list of Lessons Learnt (LL).*/}
                    {/*    You can explore the RMA details or take a look on the full Role Module (RM) where it was referred to.*/}
                    {/*</SubTitle>*/}


                    <Typography component="div" variant="caption">
                        <Box textAlign="right" fontStyle="italic">
                            Total RMA: {Object.keys(rmaCounters).length}
                        </Box>
                    </Typography>
                </Box>

                <Box flexGrow={1} overflow="auto">

                    {/*<RMAGroupList db={db} llList={llList}/>*/}

                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={90}>RMA ID</TableCell>
                                <TableCell align="left">RMA DESCRIPTION</TableCell>
                                <TableCell align="center">RM</TableCell>
                                <TableCell align="center">Mentioned In LL</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rmaArray.map(rmaObj => (
                                    <TableRow key={rmaObj.id} hover>

                                        <TableCell>{rmaObj.id}</TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => handleRmaOpen(rmaObj.id)}
                                                style={{ justifyContent: 'flex-start', textAlign: 'left' }}
                                                variant="outlined" size="small"
                                                fullWidth
                                            >
                                                {getRMADescription(rmaObj.id)}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="left">
                                            {rmButton(rmaObj.id)}
                                        </TableCell>
                                        <TableCell>
                                            <CustomProgress
                                                label={rmaObj.counter}
                                                now={100.0 * (rmaObj.counter / maxCounter)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Box>

            </Box>


            {/*Dialog for RMA*/}
            <Dialog
                open={dialogRmaOpen}
                onClose={handleRmaClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogContent>
                    <RMAInfo db={db} actionId={dialogID}/>
                </DialogContent>
            </Dialog>

            {/*Dialog for RM*/}
            <DialogInfo dialogOpen={dialogRmOpen}
                        title={dialogRmID['Name']}
                        handleClose={() => handleDialogRmClose()}
            >
                <RmPanel db={db} rm={dialogRmID}/>
            </DialogInfo>

        </Box>
    )
}

