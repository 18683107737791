import React from 'react'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { CustomProgress, DSSTooltipDescription, DSSTooltipTile, scaleScore } from '../js/utils'
import Divider from '@material-ui/core/Divider'
import DSSHelpIcon from './DSSHelpIcon'
import List from '@material-ui/core/List'
import RmPanel from './RmPanel'
import DialogInfo from './DialogInfo'
import ReplicatorInfo from './ReplicatorInfo'


export default function ReplicatorsList (props) {
    const { db, replicatorsDisplay } = props

    const [selectedReplicator, setSelectedReplicator] = React.useState('undefined')
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [replicatorDialog, setReplicatorDialog] = React.useState({})

    function handleListItemClick (event, elem) {
        setSelectedReplicator(elem['short_description'])    // keeps the highligh of the selected RM
        setReplicatorDialog(elem)                   // gives the data be displayed in dialog window
        setDialogOpen(true)                 // Opens the dialog
    }

    function handleDialogClose () {
        setDialogOpen(false)
    }

    function relevanceTooltip () {
        let title = 'RELEVANCE',
            description = 'Based on your Criteria descriptions we try to find and bring to the top the most relevant ' +
                'content to you. The higher relevance the higher we think it does relate to your search.'
        return (
            <Box m={1}>
                {DSSTooltipTile(title)}
                {DSSTooltipDescription(description)}
            </Box>
        )
    }

    return (
        <React.Fragment>

            <List component="nav">

                <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    {/*NAME*/}
                    <ListItemText
                        primary={
                            <Typography component="div" variant='body2'>
                                <Box color="secondary.main" fontWeight={500} fontSize={12}>NAME</Box>
                            </Typography>
                        }
                    />
                    {/*RELEVANCE*/}
                    {/*<ListItemSecondaryAction>*/}
                    {/*    <Typography component="div" variant='body2'>*/}
                    {/*        <Box display="flex" alignItems="center">*/}
                    {/*            <Box mr={1}>*/}
                    {/*                <DSSHelpIcon tooltipTitle={relevanceTooltip()}/>*/}
                    {/*            </Box>*/}
                    {/*            <Box color="secondary.main" fontWeight={500} fontSize={12}>*/}
                    {/*                RELEVANCE*/}
                    {/*            </Box>*/}
                    {/*        </Box>*/}
                    {/*    </Typography>*/}
                    {/*</ListItemSecondaryAction>*/}

                </ListItem>

                <Divider/>

                {
                    db.replicators.map(elem => (
                            <Collapse key={elem.id + '_key'} in={replicatorsDisplay[elem.id]}>

                                <ListItem
                                    button
                                    // selected={selectedRM === elem.data.RM}
                                    onClick={event => handleListItemClick(event, elem)}
                                >
                                    <ListItemText
                                        // style={{ width: '80%' }}
                                        primary={
                                            <Typography component="div" variant='body2'>
                                                <Box style={{ width: '80%' }}>
                                                    {elem.short_description}
                                                </Box>
                                            </Typography>
                                        }
                                        secondary={
                                            <Box component="span">{elem.names.join(" | ")}</Box>
                                        }
                                    />

                                    {/*<ListItemSecondaryAction style={{ width: '20%' }}>*/}
                                    {/*    <CustomProgress*/}
                                    {/*        style={{ width: '100%' }}*/}
                                    {/*        now={ 100.0}*/}
                                    {/*        // now={scaleScore(minScore, maxScore, elem.score)}*/}
                                    {/*        // label={scaleScore(minScore, maxScore, elem.score) + '%'}*/}
                                    {/*    />*/}
                                    {/*</ListItemSecondaryAction>*/}
                                </ListItem>

                                <Divider/>
                            </Collapse>
                        )
                    )
                }
            </List>

            <DialogInfo dialogOpen={dialogOpen}
                        title={selectedReplicator}
                        handleClose={() => handleDialogClose()}
            >
                <ReplicatorInfo db={db} replicator={replicatorDialog}/>
            </DialogInfo>

        </React.Fragment>

    )

}