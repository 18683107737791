import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Box } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import RMAInfo from './RMAInfo'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ReplicatorActionInfo from './ReplicatorActionInfo'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(0),
        overflowX: 'auto'
    },
    button: {
        color: props => props.mainColor,
        borderColor: props => props.mainColor,
        justifyContent: "flex-start",
        textAlign:'left'
    }
}))

function MyButton(props) {
    const { mainColor, ...other } = props;
    const classes = useStyles(props);
    return <Button variant="outlined" size="small" className={classes.button} {...other} />;
}

export default function TableReplicatorActions(props){
    const classes = useStyles()
    const { db, replicatorActions } = props

    // If modelActions is undefined then pass an empty array
    let actions = replicatorActions
    if (typeof replicatorActions === 'undefined') {
        actions = []
        console.log('WARNING!! modelActions undefined!')
    }

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogID, setDialogId] = React.useState(false);

    function handleOpen(actionId){
        setDialogId(actionId)
        setDialogOpen(true)
    }
    function handleClose(){
        setDialogOpen(false)
    }

    // function findRmaIdByDescription (rmaDescription) {
    //
    //     // Find rma by description
    //     let rma = db.rma.find(a => a["NAME OF THE RM ACTION"] === rmaDescription)
    //
    //     // If RMA wasn't found then pass an non existing RMA name
    //     let id = rma ? rma["CODE RM ACTION"] : RMA_NOT_FOUND
    //
    //     return id
    // }

    return (
        <Box className={classes.root}>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ACTION ID</TableCell>
                        <TableCell align="left">DESCRIPTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        actions.map(row => (
                            <TableRow key={row.action_id} hover>
                                <TableCell>{row.action_id}</TableCell>
                                <TableCell align="left" >
                                    <MyButton
                                        // mainColor={primaryColor}
                                        // disabled={findRmaIdByDescription(row.description) === RMA_NOT_FOUND}
                                        onClick={()=>handleOpen(row.action_id)}
                                        >
                                        {row.action_name}
                                    </MyButton>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogContent>
                    <ReplicatorActionInfo db={db} actionId={dialogID}/>
                </DialogContent>
            </Dialog>
        </Box>

    )
}