import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TableList from './TableList'
import LLList from './wizard/LLList'
import RMAList from './RMAList'
import Box from '@material-ui/core/Box'
import { siasDefinition } from '../js/utils'

function dataNotFound () {
    return <div>Data not found!</div>
}

function Title (props) {
    return (
        <Typography variant="h6">
            <Box color="secondary.main" letterSpacing={2} fontSize="htmlFontSize"
                 fontWeight="fontWeightMedium" borderBottom={1}>
                {props.children}
            </Box>
        </Typography>
    )
}

function getSiaName(siaId){
    let siaElem = siasDefinition.find(elem => elem['RELATED SIA'] === siaId)
    return siaElem['SIA NAME']
}

export default function RMAEnhancementInfo(props){
    const { db, actionId } = props

    function dataInfo(action){

        return (
            <div>
                <Grid container spacing={2}>
                    {/*ACTION ID*/}
                    <Grid item xs={3}>
                        <Title>ACTION ID</Title>
                        <Typography>{action['action_id']}</Typography>
                    </Grid>

                    {/*ACTION NAME*/}
                    <Grid item xs={3}>
                        <Title>ACTION NAME</Title>
                        <Typography>{action['action_name']}</Typography>
                    </Grid>

                    {/*COSTS*/}
                    <Grid item xs={3}>
                        <Title>ACTION COSTS</Title>
                        <Typography>
                            {
                                (action['costs']['indicative_costs'] ?
                                    action['costs']['indicative_costs']
                                    :
                                    '--')
                                + ' ' + action['costs']['currency']
                            }
                        </Typography>
                    </Grid>

                    {/*TIME*/}
                    <Grid item xs={3}>
                        <Title>TIME</Title>
                        <Typography>Started: {action['time']['start_time']}</Typography>
                        <Typography>Ended:   {action['time']['end_time']}</Typography>
                    </Grid>

                    {/*DESCRIPTION*/}
                    <Grid item xs={12}>
                        <Title>DESCRIPTION</Title>
                        <Typography>{action['description']}</Typography>
                    </Grid>

                    {/*OBJECTIVE*/}
                    <Grid item xs={6}>
                        <Title>OBJECTIVE</Title>
                        <Typography>{action['objective']}</Typography>
                    </Grid>

                    {/*RELATED OBJECTIVES*/}
                    <Grid item xs={3}>
                        <Title>RELATED OBJECTIVES</Title>
                        {action['related_objectives'] ? <TableList list={action['related_objectives']}/> : null}
                    </Grid>

                    {/*RELEVANT HERITAGE*/}
                    <Grid item xs={3}>
                        <Title>RELEVANT HERITAGE</Title>
                        {action['relevant_heritage'] ? <TableList list={action['relevant_heritage']}/> : null}
                    </Grid>

                    {/*ACTIVITIES*/}
                    <Grid item xs={12}>
                        <Title>ACTIVITIES</Title>
                        { action['activities'] ? <TableList list={action['activities']}/> : null}
                    </Grid>

                    {/*RELATED CHALLENGES*/}
                    <Grid item xs={12}>
                        <Title>RELATED CHALLENGES</Title>
                        {action['related_challenges'] ? <TableList list={action['related_challenges']}/> : null}
                    </Grid>

                    {/*MAIN STAKEHOLDERS*/}
                    <Grid item xs={4}>
                        <Title>MAIN STAKEHOLDERS</Title>
                        {action['main_stakeholders'] ? <TableList list={action['main_stakeholders']}/> : null}
                    </Grid>

                    {/*BENEFICIARIES*/}
                    <Grid item xs={4}>
                        <Title>BENEFICIARIES</Title>
                        { action['beneficiaries'] ? <TableList list={action['beneficiaries']}/> : null }
                    </Grid>

                    {/*INDICATIVE FUNDING SOURCES*/}
                    <Grid item xs={12}>
                        <Title>INDICATIVE FUNDING SOURCES</Title>
                        {action['indicative_funding_sources'] ? <TableList list={action['indicative_funding_sources']}/> : null}
                    </Grid>

                    {/*RELEVANT SIAS*/}
                    <Grid item xs={6}>
                        <Title>RELEVANT SIAS</Title>
                        {action['relevant_sias'] ? <TableList list={action['relevant_sias'].map(siaId => getSiaName(siaId))}/> : null}
                    </Grid>

                    {/*REFERENCE ROLE MODEL ACTION*/}
                    <Grid item xs={12}>
                        <Title>REFERENCE ROLE MODEL ACTION</Title>
                        {
                            (action['reference_rma'].length > 0) && <RMAList db={db} rmaList={action['reference_rma']}/>
                        }
                    </Grid>

                    {/*PREVIOUS ROLE MODEL ACTION TO ENHANCE*/}
                    <Grid item xs={12}>
                        <Title>PREVIOUS ROLE MODEL ACTION TO ENHANCE</Title>
                        {
                            (action['rma_enhance'].length > 0) && <RMAList db={db} rmaList={action['rma_enhance']}/>
                        }
                        {/*<RMAList db={db} llList={action['reference_rma']}/>*/}
                    </Grid>

                </Grid>

            </div>
        )

    }

    function getRMAEnhancementById(RMEActionId){
        let showAction = {}

        // Go through all role models
        for(let i = 0; i < db.rm.length; i++){
            let rm = db.rm[i]  // Get role models
            // If rm contains rma enhancements
            if("rma_enhancements" in rm){

                // go through all rma enhancement actions
                rm.rma_enhancements.actions.forEach( action => {
                    // if action is found then retrieve action info
                    if( action.action_id === RMEActionId ) {
                        showAction = action
                    }
                })
            }
        }
        return showAction
    }

    let action = getRMAEnhancementById(actionId)

    // If action object is not empty then show data otherwise show data not found
    return (Object.entries(action).length === 0 && action.constructor === Object) ? dataNotFound() : dataInfo(action)
}