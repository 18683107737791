import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(0),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}))

function StepButtons (props) {
    const classes = useStyles()
    const { activeStep, wizardLenght, handleBack, handleNext } = props

    return (
        <div className={classes.actionsContainer}>
            <div>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                >
                    {activeStep === wizardLenght - 1 ? 'Finish' : 'Next'}
                </Button>
            </div>
        </div>
    )
}

export default function Steppers (props) {
    const classes = useStyles()
    const { wizardSteps, activeStep, handleNextStep, handleBackStep, handleResetWizard, handleGoToReport } = props

    return (

        <Container fixed>
            <Box my={1}>
                <Button variant={'contained'} size={'small'} color={'secondary'} onClick={handleResetWizard}>
                    Reset Wizard
                </Button>
            </Box>

            <Stepper activeStep={activeStep} orientation="vertical">
                {wizardSteps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>
                            <Box color="secondary.main" letterSpacing={2} fontWeight="fontWeightBold">
                                {step.label.toUpperCase()}
                            </Box>
                        </StepLabel>
                        <StepContent>
                            {step.content}

                            <StepButtons
                                activeStep={activeStep}
                                wizardLenght={wizardSteps.length}
                                handleBack={() => handleBackStep()}
                                handleNext={() => handleNextStep()}
                            />
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

            {activeStep === wizardSteps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>

                    <Box mb={2}>
                        <Divider/>
                    </Box>

                    <Typography component={'div'}>
                        <Box fontWeight={600}>DONE!</Box>
                        Congratulations, all steps are completed! Now, based on your answer, we have prepared a report
                        for you. Please take a look!
                    </Typography>
                    <Button variant={'contained'} color={'primary'} onClick={handleGoToReport}
                            className={classes.button} fullWidth>
                        Report
                    </Button>
                </Paper>
            )}
        </Container>

    )
}