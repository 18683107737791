import React from 'react'
import './css/App.css'

//import Header from './components/Header'
import Footer from './components/footer'

import Box from '@material-ui/core/Box'
import 'typeface-roboto'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme/theme'

import Body from './components/Body'


export default function App () {

    return (
        <MuiThemeProvider theme={theme}>
            <React.Fragment>
                {/*<Box className="rowBody headerBody" bgcolor="DSS_Pallet.mainColours.YELLOW" pb={0.5}>*/}
                {/*    <Header/>*/}
                {/*</Box>*/}

                <Box className="rowBody contentBody" /*bgcolor="DSS_Pallet.supplementaryColours.LIGHT_GREEN"*/>
                    <Body/>
                </Box>

                <Box mt={2} className="rowBody footerBody" /*bgcolor="background.paper"*/>
                    <Footer/>
                </Box>
            </React.Fragment>
        </MuiThemeProvider>
    )
}

