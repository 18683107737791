import React, { Component } from 'react'

import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'

import Steppers from '../wizard/Steppers'
import SiasStep from '../wizard/wizardSteps/SiasStep'
import Report from '../wizard/Report'
import { POLITO_DB_API } from '../../js/config'
import { getAllRmFromLL } from '../../js/dataFetchUtils'

import DriversStep from '../wizard/wizardSteps/DriversStep'
import CrosscuttingStep from '../wizard/wizardSteps/CrosscuttingStep'
import ChallengesStep from '../wizard/wizardSteps/ChallengesStep'
import SizeStep from '../wizard/wizardSteps/SizeStep'

function getCrossCuttingStates (ll) {
    let cc = {}

    // Get all crosscuttings in a array
    let ccArray = []
    ll.forEach(lesson => {
        ccArray =
            [
                ...ccArray,
                ...lesson['MAIN RELATED CROSSCUTTING'],
                ...lesson['OTHER RELATED CROSSCUTTING']
            ]
    })
    ccArray = [...new Set(ccArray)] // make the array with unique elements

    // Create initial state object
    ccArray.forEach(elem => {
        cc[elem] = false
    })

    return cc

}

function getDriversStates (rms) {

    let drivers = {} // object with state to be returned

    // Create array of all drivers
    let driversArray = []
    rms.forEach(rm => {
        driversArray = [...driversArray, ...rm['Practices']['DRIVERS']]
    })
    driversArray = [...new Set(driversArray)] // make array just with unique elements

    //Create initial drivers state object
    driversArray.forEach(d => drivers[d] = false)

    return drivers
}

function getChallengesStates (rms) {

    let challenges = {} // object with state to be returned

    // Create array of all challenges
    let challengesArray = []
    rms.forEach(rm => {
        challengesArray = [...challengesArray, ...rm['Practices']['CHALLENGES'].map(c => c['CHALLENGE'])]
    })
    challengesArray = [...new Set(challengesArray)] // make array just with unique elements

    //Create initial challenges state object
    challengesArray.forEach(c => challenges[c] = false)

    // console.log('challenges:', challenges)

    return challenges
}

function getSiasStates (rms) {

    let sias = {} // object with state to be returned

    // siasDefinition.map( siaObj => siaObj[])

    // Create array of all challenges
    let siasArray = []
    rms.forEach(rm => {
        siasArray = [...siasArray, rm['SIA NAME']]
    })
    siasArray = [...new Set(siasArray)] // make array just with unique elements

    //Create initial challenges state object
    siasArray.forEach(sia => sias[sia] = false)

    // console.log('sias:', sias)

    return sias

}

function getSizeState () {
    return 'SMALL' // Default value
}

export default class Wizard extends Component {

    constructor (props) {
        super(props)

        this.state = {
            reportFlag: false,
            activeStep: 0, // step number of the wizard
            wizardSelections: {
                crosscuttings: {},
                drivers: {},
                challenges: {},
                sias: {},
                size: ''
            }
        }

        // Ruritage Data
        this.db = {
            rm: [],
            rma: [],
            ll: [],
            rm_ll: {},
            ll_rm: {},
            rmas: [],
            stakeholders: []
        }

        this.handleOnChanges = {
            crosscuttings: this.handleCrosscuttingsChange,
            drivers: this.handleDriversChange,
            challenges: this.handleChallengesChange,
            sias: this.handleSiasChange,
            size: this.handleSizeChange
        }

    }

    componentDidMount () {

        const p_rm = fetch(POLITO_DB_API + 'rm').then(res => res.json())
        const p_rma = fetch(POLITO_DB_API + 'rma').then(res => res.json())
        const p_rm_ll = fetch(POLITO_DB_API + 'rm_ll').then(res => res.json())
        const p_ll = fetch(POLITO_DB_API + 'll').then(res => res.json())

        const p_rmas = fetch(POLITO_DB_API + 'rmas').then(res => res.json())
        const p_stackholders = fetch(POLITO_DB_API + 'stakeholders').then(res => res.json())

        Promise.all([p_rm, p_rma, p_rm_ll, p_ll, p_rmas, p_stackholders])
            .then((data) => {
                // console.log('data: ', data)

                let ll_rm = getAllRmFromLL(data[3])

                // update Ruritage data
                this.db = {
                    rm: data[0], //p_rm,
                    rma: data[1], //p_rma,
                    rm_ll: data[2], //p_rm_ll,
                    ll: data[3],// p_ll,
                    ll_rm: ll_rm,
                    rmas: data[4], // p_rmas
                    stakeholders: data[5] //p_stackeholders
                }
                // console.log(this.db)
            })
            .then(() => this.resetWizardStatesToDefault())

    }

    // Cleans all wizard to initial default values and set activeStep to the first one
    handleResetWizard = () => { this.setState({ activeStep: 0 }, () => this.resetWizardStatesToDefault())}

    // Handlers to move steppers back and forward
    handleNextStep = () => {this.setState(state => ({ activeStep: state.activeStep + 1 }))}
    handleBackStep = () => {this.setState(state => ({ activeStep: state.activeStep - 1 }))}

    // Handlers to go to Report or Wizard
    handleGoToReport = () => {this.setState({ ...this.state, reportFlag: true })}
    handleGoToWizard = () => {this.setState({ ...this.state, reportFlag: false, activeStep: 0 })}

    // Reset wizard states to default values
    resetWizardStatesToDefault = () => {
        // Get initial crosscutting states
        let newCC = getCrossCuttingStates(this.db.ll)

        // Get initial drivers states
        let newDrivers = getDriversStates(this.db.rm)

        // Get initial challenges states
        let newChallenges = getChallengesStates(this.db.rm)

        // Get initial sias states
        let newSias = getSiasStates(this.db.rm)

        // Get initial Size state
        let newSize = getSizeState()

        // Update state with initial values
        this.setState({
            ...this.state,
            wizardSelections: {
                crosscuttings: newCC,
                drivers: newDrivers,
                challenges: newChallenges,
                sias: newSias,
                size: newSize
            }
        })
    }

    handleCrosscuttingsChange = (event) => {

        // Create the new crosscutting object
        let newCrosscuttings = {
            ...this.state.wizardSelections.crosscuttings,
            [event.target.name]: event.target.checked
        }

        // Get wizardSelections
        let wizardSelections = {
            ...this.state.wizardSelections,
            crosscuttings: newCrosscuttings
        }

        // Update state
        this.setState({ ...this.state, wizardSelections: wizardSelections })
    }

    handleDriversChange = (event) => {

        // Create the new crosscutting object
        let newDrivers = {
            ...this.state.wizardSelections.drivers,
            [event.target.name]: event.target.checked
        }

        // Get wizardSelections
        let wizardSelections = {
            ...this.state.wizardSelections,
            drivers: newDrivers
        }

        // Update state
        this.setState({ ...this.state, wizardSelections: wizardSelections })

    }

    handleChallengesChange = (event) => {

        // Create the new crosscutting object
        let newChallenges = {
            ...this.state.wizardSelections.challenges,
            [event.target.name]: event.target.checked
        }

        // Get wizardSelections
        let wizardSelections = {
            ...this.state.wizardSelections,
            challenges: newChallenges
        }

        // Update state
        this.setState({ ...this.state, wizardSelections: wizardSelections })

    }

    handleSiasChange = (event) => {

        // Create the new crosscutting object
        let newSias = {
            ...this.state.wizardSelections.sias,
            [event.target.name]: event.target.checked
        }

        // Get wizardSelections
        let wizardSelections = {
            ...this.state.wizardSelections,
            sias: newSias
        }

        // Update state
        this.setState({ ...this.state, wizardSelections: wizardSelections })

    }

    handleSizeChange = (event) => {

        // Get wizardSelections
        let wizardSelections = {
            ...this.state.wizardSelections,
            size: event.target.value
        }

        // Update state
        this.setState({ ...this.state, wizardSelections: wizardSelections })

    }

    render () {

        // Create the Wizard steps object to be passed to Steppers component
        let wizardSteps = [
            {
                label: 'Systemic Innovation Areas (SIA\'s)',
                content:
                    <SiasStep
                        wizardSelections={this.state.wizardSelections}
                        handleOnChanges={this.handleOnChanges}
                    />
            },
            {
                label: 'Crosscutting',
                content:
                    <CrosscuttingStep
                        wizardSelections={this.state.wizardSelections}
                        handleOnChanges={this.handleOnChanges}
                    />
            },
            {
                label: 'Challenges',
                content:
                    <ChallengesStep
                        wizardSelections={this.state.wizardSelections}
                        handleOnChanges={this.handleOnChanges}
                    />
            },
            {
                label: 'Drivers',
                content:
                    <DriversStep
                        wizardSelections={this.state.wizardSelections}
                        handleOnChanges={this.handleOnChanges}
                    />
            },
            {
                label: 'Size',
                content:
                    <SizeStep
                        wizardSelections={this.state.wizardSelections}
                        handleOnChanges={this.handleOnChanges}
                    />
            },
            // {
            //     label: 'Capital Transference Mechanism',
            //     content:
            //         <CtmStep
            //             wizardSelections={this.state.wizardSelections}
            //             handleOnChanges={this.handleOnChanges}
            //         />
            // },

            // {
            //     label: 'Available Resources',
            //     content:
            //         <ResourcesStep
            //             rms={db.rm}
            //             ll={db.ll}
            //             rmas={db.rma}
            //             resources={resources}
            //             handleResourcesChange={handleResourcesChange}
            //         />
            // },

        ]

        return (
            <React.Fragment>
                <Box m={1}>

                    <Collapse in={!this.state.reportFlag}>
                        <Steppers
                            wizardSteps={wizardSteps}

                            activeStep={this.state.activeStep}
                            handleNextStep={this.handleNextStep}
                            handleBackStep={this.handleBackStep}
                            handleResetWizard={this.handleResetWizard}

                            handleGoToReport={this.handleGoToReport}/>
                    </Collapse>

                    <Collapse in={this.state.reportFlag}>
                        <Report
                            db={this.db}
                            wizardSelections={this.state.wizardSelections}

                            handleGoToWizard={this.handleGoToWizard}
                        />
                    </Collapse>

                    {/*<br/>*/}
                    {/*<Report*/}
                    {/*    db={this.db}*/}
                    {/*    wizardSelections={this.state.wizardSelections}*/}

                    {/*    handleReset={this.handleGoToWizard}*/}
                    {/*/>*/}

                </Box>

                {/*<Box m={1} mt={3}>*/}
                {/*    <Paper>*/}
                {/*        <p>SIAS:</p>*/}
                {/*        <pre>{JSON.stringify(this.state, null, 2)}</pre>*/}
                {/*    </Paper>*/}
                {/*</Box>*/}

            </React.Fragment>
        )
    }
}

