import React, { Component } from 'react'

import Box from '@material-ui/core/Box'
import DSSHelpIcon from './DSSHelpIcon'

export default class SummaryModuleBox extends Component {

    render () {
        const { graphtitle, tooltip } = this.props
        return (
            <Box
                flexGrow={1} flexShrink={1} flexBasis={0} // it keeps the modules with the same width
                m={1} p={1}
                border={1} borderRadius="borderRadius" borderColor="grey.500"
                minWidth="400px"
                {...this.props}
            >

                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box textAlign="center" color="secondary.main"
                        // height={heightTitle}
                         fontSize={14}
                         letterSpacing={1}
                         fontWeight={500}
                    >
                        {graphtitle.toUpperCase()}
                    </Box>
                    <DSSHelpIcon tooltipTitle={tooltip}/>
                </Box>

                <Box
                    maxWidth="500px"
                    width={390}
                    height={150} // required to set a fix height because graphs have height=100%
                    m="auto" // keeps graphs in the center
                >
                    {this.props.children}
                </Box>
                {/*{this.props.children}*/}
            </Box>
        )
    }

}