import React from 'react'
import { withStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { theme } from '../theme/theme'

import { ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'

// RURITAGE SIAS Logos
import Icon_ArtAndFestival from '../img/SIA_pictograms-Icons/Art and Festival.png'
import Icon_IntegratedLandscapeManagement from '../img/SIA_pictograms-Icons/Integrated Landscape Management.png'
import Icon_Migration from '../img/SIA_pictograms-Icons/Migration.png'
import Icon_Pilgrimage from '../img/SIA_pictograms-Icons/Pilgrimage.png'
import Icon_Resilience from '../img/SIA_pictograms-Icons/Resilience.png'
import Icon_SustainableFoodProduction from '../img/SIA_pictograms-Icons/Sustainable Food Production.png'


// Get ll info by name
export const getLLInfo = (ll, LLArray) => {
    let llObj = {
        'found': false,
        'llInfo': {}
    }

    for (let i = 0; i < LLArray.length; i++) {
        let llAux = LLArray[i]
        if (llAux['LL CODE'] === ll) {
            llObj.found = true
            llObj.llInfo = llAux
        }
    }

    return llObj
}

// Input:
//      rmName      -> (String) Name of role model. e.g.: "rm7"
//      rmArray     -> (Array) Array containing RMs object where to look at
// Returns an object containing:
//      found       -> (true / false) true If RM was found
//      data        -> Object with RM data if found is set to true
export const getRmData = (rmName, rmArray) => {
    let rmData = {
        found: false,
        data: {}
    }

    for (let i = 0; i < rmArray.length; i++) {
        if (rmArray[i]['RM'] === rmName) {
            rmData.data = rmArray[i]
            rmData.found = true
            break
        }
    }
    return rmData
}

// Tooltip for DSS
export const DSSTooltip = withStyles(theme => ({
    tooltip: {
        // backgroundColor:  theme.palette.DSS_Pallet.supplementaryColours.LIGHT_GREEN, //background.paper, //"#c7db96", //theme.palette.main, //'#f5f5f9',
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 400,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #b28252',
        // borderRadius:"50%"
    },
}))(Tooltip)

export const DSSTooltipTile = (title) => {
    return (
        <Typography variant="h6">
            <Box
                fontSize={14}
                borderBottom={1}
                borderColor={theme.palette.secondary.main}
                color={theme.palette.secondary.main}>
                {title}
            </Box>
        </Typography>
    )
}

export const DSSTooltipDescription = (description) => {
    return (
        <Typography variant="body2" color="textSecondary" component="p">
            <Box fontSize={12} component="span">
                {description}
            </Box>
        </Typography>
    )
}

export const DSSTooltipExample = (examples) => {
    return (
        <Typography variant="caption" component="p">
            <Box mt={1} fontWeight="fontWeightMedium" component="span">
                Examples:<br></br>
                {examples}
            </Box>
        </Typography>
    )
}

// Bootstraap and styled-component package (Not Material-ui)
export const CustomProgress = styled(ProgressBar)`
  .progress-bar {
    background-color: ${theme.palette.DSS_Pallet.mainColours.GREEN}; +
  }
`

// oldMin -> Minimum value in the previous range
// oldMax -> Maximum value in the previous range
// oldValue -> Value to be converter to between [0, 100]
export function scaleScore (oldMin, oldMax, oldValue) {
    let newMin = 0.0,
        newMax = 100.0
    let newRange = (newMax - newMin)
    let scaledScore = newMin
    let oldRange = (oldMax - oldMin)
    if (oldRange !== 0) {
        scaledScore = (((oldValue - oldMin) * newRange) / oldRange) + newMin
    }
    return scaledScore
}

export const compareField = field => (a, b) => {
    if (a[field] < b[field]) { return 1 }
    if (a[field] > b[field]) { return -1 }
    return 0
}

export const siasDefinition = [
    {
        'RELATED SIA': 'SIA1',
        'SIA NAME': 'Pilgrimage',
        'DEFINITION': 'Heritage routes to sacred and historical places are a driver for sustainable and economic growth in many rural areas. Indeed,\n' +
            'some observers describe ‘route tourism’ as the world’s best hope for securing sustainability in travel and tourism. Thus, heritage routes\n' +
            'represent a good opportunity for developing less explored areas with valuable CNH that appeal to external visitors.'
    },
    {
        'RELATED SIA': 'SIA2',
        'SIA NAME': 'Sustainable Local Food Production',
        'DEFINITION': 'Using food, wine and gastronomy to profile rural localities has become a widespread way to improve\n' +
            'the economic and environmental sustainability of both tourism and agriculture. It has been linked to the development of “alternative” food\n' +
            'networks and a resurgent enthusiasm for food products that are perceived to be traditional and local, symbolising the place and culture of\n' +
            'the destination.'
    },
    {
        'RELATED SIA': 'SIA3',
        'SIA NAME': 'Migration',
        'DEFINITION': 'Beyond the challenges presented by the migration crisis, especially in the countries most affected by the migrants’ arrivals (e.g.\n' +
            'Greece and Italy), and by the received application of asylum (e.g. Germany), the arrival of ‘incomers’ can also create opportunities for\n' +
            'repopulation, growth and potential for rural regeneration. In this context, CNH, in terms of local tradition, languages, art and crafts, etc. can\n' +
            'play an important role in boosting and accelerating the process of integration and regeneration. Moreover, highlighting the positive\n' +
            'contribution of migrants to the development of rural areas can be fundamental for the creation of an inclusive society.'
    },
    {
        'RELATED SIA': 'SIA4',
        'SIA NAME': 'Art and Festivals',
        'DEFINITION': 'Festivals and arts’ exhibition have been used as a mean to attract tourists and as an economic resource in many rural areas.\n' +
            'Festivals related with ancient local traditions and products, open-air art exhibition and landscape museums are continuously growing and\n' +
            'represent an important source of tourism and jobs creation. Furthermore, arts-involved projects for youth engagement can highlight building\n' +
            'social connections, self-esteem, and community knowledge thus promoting youth entrepreneurship and a “creative rural economy”, providing aspirational' +
            ' jobs and examples of entrepreneurship that are particularly attractive to young people.'
    },
    {
        'RELATED SIA': 'SIA5',
        'SIA NAME': 'Resilience',
        'DEFINITION': 'Resilience refers to the ability of human settlements to withstand and to recover quickly from external shocks. Resilience against\n' +
            'crises not only refers to reducing risks and damage from disasters (i.e. loss of lives and assets), but also the ability to quickly bounce back\n' +
            'to a stable state. By enhancing the role of Cultural and Natural Heritage for building resilience against the dual threats of climate change and\n' +
            'disasters and ensuring that all development is risk-informed, rural communities can protect against losses and simultaneously boost\n' +
            'economic growth, create jobs and livelihoods, strengthen access to health and education, and contribute to foster the responsible ownership\n' +
            'of CNH in rural areas.'
    },
    {
        'RELATED SIA': 'SIA6',
        'SIA NAME': 'Integrated Landscape Management',
        'DEFINITION': 'According to the European landscape Convention, public is encouraged to take an active part in\n' +
            'Landscape protection, conserving and maintaining its heritage value, helping to steer changes brought about by economic, social or\n' +
            'environmental necessity, and in its planning. Successful examples of participatory landscape management built on heritage - and through\n' +
            'their integration in regional and Smart Specialization strategies – have been demonstrated to be an important instigator of rural renaissance.'
    }
]

// RURITAGE LOGOS with info
export const RURITAGE_SIAS_LOGOS = [
    {
        "RELATED SIA": "SIA4",
        img: Icon_ArtAndFestival,
        alt: 'Art and Festival'
    },
    {
        "RELATED SIA": "SIA6",
        img: Icon_IntegratedLandscapeManagement,
        alt: 'Integrated Landscape Management'
    },
    {
        "RELATED SIA": "SIA3",
        img: Icon_Migration,
        alt: 'Migration'
    },
    {
        "RELATED SIA": "SIA1",
        img: Icon_Pilgrimage,
        alt: 'Pilgrimage'
    },
    {
        "RELATED SIA": "SIA5",
        img: Icon_Resilience,
        alt: 'Resilience'
    },
    {
        "RELATED SIA": "SIA2",
        img: Icon_SustainableFoodProduction,
        alt: 'Sustainable Food Production'
    }
]

// Sizes of the Role Modules (Attention: this list needs to be in crescent order!!)
export const RM_SIZES = [
    'SMALL',
    'MEDIUM',
    'BIG',
    'VERY BIG'
]
