import React from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'

import { theme } from '../theme/theme'

function AndOrButtonDescription (buttonType) {
    return (
        <Typography variant="overline">
            <Box fontStyle="italic" fontWeight="fontWeightRegular">
                {
                    buttonType === 'AND' ?
                        <div><b>AND</b> - Displays elements that contain <u>all</u> the following activated filters</div>
                        :
                        <div><b>OR</b> - Displays elements that contain <u>at least one</u> of the following activated filters</div>
                }
            </Box>
        </Typography>
    )
}

export default function FilterControlLogic (props) {

    const { logicFilter, logicFilterOnChange } = props

    return (
        <Box>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <FormControl margin='dense' variant='outlined'>
                        <FormLabel component="legend">
                            <Box borderBottom={1} color={theme.palette.DSS_Pallet.mainColours.BROWN}>
                                <Typography variant="subtitle2">
                                    <Box mr={1} color="secondary.main" fontWeight="fontWeightMedium">
                                        FILTER LOGIC
                                    </Box>
                                </Typography>
                            </Box>
                        </FormLabel>
                        <FormGroup>
                            <Box ml={2}>
                                <RadioGroup aria-label="Logic" name="Logic" value={logicFilter} onChange={logicFilterOnChange}>
                                    <FormControlLabel value="AND" control={<Radio/>} label={AndOrButtonDescription('AND')}/>
                                    <FormControlLabel value="OR" control={<Radio/>} label={AndOrButtonDescription('OR')}/>
                                </RadioGroup>
                            </Box>
                        </FormGroup>
                    </FormControl>

                </Grid>
            </Grid>

        </Box>
    )

}
