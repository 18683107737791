import React from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export default function WizardQuestion(props){

    return(
        <Typography variant={'body1'} component={'span'} >
            <Box component={'span'} my={2}  fontWeight={600}>
                {props.children}
            </Box>
        </Typography>
    )
}